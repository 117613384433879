import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../hooks/useStores'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { IBilancio } from '../../types'
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import AggiungiButton from '../../../../components/common/AggiungiButton'
import BilanciForm from '../../components/bilanci/BilanciForm'
import BilancioTile from '../../components/bilanci/BilancioTile'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'
import BarraRicercaGenerale from '../../../../components/common/BarraRicercaGenerale'
import ReactPaginate from 'react-paginate'

const ArchivioBilanciTemiMaterialita = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()
    const { bilanci } = useModuloESGStores()
    const { t } = useTranslation();
    const { ui } = useStores();
    const testoTile = `${t('ModuloFattoriEsg.ModuloBilanciFattori.materialitaBilancio')}`

    const [stringaDiRicercaBilanci, setStringaDiRicercaBU] = useState<string>('');
    const listaBilanci = bilanci.bilanci;
    const listaBilanciFiltrati = listaBilanci?.filter((bilancio) => bilancio.descrizione.toLowerCase().includes(stringaDiRicercaBilanci.toLowerCase()));
    const [numeroPagineListaBilanci, setNumeroPagineListaBilanci] = useState(0);
    const listaBilanciPerPagina = 4;
    const listaBilanciVisitati = numeroPagineListaBilanci * listaBilanciPerPagina;
    const conteggioPaginaListaBilanci = Math.ceil(listaBilanciFiltrati!.length / listaBilanciPerPagina);
    const cambioPaginaListaBilanci = ({ selected }: { selected: number }): void => {
        setNumeroPagineListaBilanci(selected)
    };

    useEffect(() => {
        setNumeroPagineListaBilanci(0)
    }, [stringaDiRicercaBilanci])
    useEffect(() => {

        bilanci.getBilanci()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        bilanci.bilancioCreato,
        bilanci.bilancioEliminato,
        bilanci.bilancioModificato,
    ])

    return (
        <div>
            <div className='flex justify-end mb-4 mt-2'>
                <BarraRicercaGenerale
                    setStringaDiRicerca={setStringaDiRicercaBU}
                    numeroRisultati={listaBilanciFiltrati.length}
                    mostraRisultati={true} />
            </div>
            <div className="mt-2 mb-4">
                {
                    listaBilanciFiltrati.length > 0 ?
                        <div className="grid gap-3 xl:gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-2">
                            {listaBilanciFiltrati
                                .slice(listaBilanciVisitati, listaBilanciVisitati + listaBilanciPerPagina)
                                .map((bilancio: IBilancio) => (
                                    <BilancioTile testo={testoTile} bilancio={bilancio} key={bilancio.anno} />
                                )
                                )}
                        </div>
                        :
                        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
                            <FaInfoCircle /> {t('ModuloDashboard.Archivio.nessunBilancioPresente')}
                        </h3>
                }
            </div>
            {listaBilanciFiltrati.length > 4 &&
                <div className='mb-4'>
                    <ReactPaginate
                        pageCount={conteggioPaginaListaBilanci}
                        nextLabel={'Next'}
                        onPageChange={cambioPaginaListaBilanci}
                        forcePage={numeroPagineListaBilanci}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"} />
                </div>
            }

            {privilegio >= 2 &&
                <div className="w-full mt-2" onClick={() => ui.mountAsideModal(<BilanciForm />)} >
                    <AggiungiButton testo={t('common.aggiungiNuovoBilancio')} />
                </div>
            }
        </div>
    )
})

export default ArchivioBilanciTemiMaterialita;