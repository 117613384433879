import React from 'react'
import { observer } from 'mobx-react-lite';
import { useTabellaInterattivaContext } from './context/useTabellaInterattivaContext';
import { useTranslation } from 'react-i18next';
import BarraRicercaTabella from './BarraRicercaTabella';

const HeaderTabella = observer(() => {

    const { tabella } = useTabellaInterattivaContext()
    const { t } = useTranslation()
    return (
        <div className="flex items-end justify-between">
            <div className="flex flex-col items-start gap-2">
                <p>{t('tabellaInterattiva.elementiPerPagina')}:
                    <input
                        min={0}
                        max={tabella.dati.length}
                        className="mx-1 w-[60px] border border-lightgray-d py-[4px] px-[8px]"
                        type="number"
                        defaultValue={tabella.elementiPerPagina}
                        onChange={(e) => tabella.settaElementiPerPagina(parseInt(e.target.value))}
                    />
                </p>
                <p>
                    {t('tabellaInterattiva.elementiDa')} {tabella.paginaAttiva * tabella.elementiPerPagina} {t('common.a')} {(tabella.paginaAttiva * tabella.elementiPerPagina) + tabella.elementiPerPagina} {t('tabellaInterattiva.visualizzatiSu')} {tabella.dati.length}
                </p>
            </div>
            <BarraRicercaTabella />
        </div>
    )
})

export default HeaderTabella;