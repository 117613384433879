import React from 'react'
import ReactEcharts from "echarts-for-react"

const GraficoRadarVeicoliTotali: React.FC<{ emissioniBusinessUnitTotaliFlat: any[], anno: number }> = ({ emissioniBusinessUnitTotaliFlat, anno }) => {


    const lineStyle = {
        width: 7,
        opacity: 0.5
    };

    //Ottiene lista business units
    let listaBu: Set<string> = new Set()
    emissioniBusinessUnitTotaliFlat
        .filter(item => item.emissione_totale_t_co2e_veicoli_carburante > 0)
        .map((item: any) => listaBu.add(item.business_unit))



    //Ottiene lista emissioni
    let listaEmissioni: number[] = []
    emissioniBusinessUnitTotaliFlat
        .filter(item => item.emissione_totale_t_co2e_veicoli_carburante > 0)
        .map((item: any) => listaEmissioni.push(item.emissione_totale_t_co2e_veicoli_carburante))

    //Definisce indicatori ruota
    let indicatori: object[] = []

    listaBu.forEach((bu: string) => {
        indicatori.push({
            name: bu,
            max: Math.max(...listaEmissioni)
        })
    })

    let option = {
        tooltip: {
            trigger: 'item'
        },
        toolbox: {
            show: true,
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        legend: {
            data: Array.from(listaBu)
        },
        radar: {
            // shape: 'circle',
            indicator: indicatori,
            splitNumber: 5,
            axisName: {
                color: 'rgb(0, 46, 90)'
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(0,0,0,0.5)'
                }
            },
            lineStyle: lineStyle,
            itemStyle: {
                color: 'rgb(0,46,90)'
            },
            label: {
                show: true,
                formatter: function (params: any) {
                    return params.value;
                }
            }

        },
        series: [
            {
                name: 'Total Emissions',
                type: 'radar',
                data: [
                    {
                        value: listaEmissioni,
                        name: 'Emissions'
                    }
                ]
            }
        ]
    };

    return (
        <div className="h-[600px] p-4" >
            <ReactEcharts style={{ height: '100%' }} option={option} />
        </div >
    )
}

export default GraficoRadarVeicoliTotali;
