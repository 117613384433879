import React, { useEffect } from 'react'

//Data
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { useStores } from '../../hooks/useStores';
import { ITabellaInterattiva } from './types';
import { useTabellaInterattivaContext } from './context/useTabellaInterattivaContext';

//Components
import CorpoTabella from './CorpoTabella';
import InformazioniTabella from './InformazioniTabella';
import HeaderTabella from './HeaderTabella';
import PaginazioneTabella from './PaginazioneTabella';
import FooterTabella from './FooterTabella';

const TabellaInterattiva: React.FC<ITabellaInterattiva> = observer(({
    titolo,
    sottoTitolo,
    paragrafo,
    modalitaModificaRapida = false,
    callToActions,
    callToActionsRiga,
    dati,
    colonne,
    elementiPerPagina = 15,
    paginaAttiva = 0,
    pk = [],
    endpoint,
    stringaFiltro
}) => {

    const { ui } = useStores()
    const { tabella } = useTabellaInterattivaContext()

    const styles = {
        container: `min-w-full max-w-[calc(100vw-20px)] md:max-w-[calc(100vw-340px)] canvas-body
        bg-white dark:bg-darkgray-dd p-5 rounded-md flex flex-col items-stretch gap-2
        ${ui.asideModal && 'md:max-w-[calc(100vw-750px)]'}`,
    }

    //Istanzia info tabella
    useEffect(() => {

        runInAction(() => {
            tabella.titolo = titolo ? titolo : ''
            tabella.sottoTitolo = sottoTitolo ? sottoTitolo : ''
            tabella.paragrafo = paragrafo ? paragrafo : ''
            tabella.modalitaModificaRapida = modalitaModificaRapida
            tabella.colonne = colonne && colonne
            tabella.elementiPerPagina = elementiPerPagina ? elementiPerPagina : 15
            tabella.paginaAttiva = paginaAttiva ? paginaAttiva : 0
            tabella.pk = pk ? pk : []
            tabella.endpoint = endpoint ? endpoint : ''
            tabella.stringaFiltro = stringaFiltro ? stringaFiltro : ''
        })

    }, [titolo])

    useEffect(() => {
        runInAction(() => tabella.callToActionsRiga = callToActionsRiga ? callToActionsRiga : [])
    }, [callToActionsRiga])

    useEffect(() => {
        runInAction(() => tabella.callToActions = callToActions ? callToActions : [])
    }, [callToActions])


    //Carica dati in tabella
    useEffect(() => {
        if (dati) {
            tabella.settaDati(dati)
        }
    }, [dati])

    return (
        <div className={`${styles.container}`}>
            <InformazioniTabella />

            {
                tabella.dati &&
                <div className="mt-3">
                    <HeaderTabella />
                    <CorpoTabella />
                </div>
            }

            <PaginazioneTabella />

            {tabella.dati && tabella.dati.length > 0 && <FooterTabella />}

        </div>
    )
})

export default TabellaInterattiva;