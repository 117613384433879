import React, { useEffect, useState } from 'react'
import { get } from '../../../../rest/crud_generica'
import GraficoResocontoGenerale from '../grafici/GraficoResocontoGenerale'
import TabellaInterattiva from '../../../../components/tabellaInterattiva/TabellaInterattiva'

const AnalisiCarbonFootprintGenerale = () => {
    const [resocontoGenerale, setResocontoGenerale] = useState([])

    useEffect(() => {
        get('carbon_footprint/analisi/resoconto_rendicontazione').then((res) => setResocontoGenerale(res.data.data))
    }, [])

    return (
        <div className='bg-white dark:bg-darkgray-dd'>

            <div className="py-6 text-center">
                <h1 className='text-3xl font-bold'>Resoconto generale</h1>
                <p className="text-lg">Resoconto generale della rendicontazione, utile a comprendere l'aggregato delle emissioni dirette per ogni business unit</p>
            </div>

            {resocontoGenerale && resocontoGenerale.length > 0 && <GraficoResocontoGenerale resocontoGenerale={resocontoGenerale} />}

            <TabellaInterattiva
                titolo="Dati grezzi"
                dati={resocontoGenerale}
                colonne={[
                    { id: 'business_unit', label: 'Business Unit', link: 'app/modulo/analisi_carbon_footprint/{{valore}}' },
                    { id: 'anno', label: 'Anno' },
                    { id: 'emissione_totale_t_co2e_combustibile', label: 'Emissioni degli impianti stazionati (t / co2e)' },
                    { id: 'emissione_totale_t_co2e_veicoli', label: 'Emissioni dei veicoli (t / co2e)' },
                    { id: 'emissione_totale_t_co2e_gas_refrigerante', label: 'Emissioni dei gas refrigeranti (t / co2e)' },
                    { id: 'emissione_totale_t_co2e_elettricita', label: 'Emissioni elettriche (t / co2e)' },
                ]}
            />

        </div>
    )
}

export default AnalisiCarbonFootprintGenerale;