import React, { useEffect, useState } from 'react'

//Data
import { Controller, useForm } from 'react-hook-form'
import { crea, get, modifica } from '../../../../../rest/crud_generica';
import { useStores } from '../../../../../hooks/useStores';
import { ICarburantiEmissioni, IEmissioneCarburante } from '../../../types';

//Components
import InputField from '../../../../../components/common/form/InputField';
import SpinnerButton from '../../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../components/common/InfoBox';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { getUnitaMisuraLista } from '../../../../gestioneUnitaMisura/rest/gestioneUnitaMisura';
import { useStatoRendicontazioneContext } from '../../context/useStatoRendicontazioneContext';


const RendicontazioneCarburantiForm: React.FC<{
    emissioneCarburante?: IEmissioneCarburante,
    businessUnit?: string,
    anno: number
}> = ({
    emissioneCarburante,
    businessUnit,
    anno,
}) => {

        const { t } = useTranslation()
        const { ui } = useStores()
        const [attesa, setAttesa] = useState<boolean>(false)
        const [successo, setSuccesso] = useState<string>('')
        const [errore, setErrore] = useState<string>('')
        const { stato_rendicontazione } = useStatoRendicontazioneContext()
        
        const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm({
            defaultValues: {
                carburante: emissioneCarburante ? emissioneCarburante.carburante : '',
                nome: emissioneCarburante ? emissioneCarburante.nome : '',
                quantita: emissioneCarburante ? emissioneCarburante.quantita : '',
                unita_misura: emissioneCarburante ? emissioneCarburante.unita_misura : '',
            }
        });


        const onSubmit = () => {
            setAttesa(true)
            emissioneCarburante
                ?
                modifica(`carbon_footprint/rendicontazione/carburanti/${businessUnit}/${anno}/${emissioneCarburante.carburante}/${emissioneCarburante.nome}`, {
                    carburante: watch('carburante'),
                    nome: watch('nome'),
                    quantita: watch('quantita'),
                    unita_misura: watch('unita_misura'),
                    business_unit: businessUnit,
                    anno: anno,
                })
                    .then((res) => {
                        setErrore('')
                        setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendCarburanti.form.successoModifica'))
                        ui.triggerAggiornamentoGenerico()
                    })
                    .catch((err) => {
                        setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                        setSuccesso('')
                    })
                    .finally(() => setAttesa(false))
                :
                crea(`carbon_footprint/rendicontazione/carburanti`, {
                    carburante: watch('carburante'),
                    nome: watch('nome'),
                    quantita: watch('quantita'),
                    unita_misura: watch('unita_misura'),
                    business_unit: businessUnit,
                    anno: anno,
                })
                    .then((res) => {
                        setErrore('')
                        setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendCarburanti.form.successoCreazione'))
                        ui.triggerAggiornamentoGenerico()
                    })
                    .catch((err) => {
                        setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                        setSuccesso('')
                    })
                    .finally(() => setAttesa(false))
        }

        const [listaCarburanti, setListaCarburanti] = useState<[]>([])
        const [listaUnitaMisura, setListaUnitaMisura] = useState<any[]>([])


        useEffect(() => {

            get('carbon_footprint/carburanti_fattori_emissione/lista_senza_fattori').then((res) =>
                setListaCarburanti(res.data.data.filter((car: any) => car.anno === anno))
            )

        }, [])

        useEffect(() => {

            setValue('unita_misura', '')

            const unitaMisuraCarburante = listaCarburanti
                .filter((carburante: ICarburantiEmissioni) => carburante.carburante === watch('carburante'))
                .map((carburante: ICarburantiEmissioni) => carburante.unita_misura)
                .map((um: any) => {
                    return {
                        value: um,
                        label: um,
                    }
                })


            setListaUnitaMisura(unitaMisuraCarburante)

        }, [watch('carburante')])

        const listaCarburantiSelezionabili = () => {
            const listaSelectCarburantiDuplicata = listaCarburanti.map((carburante: ICarburantiEmissioni) => {
                return {
                    label: carburante.carburante,
                    value: carburante.carburante
                }
            })

            const mappaCarburanti = new Map(
                listaSelectCarburantiDuplicata.map(carburante =>
                    [carburante.label, carburante]
                )
            )

            const listaSelectCarburantiSingoli = Array.from(mappaCarburanti.values())

            return listaSelectCarburantiSingoli
        }

        return (
            <div>
                <div>
                    {emissioneCarburante ?
                        <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendCarburanti.form.titoloModifica')} {businessUnit}</h2>
                        :
                        <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendCarburanti.form.titoloCreazione')} {businessUnit}</h2>
                    }
                </div>

                <form className="form" onSubmit={handleSubmit(onSubmit)}>


                    <InputField
                        label={t('ModuloCarbonFootprint.common.nomeImpianto')}
                        type="text"
                        error={errors.nome}
                        form={register("nome")}
                        placeholder={t('common.nome')}
                    />

                    <div className="form">
                        <label>{t('ModuloCarbonFootprint.common.carburante')}</label>
                        <Controller
                            name="carburante"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={listaCarburantiSelezionabili()}
                                    onChange={(e: any) => onChange(e.value)}
                                    defaultValue={emissioneCarburante?.carburante && {
                                        label: emissioneCarburante.carburante,
                                        value: emissioneCarburante.carburante,
                                    }}
                                />
                            )}
                        />
                    </div>

                    <InputField
                        label={`${t('common.quantita')}`}
                        type="number"
                        error={errors.quantita}
                        form={register("quantita")}
                        placeholder="1"
                    />

                    <div className="form w-full">
                        <label htmlFor="">{t('common.unitaMisura')}</label>
                        <Controller
                            name="unita_misura"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={listaUnitaMisura}
                                    onChange={(e: any) => onChange(e.value)}
                                    defaultValue={emissioneCarburante?.unita_misura && {
                                        value: emissioneCarburante.unita_misura,
                                        label: emissioneCarburante.unita_misura
                                    }}
                                />
                            )}
                        />
                    </div>

                    {
                        !attesa ?
                            <input type="submit" value={emissioneCarburante ? t('common.salvaModifica') : t('common.crea')} />
                            : <SpinnerButton />
                    }

                    {(errore || successo) &&
                        <div className="mt-2">
                            {errore && <ErrorBox errore={errore} />}
                            {successo && <InfoBox messaggio={successo} />}
                        </div>
                    }


                </form>
            </div>
        )
    }

export default RendicontazioneCarburantiForm;