import React from 'react'
import ReactEcharts from "echarts-for-react"

const GraficoDettagliCarburantiPerBu: React.FC<{ emissioniBusinessUnitFlat: any[], anno: number }> = ({ emissioniBusinessUnitFlat, anno }) => {

  //Ottiene lista dei carburanti
  let listaCarburanti: Set<string> = new Set()
  emissioniBusinessUnitFlat
    .filter(item => item.emissione_totale_t_co2e_combustibile > 0)
    .forEach(item => listaCarburanti.add(item.carburante))

  //Ottiene lista delle bu
  let listaBu: Set<string> = new Set()
  emissioniBusinessUnitFlat
    .filter(item => item.emissione_totale_t_co2e_combustibile > 0)
    .forEach(item => listaBu.add(item.business_unit))

  //Ottiene lista delle emissioni
  let listaEmissioni: number[] = []
  emissioniBusinessUnitFlat
    .filter(item => item.emissione_totale_t_co2e_combustibile > 0)
    .forEach(item => listaEmissioni.push(item.emissione_totale_t_co2e_combustibile))


  let series: any[] = []

  //Genera le serie partendo dala lista dei carburanti
  listaCarburanti.forEach((nomeCarburante) => {

    let data: number[] = []

    listaBu.forEach(nomeBu => {

      let valore = 0

      let record = emissioniBusinessUnitFlat.find(item => (
        item.anno === anno &&
        item.carburante === nomeCarburante &&
        item.business_unit === nomeBu
      ))

      if (record && record.emissione_totale_t_co2e_combustibile) {
        valore = record.emissione_totale_t_co2e_combustibile
      }

      data.push(valore)

    })

    series.push({
      name: nomeCarburante,
      label: {
        formatter: (param: any) => {
          return param.value ? param.value : ''
        },
        show: true
      },
      data: data,
      type: 'bar',
      stack: 'total',
      emphasis: {
        focus: 'series'
      },
    })

  })

  let option = {
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      containLabel: true,
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: Array.from(listaBu).map(item => item.substring(0, 20))
    },
    series: series
  };

  return (
    <div className="p-4" style={{ height: listaBu.size * 120 }}>
      <ReactEcharts style={{ height: '100%' }} option={option} />
    </div>
  )
}

export default GraficoDettagliCarburantiPerBu;
