import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { get } from '../../../../rest/crud_generica'
import TabellaInterattiva from '../../../../components/tabellaInterattiva/TabellaInterattiva'
import GraficoBusinessUnit from '../grafici/graficiBusinessUnit/GraficoBusinessUnit'

export default function AnalisiCarbonFootprintBusinessUnit() {

    const { business_unit } = useParams()
    const [emissioniBusinessUnit, setEmissioniBusinessUnit] = useState<[]>([])

    useEffect(() => {
        get(`carbon_footprint/analisi/resoconto/business_unit/${business_unit}`).then((res) => {
            setEmissioniBusinessUnit(res.data.data)
        })
    }, [])

    return (
        <div className='bg-white dark:bg-darkgray-dd'>

            <div className="py-6 text-center">
                <Link to='/app/modulo/analisi_carbon_footprint'>Torna indietro</Link>
                <h1 className='text-3xl font-bold'>Resoconto "{business_unit}"</h1>
                <p className="text-lg">Storico delle emissioni su base annuale per questa business unit</p>
            </div>

            <GraficoBusinessUnit emissioniBusinessUnit={emissioniBusinessUnit} />
            <TabellaInterattiva
                titolo="Dati grezzi"
                dati={emissioniBusinessUnit}
                colonne={[
                    { id: 'business_unit', label: 'Business Unit'},
                    { id: 'business_unit_padre', label: 'Business Unit Padre' },
                    { id: 'emissione_cumulate_t_co2e_combustibile', label: 'Emissioni degli impianti stazionati (t / co2e)' },
                    { id: 'emissione_cumulate_t_co2e_veicoli', label: 'Emissioni dei veicoli (t / co2e)' },
                    { id: 'emissione_cumulate_t_co2e_gas_refrigerante', label: 'Emissioni dei gas refrigeranti (t / co2e)' },
                    { id: 'emissione_cumulate_t_co2e_elettricita', label: 'Emissioni elettriche (t / co2e)' },
                    { id: 'emissione_cumulate_t_co2e_totale', label: 'Emissioni totali (t / co2e)' },
                ]}
                />
        </div>
    )
}
