import React, { useState, useEffect } from 'react'

//Data
import { useTabellaInterattivaContext } from '../context/useTabellaInterattivaContext';
import { useTranslation } from 'react-i18next';
import { utils, writeFileXLSX } from 'xlsx';
import { observer } from 'mobx-react';
import { FaDownload } from 'react-icons/fa';

const TabellaFeatureExportExcel = observer(() => {

    const { tabella } = useTabellaInterattivaContext()
    const { t } = useTranslation()

    const [pres, setPres] = useState([]);

    const esporta = () => {
        setPres(tabella.dati)

        if (pres.length > 0) {
            const ws = utils.json_to_sheet(pres);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, (tabella.titolo ? tabella.titolo : 'Tabella Ipè') + '.xlsx');
        }
    };

    return (
        <div className="flex font-bold items-center gap-2 p-2 bg-[#1F7043] text-white hover:brightness-[1.1] duration-200 cursor-pointer" onClick={esporta}>
            {t('common.scarica')} Excel <FaDownload />
        </div>
    )
})

export default TabellaFeatureExportExcel;