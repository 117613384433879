import React, { useState, useEffect } from 'react'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { elimina, get, modifica } from '../../../../../rest/crud_generica'
import { IEmissioneElettrica } from '../../../types'
import { ICallToActionTabella } from '../../../../../components/tabellaInterattiva/types'
import { IBusinessUnitRendicontabile } from '../../../../UiModuloFattoriESG/types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

//Components
import { FaInfoCircle, FaPen, FaTrash } from 'react-icons/fa'
import { BsShieldCheck } from 'react-icons/bs'
import { CgAdd } from 'react-icons/cg'
import Dialogue from '../../../../../components/common/Dialogue'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'
import RendicontazioneElettricitaForm from './RendicontazioneElettricitaForm'
import Spinner from '../../../../../components/common/Spinner'
import { useStatoRendicontazioneContext } from '../../context/useStatoRendicontazioneContext'

const RendicontazioneElettricita = observer(() => {

    const { t } = useTranslation()
    const { ui } = useStores()
    const { stato_rendicontazione } = useStatoRendicontazioneContext()

    //Lista delle bu
    const [businessUnits, setBusinessUnits] = useState<IBusinessUnitRendicontabile[]>([])
    const [caricamento, setCaricamento] = useState<boolean>(false)

    //Ottiene lista bu
    useEffect(() => {

        setCaricamento(true)

        get('lista_ruoli_utente').then((res) => {
            setBusinessUnits(res.data.data)
            cambiaBuAttiva(res.data.data[0])
        }).finally(() => {
            setCaricamento(false)
        })

    }, [])

    //BU attiva
    const [businessUnitAttiva, setBusinessUnitAttiva] = useState<IBusinessUnitRendicontabile>()


    //Cambia BU attiva
    const cambiaBuAttiva = (bu: IBusinessUnitRendicontabile) => {
        setBusinessUnitAttiva(bu)
        setPaese(bu.paese)
        ui.triggerAggiornamentoGenerico()
    }

    const [paese, setPaese] = useState<string>('Italy')

    const [emissioniElettriche, setEmissioniElettriche] = useState<IEmissioneElettrica[]>([])

    useEffect(() => {

        businessUnitAttiva &&
            get(`carbon_footprint/rendicontazione/elettricita/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}`)
                .then((res) => {
                    setEmissioniElettriche(res.data.data)
                })

    }, [businessUnitAttiva, ui.aggiornamentoGenerico, stato_rendicontazione.annoAttivo])

    //Crea emissione
    const creaEmissioneElettrica = () => {
        businessUnitAttiva &&
            ui.mountAsideModal(
                <RendicontazioneElettricitaForm
                    businessUnit={businessUnitAttiva.nome}
                    anno={stato_rendicontazione.annoAttivo}
                    paese={paese}
                />
            )
    }

    //Modifica emissione
    const modificaEmissioneElettrica = (elettricita: IEmissioneElettrica) => {
        businessUnitAttiva &&
            ui.mountAsideModal(
                <RendicontazioneElettricitaForm
                    businessUnit={businessUnitAttiva.nome}
                    anno={stato_rendicontazione.annoAttivo}
                    paese={paese}
                    emissioneElettrica={elettricita} />
            )
    }

    //Elimina emissione
    const eliminaEmissioneElettrica = (elettricita: IEmissioneElettrica) => {
        businessUnitAttiva &&
            elimina(`carbon_footprint/rendicontazione/elettricita/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}/${elettricita.fattore_emissione}/${elettricita.nome}`)
                .then(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendElettricita.successoEliminazione')} />)
                    ui.triggerAggiornamentoGenerico()
                }
                )
                .catch(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendElettricita.erroreEliminazione')} />)
                })
    }

    //Valida emissione
    const validaEmissioneElettrica = (elettricita: IEmissioneElettrica) => {
        businessUnitAttiva &&
            modifica(`carbon_footprint/rendicontazione/elettricita/valida/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}/${elettricita.fattore_emissione}/${elettricita.nome}`, {})
                .then(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendElettricita.successoValidazione')} />)
                    ui.triggerAggiornamentoGenerico()
                }
                )
                .catch(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendElettricita.erroreValidazione')} />)
                })
    }

    //Invalida emissione
    const invalidaEmissioneElettrica = (elettricita: IEmissioneElettrica) => {
        businessUnitAttiva &&
            modifica(`carbon_footprint/rendicontazione/elettricita/invalida/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}/${elettricita.fattore_emissione}/${elettricita.nome}`, {})
                .then(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendElettricita.successoRimValidazione')} />)
                    ui.triggerAggiornamentoGenerico()
                }
                )
                .catch(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendElettricita.erroreRimValidazione')} />)
                })
    }

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (businessUnitAttiva) {

            setCtaRiga([])
            setCtaTabella([])

            if (businessUnitAttiva.ruolo >= 1) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaEmissioneElettrica,
                    testo: t('common.modifica'),
                    icona: <FaPen size={12} />,
                }])

                setCtaRiga(cta => [...cta, {
                    azione: eliminaEmissioneElettrica,
                    testo: t('common.elimina'),
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])

                setCtaTabella(cta => [...cta, {
                    testo: t('ModuloCarbonFootprint.rendElettricita.aggiungi'),
                    azione: creaEmissioneElettrica,
                    icona: <CgAdd size={18} />
                }])
            }

            if (businessUnitAttiva.ruolo >= 2) {

                setCtaRiga(cta => [...cta, {
                    azione: validaEmissioneElettrica,
                    testo: t('common.valida'),
                    icona: <BsShieldCheck size={12} />,
                    coloreSfondo: "bg-green dark:bg-dark-green"
                }])

                setCtaRiga(cta => [...cta, {
                    azione: invalidaEmissioneElettrica,
                    testo: t('common.rimuoviValidazione'),
                    icona: <BsShieldCheck size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])

            }
        }

    }, [businessUnitAttiva])

    return (
        <div className="flex flex-col">

            {caricamento &&
                <div className='flex items-center justify-center p-4 w-full'>
                    <Spinner colore='blue' />
                </div>
            }

            {businessUnits && businessUnits.length > 0 && !caricamento &&
                <div className="flex max-w-[90vw] sm:max-w-[450px] md:max-w-[600px] overflow-x-auto">
                    {
                        businessUnits.map((bu) => (
                            <div
                                key={bu.nome}
                                onClick={() => cambiaBuAttiva(bu)}
                                onMouseEnter={() => bu.nome.length > 30 && ui.mountFollowMouse(<>{bu.nome}</>)}
                                onMouseLeave={() => ui.unmountFollowMouse()}
                                className={`
                                p-1 px-4 py-2 cursor-pointer duration-200
                                ${businessUnitAttiva && businessUnitAttiva.nome === bu.nome ?
                                        'bg-accent dark:bg-dark-accent pointer-events-none text-white'
                                        :
                                        'bg-white dark:bg-darkgray-dd opacity-100 hover:opacity-70'
                                    }`}>
                                {bu.nome.substring(0, 30)}
                                {bu.nome.length > 30 && '...'}
                            </div>
                        ))
                    }
                </div>
            }


            {businessUnits.length === 0 && !caricamento &&
                <div className='flex flex-col gap-2 items-center max-w-[500px] mx-auto'>
                    <FaInfoCircle />
                    <h2 className="text-center text-lg">{t('ModuloCarbonFootprint.common.nonHaiAccesso')}</h2>
                </div>
            }

            {
                businessUnitAttiva && businessUnits.length > 0 &&
                <div className="grow border-t-2 border-accent dark:border-dark-accent">
                    <TabellaInterattiva
                        dati={emissioniElettriche}
                        titolo={`${t('ModuloCarbonFootprint.rendElettricita.titolo')} ${businessUnitAttiva.nome}`}
                        callToActions={ctaTabella}
                        colonne={
                            [
                                { id: 'nome', label: t('common.nome') },
                                { id: 'fattore_emissione', label: t('ModuloCarbonFootprint.common.fattoreDiEmissione') },
                                { id: 'business_unit', label: t('common.businessUnit') },
                                { id: 'anno', label: t('common.anno') },
                                { id: 'paese', label: t('common.paese') },
                                { id: 'elettricita', label: `${t('ModuloCarbonFootprint.common.elettricita')} (in kWh)` },
                            ]
                        }
                        callToActionsRiga={ctaRiga}
                    />
                </div>
            }

        </div>
    )
})
export default RendicontazioneElettricita;