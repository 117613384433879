import { action, makeAutoObservable, observable } from "mobx"

export class StatoRendicontazioneStore {
    annoAttivo: number = 2022 

    constructor() {
        makeAutoObservable(this, {
           annoAttivo: observable
        })
    }

    settaAnnoAttivo = (anno: number) => {
        this.annoAttivo = anno
    }
}