import React from 'react'
import { observer } from 'mobx-react-lite';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useTabellaInterattivaContext } from './context/useTabellaInterattivaContext';

const PaginazioneTabella = observer(() => {

    const { tabella} = useTabellaInterattivaContext()

    return (
        <div className="relative h-5">
            {
                tabella.paginaAttiva > 0 &&
                <div
                    className="bg-accent dark:bg-dark-accent text-white p-2 cursor-pointer absolute left-0 flex items-center gap-1 hover:opacity-90 duration-200"
                    onClick={() => tabella.cambiaPagina(tabella.paginaAttiva - 1)}>
                    <FaAngleLeft />
                    <span>Pagina precedente</span>
                </div>
            }


            {tabella.dati && (tabella.paginaAttiva * tabella.elementiPerPagina) + tabella.elementiPerPagina < tabella.dati.length &&
                <div
                    className="bg-accent dark:bg-dark-accent text-white p-2 cursor-pointer absolute right-0 flex items-center gap-1 hover:opacity-90 duration-200"
                    onClick={() => tabella.cambiaPagina(tabella.paginaAttiva + 1)}>
                    <span>Pagina successiva</span>
                    <FaAngleRight />
                </div>
            }
        </div>
    )
})

export default PaginazioneTabella;