import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../../../../hooks/useStores';
import { elimina, get } from '../../../../../rest/crud_generica';
import { IGasRefrigerante } from '../../../types';
import { observer } from 'mobx-react-lite';

//Components
import { CgAdd } from 'react-icons/cg';
import { FaPen, FaTrash } from 'react-icons/fa';
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva';
import Dialogue from '../../../../../components/common/Dialogue';
import GasRefrigeranteForm from './components/GasRefrigeranteForm';
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo';
import { ICallToActionTabella } from '../../../../../components/tabellaInterattiva/types';
import { useTabellaInterattivaContext } from '../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext';

const ConfigurazioneGasRefrigeranti = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()
    const { ui } = useStores()

    const [fattoriGasRefrigeranti, setFattoriGasRefrigeranti] = useState<IGasRefrigerante[]>([])

    useEffect(() => {
        listaGasRefrigeranti()
    }, [ui.aggiornamentoGenerico])


    //Ottieni lista gas
    const listaGasRefrigeranti = () => get('carbon_footprint/gas_refrigeranti').then((res) => {
        setFattoriGasRefrigeranti(res.data.data)
    })

    //Crea gas
    const creaGasRefrigerante = () => ui.mountAsideModal(<GasRefrigeranteForm />)

    //Modifica gas
    const modificaGasRefrigerante = (gas: IGasRefrigerante) => ui.mountAsideModal(<GasRefrigeranteForm gas={gas} />)

    //Elimina gas
    const eliminaGasRefrigerante = (gas: IGasRefrigerante) => elimina(`carbon_footprint/gas_refrigeranti/${gas.nome}/${gas.anno}`)
        .then(() => {
            ui.mountDialogue(<Dialogue titolo="Gas eliminato con successo" />)
            listaGasRefrigeranti()
        }
        )
        .catch(() => {
            ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione del gas" />)
        })

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const { tabella } = useTabellaInterattivaContext()
    useEffect(() => { }, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    testo: 'Crea gas refrigerante',
                    azione: creaGasRefrigerante,
                    icona: <CgAdd size={18} />
                }])

            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaGasRefrigerante,
                    testo: 'Modifica',
                    icona: <FaPen size={12} />,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaGasRefrigerante,
                    testo: 'Elimina',
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                pk={["nome", "anno"]}
                endpoint="carbon_footprint/gas_refrigeranti"
                dati={fattoriGasRefrigeranti}
                titolo="Fattori di emissione dei gas refrigeranti"
                paragrafo="Inserisci i fattori di emissione per i gas refrigeranti."
                callToActions={ctaTabella}
                colonne={
                    [
                        { id: 'anno', label: 'Anno' },
                        { id: 'nome', label: 'Nome' },
                        { id: 'descrizione', label: 'Descrizione' },
                        { id: 'unita_misura', label: 'Unità di misura' },
                        { id: 'kg_co2e', label: 'Kg co2e' },
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default ConfigurazioneGasRefrigeranti;
