import React, { useEffect, useState } from 'react'

//Data
import { Controller, useForm } from 'react-hook-form'
import InputField from '../../../../../../components/common/form/InputField';
import { crea, get, modifica } from '../../../../../../rest/crud_generica';
import { IFattoreEmissioneElettrico } from '../../../../types'
import { useStores } from '../../../../../../hooks/useStores';

//Components
import Select from 'react-select'
import SpinnerButton from '../../../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../../components/common/InfoBox';
import { useTranslation } from 'react-i18next';

const EmissioniElettricitaForm: React.FC<{ elettricita?: IFattoreEmissioneElettrico }> = ({ elettricita }) => {

  const { ui } = useStores()
  const { t } = useTranslation()

  const [attesa, setAttesa] = useState<boolean>(false)
  const [successo, setSuccesso] = useState<string>('')
  const [errore, setErrore] = useState<string>('')

  const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      nome: elettricita ? elettricita.nome : '',
      paese: elettricita ? elettricita.paese : '',
      anno: elettricita ? elettricita.anno : '',
      kg_co2e: elettricita ? elettricita.kg_co2e : '',
    }
  });

  const [listaAnni, setListaAnni] = useState<any[]>([])
  const [paesi, setPaesi] = useState<[]>([])


  //setta i paesi
  useEffect(() => {
    get('paesi').then((res) => {
      setPaesi(res.data.data)
    })
  }, [])

  //Ristruttura dati per label MultiSelect
  paesi.map((paese: any) => {
    paese.value = paese.nome
    paese.label = paese.nome
  })


  //Setta gli anni
  useEffect(() => {
    listaAnno()
  }, [])

  //Ottieni lista gas
  const listaAnno = () => get('carbon_footprint/anni').then((res) => {
    setListaAnni(res.data.data)
  })


  //Ristruttura dati per label MultiSelect
  listaAnni.filter((um) => !um.label).map((listaAnno: any) => {
    listaAnno.value = listaAnno.anno
    listaAnno.label = listaAnno.anno
  })

  const onSubmit = () => {
    setAttesa(true)
    elettricita
      ?
      modifica(`carbon_footprint/elettricita_emissioni/${elettricita.nome}/${elettricita.paese}/${elettricita.anno}`, {
        nome: watch('nome'),
        paese: watch('paese'),
        anno: watch('anno'),
        kg_co2e: watch('kg_co2e')
      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Fattore di emissione elettrico modificato con successo')
          ui.triggerAggiornamentoGenerico()

        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
      :
      crea(`carbon_footprint/elettricita_emissioni`, {
        nome: watch('nome'),
        paese: watch('paese'),
        anno: watch('anno'),
        kg_co2e: watch('kg_co2e')
      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Fattore di emissione elettrico creato con successo')
          ui.triggerAggiornamentoGenerico()

        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
  }

  return (
    <div>
      <div>
        {elettricita ?
          <h2 className="text-2xl mb-2">Modifica il fattore di emissione {elettricita.nome}, per il paese {elettricita.paese} dell'anno {elettricita.anno} </h2>
          :
          <h2 className="text-2xl mb-2">Crea un nuovo fattore di emissione elettrico</h2>
        }
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>

        <InputField
          label="Nome"
          type="text"
          error={errors.nome}
          form={register("nome", { required: "Il nome è richiesto" })}
          placeholder="Nome"
        />

        <div className="form">
          <label>{t('common.paese')}</label>
          <Controller
            name="paese"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={paesi}
                defaultValue={paesi.find((t: any) => t.value === elettricita?.paese)}
                onChange={(e: any) => onChange(e.value)}
              />
            )}
          />
        </div>

        <div className="form w-full">
          <label htmlFor="">Anno</label>
          <Controller
            name="anno"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaAnni}
                onChange={(e: any) => onChange(e.value)}
              />
            )}
          />
        </div>

        <InputField
          label="Kg co2e"
          type="number"
          error={errors.kg_co2e}
          form={register("kg_co2e", { required: "Il fattore di emissione è richiesto" })}
          placeholder="CO2 Equivalente"
        />

        {
          !attesa ?
            <input type="submit" value={elettricita ? 'Salva modifiche' : 'Crea Emissione'} />
            : <SpinnerButton />
        }

        {(errore || successo) &&
          <div className="mt-2">
            {errore && <ErrorBox errore={errore} />}
            {successo && <InfoBox messaggio={successo} />}
          </div>
        }

      </form>
    </div>
  )
}

export default EmissioniElettricitaForm;