import React, { useEffect, useState } from 'react'
import { get } from '../../../../rest/crud_generica'
import GraficoAggregatoEmissioniAnno from '../grafici/GraficoAggregatoEmissioniAnno'
import TabellaInterattiva from '../../../../components/tabellaInterattiva/TabellaInterattiva'
import { FaAngleDown } from 'react-icons/fa'
import { IAnno } from '../../types'

const AnalisiCarbonFootprintAggregatoEmissioni = () => {
    const [annoAttivo, setAnnoAttivo] = useState(new Date().getFullYear())
    const [listaAnni, setListaAnni] = useState<IAnno[]>([])
    const [listaAnniVisibile, setListaAnniVisibile] = useState<boolean>(false)

    useEffect(() => {
        get('carbon_footprint/anni').then((res) => {
            setListaAnni(res.data.data)
        })
    }, [])

    const [aggregatoEmissioniAnno, setAggregatoEmissioniAnno] = useState([])

    useEffect(() => {
        get(`carbon_footprint/analisi/resoconto/anno/${annoAttivo}`).then((res) => setAggregatoEmissioniAnno(res.data.data))
    }, [annoAttivo])

    return (
        <div className='bg-white dark:bg-darkgray-dd'>

            <div className="py-6 text-center">
                <h1 className='flex items-center gap-1 justify-center my-2'>
                    <span className="text-3xl font-bold">Aggregato emissioni per business unit</span>


                    <div className="relative bg-lightgray cursor-pointer" onClick={() => setListaAnniVisibile(!listaAnniVisibile)}>
                        <div className='flex items-center gap-2 h-[60px] px-3 hover:bg-[rgba(0,0,0,0.1)] duration-200'>
                            <span className="text-3xl font-bold" >
                                {annoAttivo}
                            </span>
                            <div className={`duration-300 ${listaAnniVisibile && 'rotate-180'}`}>
                                <FaAngleDown />
                            </div>
                        </div>

                        {listaAnniVisibile &&
                            <div className='absolute bg-lightgray top-[60px] left-0 right-0 z-10'>
                                {
                                    listaAnni.map((anno: IAnno) => (
                                        <div
                                            onClick={() => setAnnoAttivo(anno.anno)}
                                            className='w-full px-4 py-1 font-bold text-3xl hover:bg-[rgba(0,0,0,0.1)] duration-200'
                                        >
                                            {anno.anno}
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>

                </h1>
                <p className="text-lg">Resoconto annuale della rendicontazione, utile a comprendere l'aggregato delle emissioni per anno (in t/ co2e)</p>
            </div>

            {aggregatoEmissioniAnno && aggregatoEmissioniAnno.length > 0 && <GraficoAggregatoEmissioniAnno aggregatoEmissioniAnno={aggregatoEmissioniAnno} />}

            <TabellaInterattiva
                titolo="Dati grezzi"
                dati={aggregatoEmissioniAnno}
                colonne={[
                    { id: 'business_unit', label: 'Business Unit', link: 'app/modulo/analisi_carbon_footprint/{{valore}}' },
                    { id: 'business_unit_padre', label: 'Business Unit Padre' },
                    { id: 'anno', label: 'Anno' },
                    { id: 'emissione_cumulata_t_co2e_combustibile', label: 'Emissioni degli impianti stazionati (t / co2e)' },
                    { id: 'emissione_cumulata_t_co2e_veicoli', label: 'Emissioni dei veicoli (t / co2e)' },
                    { id: 'emissione_cumulata_t_co2e_gas_refrigerante', label: 'Emissioni dei gas refrigeranti (t / co2e)' },
                    { id: 'emissione_cumulata_t_co2e_elettricita', label: 'Emissioni elettriche (t / co2e)' },
                    { id: 'emissione_cumulata_t_co2e_totale', label: 'Emissioni totali (t / co2e)' },
                ]}
            />

        </div>
    )
}

export default AnalisiCarbonFootprintAggregatoEmissioni;