import React, { useEffect, useState } from 'react'

//Data
import { Controller, useForm } from 'react-hook-form'
import { crea, get, modifica } from '../../../../../../rest/crud_generica';
import { getUnitaMisuraLista } from '../../../../../gestioneUnitaMisura/rest/gestioneUnitaMisura';
import { IGasRefrigerante } from '../../../../types'
import { useStores } from '../../../../../../hooks/useStores';

//Components
import InputField from '../../../../../../components/common/form/InputField';
import Select from 'react-select'
import SpinnerButton from '../../../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../../components/common/InfoBox';

const GasRefrigeranteForm: React.FC<{ gas?: IGasRefrigerante }> = ({ gas }) => {

  const { ui } = useStores()
  const [attesa, setAttesa] = useState<boolean>(false)
  const [successo, setSuccesso] = useState<string>('')
  const [errore, setErrore] = useState<string>('')

  const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      anno: gas ? gas.anno : '',
      nome: gas ? gas.nome : '',
      descrizione: gas ? gas.descrizione : '',
      unita_misura: gas ? gas.unita_misura : '',
      kg_co2e: gas ? gas.kg_co2e : '',
    }
  });

  const [listaUnitaMisura, setListaUnitaMisura] = useState<any[]>([])

  //Setta le unità di misura
  useEffect(() => {
    getUnitaMisuraLista().then((res) => {
      setListaUnitaMisura([...res.data.data, {
        label: 'Nessuna',
        value: null
      }])
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //Ristruttura dati per label MultiSelect
  listaUnitaMisura.filter((um) => !um.label).map((unitaMisura: any) => {
    unitaMisura.value = unitaMisura.codice
    unitaMisura.label = unitaMisura.codice
  })

  const onSubmit = () => {
    setAttesa(true)
    gas
      ?
      modifica(`carbon_footprint/gas_refrigeranti/${gas.nome}/${gas.anno}`, {
        anno: watch('anno'),
        nome: watch('nome'),
        descrizione: watch('descrizione'),
        unita_misura: watch('unita_misura'),
        kg_co2e: watch('kg_co2e')
      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Gas modificato con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
      :
      crea(`carbon_footprint/gas_refrigeranti`, {
        anno: watch('anno'),
        nome: watch('nome'),
        descrizione: watch('descrizione'),
        unita_misura: watch('unita_misura'),
        kg_co2e: watch('kg_co2e')
      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Gas creato con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
  }

  const [listaAnni, setListaAnni] = useState<any[]>([])

  //Ottieni lista gas
  const listaAnno = () => get('carbon_footprint/anni').then((res) => {
    setListaAnni(res.data.data)
  })

  useEffect(() => {
    listaAnno()
  }, [])

  //Ristruttura dati per label MultiSelect
  listaAnni.filter((um) => !um.label).map((listaAnno: any) => {
    listaAnno.value = listaAnno.anno
    listaAnno.label = listaAnno.anno
  })

  return (
    <div>
      <div>
        {gas ?
          <h2 className="text-2xl mb-2 capitalize">Modifica il gas refrigerante {gas.nome}</h2>
          :
          <h2 className="text-2xl mb-2 capitalize">Crea un nuovo gas refrigerante</h2>
        }
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>

        <div className="form w-full">
          <label htmlFor="">Anno</label>
          <Controller
            name="anno"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaAnni}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={gas?.anno && {
                  value: gas.anno,
                  label: gas.anno,
                }}
              />
            )}
          />
        </div>

        <InputField
          label="Nome"
          type="text"
          error={errors.nome}
          form={register("nome", { required: "Il nome è richiesto" })}
          placeholder="Nome"
        />

        <InputField
          label="Descrizione"
          type="textarea"
          error={errors.descrizione}
          form={register("descrizione")}
          placeholder="Descrizione"
        />

        <div className="form w-full">
          <label htmlFor="">Unità di misura</label>
          <p className="opacity-70 mb-2">Puoi inserire nuove unità di misura dal modulo "Gestione unità di misura"</p>
          <Controller
            name="unita_misura"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaUnitaMisura}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={gas?.unita_misura && {
                  value: gas.unita_misura,
                  label: gas.unita_misura,
                }}
              />
            )}
          />
        </div>

        <InputField
          label="Kg co2e"
          type="number"
          error={errors.kg_co2e}
          form={register("kg_co2e", { required: "Il fattore di emissione è richiesto" })}
          placeholder="CO2 Equivalente"
        />

        {
          !attesa ?
            <input type="submit" value={gas ? 'Salva modifiche' : 'Crea Gas'} />
            : <SpinnerButton />
        }

        {(errore || successo) &&
          <div className="mt-2">
            {errore && <ErrorBox errore={errore} />}
            {successo && <InfoBox messaggio={successo} />}
          </div>
        }

      </form>
    </div>
  )
}

export default GasRefrigeranteForm;