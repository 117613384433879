import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { IFattoreESG } from '../../../types'
import { observer } from 'mobx-react-lite'
import { config } from '../../../../../config'
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo'

//Components
import { FaAngleDown, FaAngleUp, FaEllipsisV } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import BottoneAttribuisciFattore from '../bottoni/BottoneAttribuisciFattore'
import BottoneAggiungiFattore from '../bottoni/BottoneAggiungiFattore'
import HoverFattoriBilancio from './HoverFattoriBilancio'
import BloccoFattoreBilancioContextMenu from '../ContextMenu/BloccoFattoreBilancioContextMenu'
import BloccoFattoreContextMenu from '../ContextMenu/BloccoFattoreContextMenu'
import BloccoStandardContextMenu from '../ContextMenu/BloccoStandardContextMenu'
import IndicatoreTabellaMinificata from '../../indicatori/IndicatoreTabellaMinificata'
import BloccoFattoreDefaultTabella from './BloccoFattoreDefaultTabella'
import FattoreBilancioBusinessUnitForm from '../../bilanci/FattoreBilancioBusinessUnitForm'

const BloccoFattoreDefault: React.FC<{ fattore: IFattoreESG, loader: "bilanci" | "fattori_esg" }> = observer(({ fattore, loader }) => {

  const privilegio = useOttieniPrivilegioModulo();
  const { bilanci } = useModuloESGStores()
  const { t } = useTranslation();

  const { ui, moduli } = useStores();

  const { ui_canvas } = useModuloESGStores();

  //Gestisce la presenza del menu contestuale
  const [contextMenu, setContextMenu] = useState<boolean>(false);

  //Gestisce la possibilità di nascondere la lista dei sottofattori
  const [minificato, setMinificato] = useState<boolean>(false);

  //Gestisce la lista dei sottofattori
  const [sottoFattori, setSottoFattori] = useState<IFattoreESG[]>([]);

  //Gestisce la lista delle sottocategorie
  const [sottoCategorie, setSottoCategorie] = useState<IFattoreESG[]>([]);

  //Gestisce righe e colonne in caso di tabella
  const [righe, setRighe] = useState<IFattoreESG[]>([]);
  const [colonne, setColonne] = useState<IFattoreESG[]>([]);

  //Stabilisce se il link porta al modulo dei fattori o dei bilancii 
  const [link, setLink] = useState<string>('')

  //Stabilisce visibilità lista completa fattori ESG
  const [vistaCompletaSDGs, setVistaCompletaSDGs] = useState<boolean>(false)

  //Setta gli stati partendo dai dati ereditati
  useEffect(() => {
    setSottoFattori(fattore.sotto_fattori.filter(fat => fat.tipo === 'tabella' || fat.tipo === 'fattore'))
    setSottoCategorie(fattore.sotto_fattori.filter(fat => fat.tipo === 'categoria' || fat.tipo === 'standard'))
    setRighe(fattore.sotto_fattori.filter(fat => fat.tipo === 'riga'))
    setColonne(fattore.sotto_fattori.filter(fat => fat.tipo === 'colonna'))
  }, [fattore])

  const styles = {
    wrapper: "flex items-stretch justify grow relative",
    tileFattore: `
      relative left-2 ml-2 bg-white hover:bg-ligthgray hover:border-accent duration-100
      p-4 rounded-sm border border-lightgray-dd dark:bg-black dark:border-darkgray-dd tabella ${fattore.tipo !== 'tabella' && 'w-[250px]'}
      `,
    sotto_fattori: "w-3 h-3 flex items-center justify-center absolute right-[-8px] bg-white z-10 rounded-full border border-accent hover:text-white cursor-pointer text-accent hover:bg-accent"
  }

  //Stato context menu
  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  //Chiusura context menu
  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })

  //Punta il link in base alla sezione
  useEffect(() => {
    if (loader === 'fattori_esg') {
      setLink(`/app/modulo/${moduli.moduloAttivo?.codice}/${fattore.codice}`)
    } else if (loader === 'bilanci') {
      setLink(`/app/modulo/${moduli.moduloAttivo?.codice}/${fattore.bilancio}/fattori_esg/${fattore.codice}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`${styles.wrapper}`}>
      <div className="w-2 h-[2px] bg-accent dark:bg-dark-accent absolute top-1/2 left-2" />

      {/* Blocco */}
      <div
        className={styles.tileFattore}
        onMouseEnter={() => ui.mountFollowMouse(<HoverFattoriBilancio fattore={fattore} />)}
        onMouseLeave={() => ui.unmountFollowMouse()}
      >

        {/* Barra sinistra temi di materialità */}
        {fattore.temi_materialita && fattore.temi_materialita.length > 0 &&
          <div
            className={`absolute left-0 top-0 bottom-0 flex flex-col duration-100 shadow-xl cursor-pointer ${vistaCompletaSDGs ? 'w-3/4' : 'w-[5px]'}`}
            onClick={() => setVistaCompletaSDGs(!vistaCompletaSDGs)}
          >
            {
              fattore.temi_materialita.map(tema => (
                <div
                  onMouseEnter={() => ui.mountFollowMouse(
                    <div>
                      <p>tema di materialità:</p>
                      <p className="font-bold">
                        {tema.nome}
                      </p>
                    </div>
                  )}
                  className="grow"
                  style={{ backgroundColor: tema.colore }}
                >
                  {vistaCompletaSDGs &&
                    <div className="flex items-center justify-start gap-2 h-full pl-2">
                      <span className="text-white text-xs">{tema.nome.substring(0, 25)}{tema.nome.length > 25 && '...'}</span>
                    </div>
                  }
                </div>
              ))
            }
          </div>
        }

        {/* Metadati fattore */}
        <div className="flex justify-between items-start w-full gap-2">

          <div className="hover:underline">
            <Link to={link}>
              {
                fattore.nome && fattore.nome !== fattore.nome_esteso &&
                <p className="text-xs font-bold text-accent dark:text-dark-accent mb-1" dangerouslySetInnerHTML={
                  { __html: fattore.nome.replace(ui_canvas.stringa_ricerca, `<span class="text-xs bg-accent dark:text-dark-accent text-white">${ui_canvas.stringa_ricerca}</span>`) }
                } />
              }
              {
                fattore.nome_esteso &&
                <h5 className="text-text dark:text-white leading-5" dangerouslySetInnerHTML={
                  { __html: fattore.nome_esteso.replace(ui_canvas.stringa_ricerca, `<span class="bg-accent dark:text-dark-accent text-white">${ui_canvas.stringa_ricerca}</span>`) }
                } />
              }
            </Link>
          </div>

          {
            loader === 'bilanci' &&
            moduli.moduloAttivo?.codice !== 'bilanci' &&
            privilegio >= 3 &&
            <div className="p-2 cursor-pointer hover:bg-lightgray-d relative top-[-8px] right-[-8px]" onClick={() => toggleContextMenu()}>
              {<FaEllipsisV className="hover:opacity-70" />}

              {contextMenu && fattore.tipo !== 'standard' && <BloccoFattoreBilancioContextMenu fattore={fattore as IFattoreESG} />}
              {contextMenu && fattore.tipo === 'standard' && <BloccoStandardContextMenu standard={fattore as IFattoreESG} />}
            </div>
          }

          {
            loader === 'fattori_esg' &&
            privilegio >= 3 &&
            <div className="p-2 cursor-pointer hover:bg-lightgray-d relative top-[-8px] right-[-8px]" onClick={() => toggleContextMenu()}>
              {<FaEllipsisV className="hover:opacity-70" />}

              {contextMenu && <BloccoFattoreContextMenu fattore={fattore as IFattoreESG} />}
            </div>
          }

        </div>

        {/* Lista risposte multiple */}
        {fattore.tipo_indicatore === 'risposta_multipla' && fattore.risposte_multiple.length > 0 &&
          <div className="my-2">
            <p className="text-sm">Risposte possibili:</p>
            <div className="flex flex-col gap-[6px]">
              {fattore.risposte_multiple.map((risposta: string) => (
                <div className="p-[6px] bg-lightgray dark:bg-darkgray text-sm">
                  {risposta}
                </div>
              ))}
            </div>
          </div>
        }

        {
          fattore.tipo === 'tabella' &&
          <div className="my-4">
            {loader === 'bilanci' && <IndicatoreTabellaMinificata tabella={fattore} />}
            {loader === 'fattori_esg' &&
              <BloccoFattoreDefaultTabella
                fattore={fattore}
                colonne={colonne.sort((a, b) => (a.codice > b.codice) ? 1 : ((b.codice > a.codice) ? -1 : 0))}
                righe={righe.sort((a, b) => (a.codice > b.codice) ? 1 : ((b.codice > a.codice) ? -1 : 0))} />
            }
          </div>
        }

        {/* Etichette fattore */}
        <div className="text-white text-right mt-4 flex gap-[2px] items-stretch">

          {/* Info fattore core */}
          {
            fattore.richiesto &&
            <div className="px-1 py-1 bg-red dark:bg-dark-red flex items-center justify-center text-center rounded-sm capitalize">
              <span className="text-[10px]">{t('ModuloFattoriEsg.BloccoFattore.core')}</span>
            </div>
          }

          {/* Info tipo fattore */}
          <div className="px-1 py-1 bg-accent dark:bg-dark-accent flex items-center justify-center text-center rounded-sm capitalize">
            <span className="text-[10px]">{fattore.tipo}</span>
          </div>

          {/* Info tipo indicatore fattore */}
          {(fattore.tipo === 'fattore' || fattore.tipo === 'tabella') &&
            <div className="px-1 py-1 bg-accent dark:bg-dark-accent flex items-center justify-center text-center rounded-sm">
              {fattore.tipo_indicatore ?
                <span>
                  <span className="capitalize text-[10px]">Rendicontazione: {fattore.tipo_indicatore}</span>
                  <span className="text-[10px]">{fattore.unita_misura && ` (${fattore.unita_misura})`}</span>
                </span>
                :
                <span
                  className="text-[10px]"
                  onMouseEnter={() => ui.mountFollowMouse(<span>Questo fattore può essere rendicontato con qualsiasi tipologia di indicatore</span>)}
                  onMouseLeave={() => ui.unmountFollowMouse()}
                >
                  Rendicontazione libera
                </span>
              }
            </div>
          }

        </div>

        {/* Obiettivi agenda 2030 collegati */}
        {fattore.obiettivi_agenda2030 && fattore.obiettivi_agenda2030.length > 0 &&
          <div className="mt-2">
            <p className="font-bold">{fattore.obiettivi_agenda2030.length} obiettivi dell'agenda 2030 collegati:</p>
            <div className="flex flex-wrap gap-2 mt-2">
              {fattore.obiettivi_agenda2030?.map(obiettivo => (
                <div
                  onMouseEnter={() => ui.mountFollowMouse(<div>{obiettivo.nome}</div>)}
                  onMouseLeave={() => ui.unmountFollowMouse()}
                  style={{ backgroundColor: obiettivo.colore }}
                  className="w-[40px] h-[40px] flex items-center justify-center">
                  <div className="w-[20px]">
                    <img src={`${config.BACKEND_URI}icone_obiettivi_2030/${obiettivo.immagine}`} alt={obiettivo.descrizione} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        }

        {/* Business unit fattore */}
        {loader === 'bilanci' &&
          <>
            {
              fattore.business_units.length > 0 ?
                <div className="p-2 bg-lightgray dark:bg-darkgray-d mt-4 text-center cursor-default hover:bg-lightgray-dd rounded-sm"
                  onMouseEnter={() => ui.mountFollowMouse(
                    <div className="flex flex-col gap-1">
                      {
                        fattore.business_units.map((businessUnit) => {
                          return (
                            <div
                              className="dark:text-white bg-lightgray dark:bg-darkgray p-1 rounded-sm"
                              key={fattore.codice + businessUnit.nome}>
                              {businessUnit.nome}
                            </div>
                          )
                        })
                      }
                    </div>
                  )}
                  onMouseLeave={() => ui.unmountFollowMouse()}
                >
                  {fattore.business_units.length} {t('ModuloFattoriEsg.BloccoFattore.businessUnit')} <br /> {t('ModuloFattoriEsg.BloccoFattore.collegate')}
                </div>
                :
                <div className="mt-2">
                  {moduli.moduloAttivo?.codice === 'bilanci_fattori_business_units' ?
                    <div className="cursor-pointer" onClick={() => ui.mountBigScreenModal(
                      <FattoreBilancioBusinessUnitForm
                        fattore={fattore}
                        bilancio={bilanci.bilancioAttivo?.anno as number}
                      />)}>
                      <button className="bg-accent dark:bg-dark-accent text-white p-2 w-full">
                        {t('ModuloFattoriEsg.BloccoFattore.collegaBusinessUnitABilancio')}
                      </button>
                    </div>
                    :
                    <Link to={`/app/modulo/bilanci_fattori_business_units/${bilanci.bilancioAttivo?.anno}/fattori_esg`}>
                      <button className="bg-accent dark:bg-dark-accent text-white p-2 w-full">
                        {t('ModuloFattoriEsg.BloccoFattore.vaiAlModuloBUPerCollegareBusinessUnitABilancio')}
                      </button>
                    </Link>
                  }
                </div>
            }
          </>
        }

      </div>


      {/* Sotto fattori */}
      {
        fattore.tipo !== 'tabella' &&
        <div className="flex gap-2 py-4 flex-col grow">

          {loader === "bilanci" &&
            privilegio >= 2 &&
            (fattore.tipo !== 'fattore') &&
            moduli.moduloAttivo?.codice === 'bilanci_fattori' &&
            <BottoneAttribuisciFattore fattore={fattore} />
          }

          {
            loader === "fattori_esg" &&
            privilegio >= 2 &&
            (fattore.tipo === 'categoria' || fattore.tipo === 'standard') &&
            <BottoneAggiungiFattore codice_padre={fattore.codice} />
          }

          {/* Lista sotto categorie */}
          <div className="flex flex-col gap-2">
            {
              sottoCategorie && sottoCategorie.map((sotto_fattore: any) => (
                <SottoBloccoFattoreDefault key={sotto_fattore.codice} fattore={sotto_fattore} loader={loader} />
              ))
            }
          </div>



          {
            sottoFattori.length > 0 &&
            <div className="relative flex flex-col gap-2 border border-accent dark:border-dark-accent ml-4 min-w-[250px] w-fit">
              <div className="w-2 h-[2px] bg-accent absolute top-1/2 left-[-12px]"></div>

              <button
                onClick={() => setMinificato(!minificato)}
                className="flex items-center justify-between relative p-4 cursor-pointer text-white"
              >
                <span className="text-center duration-100">{minificato ? t('common.nascondi') : t('common.mostra')} {sottoFattori.length} {t('ModuloFattoriEsg.BloccoFattore.fattori')}</span>
                <span>{minificato ? <FaAngleUp /> : <FaAngleDown />}</span>
              </button>

              {/* Lista sotto fattori e tabelle */}
              {
                minificato &&
                <div className="flex flex-col gap-2 pr-2 pb-4 max-h-[600px] ">
                  {
                    sottoFattori && sottoFattori.map((sotto_fattore: any) => (
                      <div className="relative left-[-12px]">
                        <SottoBloccoFattoreDefault key={sotto_fattore.codice} fattore={sotto_fattore} loader={loader} />
                      </div>
                    ))
                  }
                </div>
              }
            </div>
          }
        </div>
      }

    </div>
  )
})

//Component dei sottofattori per recursività
const SottoBloccoFattoreDefault: React.FC<{ fattore: IFattoreESG, loader: "bilanci" | "fattori_esg" }> = ({ fattore, loader }) => {
  return (
    <BloccoFattoreDefault fattore={fattore} loader={loader} />
  )
}

export default BloccoFattoreDefault;