import React, { useEffect, useState } from 'react'
import { get } from '../../../rest/crud_generica'
import { IAnno } from '../types'
import { observer } from 'mobx-react'
import { useStatoRendicontazioneContext } from './context/useStatoRendicontazioneContext'
import { FaAngleDown } from 'react-icons/fa'

const AnnoAttivoRendicontazioneCarbonFootprint = observer(() => {

    const [listaVisibile, setListaVisibile] = useState<boolean>(false)
    const [listaAnni, setListaAnni] = useState<IAnno[]>([])
    const { stato_rendicontazione } = useStatoRendicontazioneContext()

    useEffect(() => {
        get('carbon_footprint/anni').then((res) => {
            setListaAnni(res.data.data)
        })
    }, [])

    return (
        <div className='absolute left-2 top-[-45px] h-[45px] pl-2 bg-accent text-white flex gap-3 items-center'>
            <p className="font-bold">Anno attivo: {stato_rendicontazione.annoAttivo}</p>

            <div onClick={() => setListaVisibile(!listaVisibile)} className="cursor-pointer w-8 relative">
                <div className="flex items-center justify-center gap-1 p-2 duration-200 hover:bg-[rgba(0,0,0,0.2)]">
                    <span>Cambia</span> <div className={`${listaVisibile && 'rotate-180'}`}><FaAngleDown /></div>
                </div>

                {listaVisibile &&
                    <ListaAnni anni={listaAnni} />
                }

            </div>

        </div>
    )
})

const ListaAnni: React.FC<{ anni: IAnno[]}> = observer(({anni}) => {

    const { stato_rendicontazione } = useStatoRendicontazioneContext()

    return (
        <div className="flex flex-col w-full items-stretch absolute right-0 bg-accent max-h-[200px] overflow-y-auto">
            {anni.map((anno) => (
                <div
                    onClick={() => stato_rendicontazione.settaAnnoAttivo(anno.anno)}
                    className="p-2 text-center  duration-200 hover:bg-[rgba(0,0,0,0.2)]"
                >
                    {anno.anno}
                </div>
            ))}
        </div>
    )
})

export default AnnoAttivoRendicontazioneCarbonFootprint;