import React, { useEffect, useState } from 'react'
import { get } from '../../../../rest/crud_generica'
import GraficoStoricoRendicontazione from '../grafici/GraficoStoricoRendicontazione'
import TabellaInterattiva from '../../../../components/tabellaInterattiva/TabellaInterattiva'
import GraficoBusinessUnitsRendicontabili from '../grafici/GraficoBusinessUnitsRendicontabili'

const AnalisiCarbonFootprintBusinessUnitRendicontabili = () => {
    const [businessUnitsRendicontabili, setBusinessUnitsRendicontabili] = useState([])

    useEffect(() => {
        get('carbon_footprint/analisi/business_units_rendicontabili_resoconto').then((res) => setBusinessUnitsRendicontabili(res.data.data))
    }, [])

    return (
        <div className='bg-white dark:bg-darkgray-dd'>

            <div className="py-6 text-center">
                <h1 className='text-3xl font-bold'>Business Units Rendicontabili</h1>
                <p className="text-lg">Lista di tutte le business units che possiedono almeno un gruppo e un utente attivo, con il rispettivo stato di rendicontazione</p>
            </div>

            <GraficoBusinessUnitsRendicontabili businessUnitsRendicontabili={businessUnitsRendicontabili} />

            <TabellaInterattiva
                titolo="Dati grezzi"
                dati={businessUnitsRendicontabili}
                colonne={[
                    { id: 'business_unit', label: 'Business Unit', link: 'app/modulo/analisi_carbon_footprint/{{valore}}' },
                    { id: 'anno', label: 'Anno' },
                    { id: 'n_gruppi', label: 'Numero di gruppi' },
                    { id: 'n_utenti_attivi', label: 'Numero di utenti attivi' },
                    { id: 'emissione_totale_t_co2e_combustibile', label: 'Emissioni degli impianti stazionati (t / co2e)' },
                    { id: 'emissione_totale_t_co2e_veicoli', label: 'Emissioni dei veicoli (t / co2e)' },
                    { id: 'emissione_totale_t_co2e_gas_refrigerante', label: 'Emissioni dei gas refrigeranti (t / co2e)' },
                    { id: 'emissione_totale_t_co2e_elettricita', label: 'Emissioni elettriche (t / co2e)' },
                ]}
            />
        </div>
    )
}

export default AnalisiCarbonFootprintBusinessUnitRendicontabili;