import React from 'react'
import ReactEcharts from "echarts-for-react"
import { useStores } from '../../../../hooks/useStores'
import { observer } from 'mobx-react'

const GraficoStoricoRendicontazione: React.FC<{ storicoRendicontazione: any[] }> = ({ storicoRendicontazione }) => {

  let heatmap: any[] = []
  let min = 0
  let maxVeicoli = Math.max(...storicoRendicontazione.map(item => item.record_carburanti + item.record_elettricita + item.record_gas_refrigeranti + item.record_veicoli))

  storicoRendicontazione.forEach(item => {

    heatmap.push({
      ...item,
      opacity: Math.floor((100 * (item.record_carburanti + item.record_elettricita + item.record_gas_refrigeranti + item.record_veicoli)) / maxVeicoli) * 0.01
    })

  })

  return (
    <div className="px-5">

      <div className="flex items-center gap-[6px] mb-4 justify-center">
        <span className="text-xs font-bold">Less</span>
        <div style={{ backgroundColor: 'rgba(56, 75, 200, 0.2)' }} className="h-1 w-3"></div>
        <div style={{ backgroundColor: 'rgba(56, 75, 200, 0.4)' }} className="h-1 w-3"></div>
        <div style={{ backgroundColor: 'rgba(56, 75, 200, 0.6)' }} className="h-1 w-3"></div>
        <div style={{ backgroundColor: 'rgba(56, 75, 200, 0.8)' }} className="h-1 w-3"></div>
        <span className="text-xs font-bold">More</span>
      </div>

      <p className="my-1 font-bold">{storicoRendicontazione[0].giorno.split(' ')[0]}</p>

      <div className="bg-[rgba(0,0,0,0.3)]">
        <div className="grid gap-[1px] p-[1px]" style={{ gridTemplateColumns: 'repeat(23, 1fr)' }}>
          {
            heatmap.map((item) => (
              <TileStoricoRendicontazione item={item} />
            ))
          }
        </div>
      </div>

      <p className="my-1 font-bold text-right">{storicoRendicontazione[storicoRendicontazione.length - 1].giorno.split(' ')[0]}</p>
    </div>
  )
}

const TileStoricoRendicontazione: React.FC<{ item: any }> = observer(({ item }) => {

  const { ui } = useStores()

  return (
    <div
      className="duration-200 overflow-hidden hover:opacity-[0.8] cursor-default relative bg-white h-[50px]"
      onMouseEnter={() => ui.mountFollowMouse(<ModalTileStoricoRendicontazione item={item} />)}
      onMouseLeave={() => ui.unmountFollowMouse()}
    >
      <div
        className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'
        style={{ backgroundColor: `rgba(56, 75, 200, ${item.opacity})` }}
      >
        <span className={`text-xs font-bold ${item.opacity > 0.7 && 'text-white'}`}>{item.record_carburanti + item.record_elettricita + item.record_gas_refrigeranti + item.record_veicoli > 0 && item.record_carburanti + item.record_elettricita + item.record_gas_refrigeranti + item.record_veicoli}</span>
      </div>
    </div >
  )
})

const ModalTileStoricoRendicontazione: React.FC<{ item: any }> = ({ item }) => {
  return (
    <div>
      <p className='font-bold'>{item.giorno.split(' ')[0]}</p>
      <ul>
        {item.record_veicoli > 0 && <li>Record veicoli: {item.record_veicoli}</li>}
        {item.record_gas_refrigeranti > 0 && <li>Record gas refrigeranti: {item.record_gas_refrigeranti}</li>}
        {item.record_carburanti > 0 && <li>Record carburanti: {item.record_carburanti}</li>}
        {item.record_elettricita > 0 && <li>Record elettricita: {item.record_elettricita}</li>}
      </ul>
    </div>
  )
}

export default GraficoStoricoRendicontazione;