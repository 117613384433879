import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../../../../../hooks/useStores'
import { ICarburantiEmissioni } from '../../../../types'
import { crea, elimina, get } from '../../../../../../rest/crud_generica'

//Components
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'
import Dialogue from '../../../../../../components/common/Dialogue'
import { CgAdd } from 'react-icons/cg'
import { FaPen, FaTrash } from 'react-icons/fa'
import CarburantiFattoriEmissioneForm from './CarburantiFattoriEmissioneForm'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'
import { ICallToActionTabella } from '../../../../../../components/tabellaInterattiva/types'
import { useTabellaInterattivaContext } from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'

const CarburantiFattoriEmissione = observer(() => {

    const { t } = useTranslation()
    const privilegio = useOttieniPrivilegioModulo()
    const { ui } = useStores()

    const [carburantiEmissioni, setCarburantiEmissioni] = useState<ICarburantiEmissioni[]>([])

    useEffect(() => {
        listaCarburantiEmissioni()
    }, [ui.aggiornamentoGenerico])

    //Ottieni lista emissione
    const listaCarburantiEmissioni = () => get('carbon_footprint/carburanti_fattori_emissione').then((res) => {
        setCarburantiEmissioni(res.data.data)
    })

    //Crea emissione
    const creaCarburantiEmissione = () => ui.mountAsideModal(<CarburantiFattoriEmissioneForm />)

    //Modifica emissione
    const modificaCarburantiEmissione = (emissione: ICarburantiEmissioni) => ui.mountAsideModal(<CarburantiFattoriEmissioneForm emissione={emissione} />)

    //Elimina emissione
    const eliminaCarburantiEmissione = (emissione: ICarburantiEmissioni) => elimina(`carbon_footprint/carburanti_fattori_emissione/${emissione.carburante}/${emissione.anno}/${emissione.unita_misura}`)
        .then(() => {
            ui.mountDialogue(<Dialogue titolo="Fattore di emissione eliminato con successo" />)
            listaCarburantiEmissioni()
        }
        )
        .catch(() => {
            ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione del fattore di emissione" />)
        })

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const { tabella } = useTabellaInterattivaContext()
    useEffect(() => { }, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    testo: 'Crea fattore',
                    azione: creaCarburantiEmissione,
                    icona: <CgAdd size={18} />
                }])

            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaCarburantiEmissione,
                    testo: 'Modifica',
                    icona: <FaPen size={12} />,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaCarburantiEmissione,
                    testo: 'Elimina',
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={carburantiEmissioni}
                titolo="Fattori di emissione dei carburanti"
                paragrafo="Inserisci i fattori di emissione dei carburanti."
                endpoint="carbon_footprint/carburanti_fattori_emissione"
                pk={['carburante']}
                callToActions={ctaTabella}
                colonne={
                    [
                        { id: 'nome', label: 'nome' },
                        { id: 'anno', label: 'Anno' },
                        { id: 'unita_misura', label: 'Unità di misura' },
                        { id: 'kg_co2e', label: 'Kg co2e' },
                        { id: 'calore', label: 'Calore' },
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default CarburantiFattoriEmissione;