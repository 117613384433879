
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import ErrorBox from '../../../../../../components/common/ErrorBox'
import InputField from '../../../../../../components/common/form/InputField'
import InfoBox from '../../../../../../components/common/InfoBox'
import SpinnerButton from '../../../../../../components/common/SpinnerButton'
import { useStores } from '../../../../../../hooks/useStores'
import { crea, get, modifica } from '../../../../../../rest/crud_generica'
import { getUnitaMisuraLista } from '../../../../../gestioneUnitaMisura/rest/gestioneUnitaMisura'
import { ICarburantiEmissioni } from '../../../../types'

const CarburantiFattoriEmissioneForm: React.FC<{ emissione?: ICarburantiEmissioni }> = ({ emissione }) => {
  const { ui } = useStores()
  const [attesa, setAttesa] = useState<boolean>(false)
  const [successo, setSuccesso] = useState<string>('')
  const [errore, setErrore] = useState<string>('')

  const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      carburante: emissione ? emissione.carburante : '',
      anno: emissione ? emissione.anno : '',
      unita_misura: emissione ? emissione.unita_misura : '',
      kg_co2e: emissione ? emissione.kg_co2e : '',
      calore: emissione ? emissione.calore : '',

    }
  });
  const [listaAnni, setListaAnni] = useState<any[]>([])
  const [listaUnitaMisura, setListaUnitaMisura] = useState<any[]>([])
  const [datiSelectCarburanti, setListaTipologia,] = useState<any[]>([])

  useEffect(() => {
    listaCarburanti()
  }, [])

  //Ottieni lista gas
  const listaCarburanti = () => get('carbon_footprint/carburanti').then((res) => {
    setListaTipologia(res.data.data)
  })

  //Ristruttura dati per label MultiSelect
  datiSelectCarburanti.filter((dato) => !dato.label).map((listaCarburanti: any) => {
    listaCarburanti.value = listaCarburanti.nome
    listaCarburanti.label = listaCarburanti.nome
  })
  //Setta gli anni
  useEffect(() => {
    listaAnno()
  }, [])

  //Ottieni lista gas
  const listaAnno = () => get('carbon_footprint/anni').then((res) => {
    setListaAnni(res.data.data)
  })


  //Ristruttura dati per label MultiSelect
  listaAnni.filter((listaAnno) => !listaAnno.label).map((listaAnno: any) => {
    listaAnno.value = listaAnno.anno
    listaAnno.label = listaAnno.anno
  })

  useEffect(() => {
    getUnitaMisuraLista().then((res) => {
      setListaUnitaMisura([...res.data.data, {
        label: 'Nessuna',
        value: null
      }])
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //Ristruttura dati per label MultiSelect
  listaUnitaMisura.filter((um) => !um.label).map((unitaMisura: any) => {
    unitaMisura.value = unitaMisura.codice
    unitaMisura.label = unitaMisura.codice
  })

  const onSubmit = () => {
    setAttesa(true)
    emissione
      ?
      modifica(`carbon_footprint/carburanti_fattori_emissione/${emissione.carburante}/${emissione.anno}/${emissione.unita_misura}`, {
        carburante: watch('carburante'),
        anno: watch('anno'),
        unita_misura: watch('unita_misura'),
        kg_co2e: watch('kg_co2e'),
        calore: watch('calore'),
      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Fattore di emissione modificato con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
      :
      crea(`carbon_footprint/carburanti_fattori_emissione`, {
        carburante: watch('carburante'),
        anno: watch('anno'),
        unita_misura: watch('unita_misura'),
        kg_co2e: watch('kg_co2e'),
        calore: watch('calore'),
      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Fattore di emissione creato con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
  }

  return (
    <div>
      <div>
        {emissione ?
          <h2 className="text-2xl mb-2 capitalize">Modifica il fattore di emissione {emissione.carburante}</h2>
          :
          <h2 className="text-2xl mb-2 capitalize">Crea un nuovo fattore di emissione</h2>
        }
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>

        <div className="form w-full">
          <label htmlFor="">Tipologia di Carburante</label>
          <Controller
            name="carburante"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={datiSelectCarburanti}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={emissione?.carburante && {
                  value: emissione.carburante,
                  label: emissione.carburante
                }}
              />
            )}
          />
        </div>

        <div className="form w-full">
          <label htmlFor="">Anno</label>
          <Controller
            name="anno"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaAnni}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={emissione?.anno && {
                  value: emissione.anno,
                  label: emissione.anno
                }}
              />
            )}
          />
        </div>

        <InputField
          label="Kg co2e"
          type="number"
          error={errors.kg_co2e}
          form={register("kg_co2e", { required: "Il fattore di emissione è richiesto" })}
          placeholder="CO2 Equivalente"
        />

        <InputField
          label="Calore"
          type="number"
          error={errors.calore}
          form={register("calore", { required: "Il fattore di emissione è richiesto" })}
          placeholder="500"
        />

        <div className="form w-full">
          <label htmlFor="">Unità di misura</label>
          <p className="opacity-70 mb-2">Puoi inserire nuove unità di misura dal modulo "Gestione unità di misura"</p>
          <Controller
            name="unita_misura"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaUnitaMisura}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={emissione?.unita_misura && {
                  value: emissione.unita_misura,
                  label: emissione.unita_misura
                }}
              />
            )}
          />
        </div>
        {
          !attesa ?
            <input type="submit" value={emissione ? 'Salva modifiche' : 'Crea nuova emissione'} />
            : <SpinnerButton />
        }

        {(errore || successo) &&
          <div className="mt-2">
            {errore && <ErrorBox errore={errore} />}
            {successo && <InfoBox messaggio={successo} />}
          </div>
        }

      </form>
    </div>
  )
}

export default CarburantiFattoriEmissioneForm;