import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../../../../../hooks/useStores'
import { ICarburanti } from '../../../../types'
import { elimina, get } from '../../../../../../rest/crud_generica'

//Components
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'
import Dialogue from '../../../../../../components/common/Dialogue'
import { CgAdd } from 'react-icons/cg'
import { FaPen, FaTrash } from 'react-icons/fa'
import CostantiVeicoliForm from './CarburantiForm'
import { observer } from 'mobx-react-lite'
import CarburantiForm from './CarburantiForm'
import { useTranslation } from 'react-i18next'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'
import { ICallToActionTabella } from '../../../../../../components/tabellaInterattiva/types'
import { useTabellaInterattivaContext } from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'

const Carburanti = observer(() => {

    const { t } = useTranslation()
    const privilegio = useOttieniPrivilegioModulo()
    const { ui } = useStores()

    const [carburanti, setCarburanti] = useState<ICarburanti[]>([])

    useEffect(() => {
        listaCarburanti()
    }, [ui.aggiornamentoGenerico])

    //Ottieni lista Carburanti
    const listaCarburanti = () => get('carbon_footprint/carburanti').then((res) => {
        setCarburanti(res.data.data)
    })

    //Crea Carburanti
    const creaCarburante = () => ui.mountAsideModal(<CarburantiForm />)

    //Modifica Carburanti
    const modificaCarburante = (carburante: ICarburanti) => ui.mountAsideModal(<CostantiVeicoliForm carburante={carburante} />)

    //Elimina gas
    const eliminacarburante = (carburante: ICarburanti) => elimina(`carbon_footprint/carburanti/${carburante.nome}/${carburante.tipologia}`)
        .then(() => {
            ui.mountDialogue(<Dialogue titolo="carburante eliminato con successo" />)
            listaCarburanti()
        }
        )
        .catch(() => {
            ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione del carburante" />)
        })

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const { tabella } = useTabellaInterattivaContext()
    useEffect(() => { }, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    testo: 'Crea fattore',
                    azione: creaCarburante,
                    icona: <CgAdd size={18} />
                }])

            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaCarburante,
                    testo: 'Modifica',
                    icona: <FaPen size={12} />,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminacarburante,
                    testo: 'Elimina',
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={carburanti}
                titolo="Carburanti"
                paragrafo="Registro dei carburanti per i quali è possibile inserire un fattore di emissione ed una sorgente emissiva."
                endpoint="carbon_footprint/carburanti"
                pk={['nome']}
                callToActions={ctaTabella}
                colonne={
                    [
                        { id: 'nome', label: 'Nome' },
                        { id: 'tipologia', label: 'Tipo' },
                        { id: 'descrizione', label: 'Descrizione' },
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default Carburanti;