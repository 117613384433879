import React from 'react'
import { observer } from 'mobx-react';
import { FaSearch } from 'react-icons/fa';
import { useTabellaInterattivaContext } from './context/useTabellaInterattivaContext';
import { useTranslation } from 'react-i18next';


const BarraRicercaTabella = observer(() => {

    const { tabella } = useTabellaInterattivaContext()
    const {t} = useTranslation()
    return (
        <div className="flex items-center gap-1 border-b border-lightgray">
            <FaSearch className="opacity-30" />
            <input
                onChange={(e) => tabella.settaStringaFiltro(e.target.value)}
                className="focus:outline-none bg-[rgba(0,0,0,0)]"
                type="text"
                placeholder={t('tabellaInterattiva.filtraTabella')} />
        </div>
    )
})

export default BarraRicercaTabella;