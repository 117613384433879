import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../../../../../hooks/useStores'
import { IDimensioneVeicolo } from '../../../../types'
import { elimina, get } from '../../../../../../rest/crud_generica'
import { observer } from 'mobx-react'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'

//Components
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'
import Dialogue from '../../../../../../components/common/Dialogue'
import { CgAdd } from 'react-icons/cg'
import { FaPen, FaTrash } from 'react-icons/fa'
import DimensioniVeicoliForm from './DimensioniVeicoliForm'
import { ICallToActionTabella } from '../../../../../../components/tabellaInterattiva/types'
import { useTabellaInterattivaContext } from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'

const DimensioniVeicoli = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()
    const { ui } = useStores()

    const [dimensioniVeicoli, setDimensioniVeicoli] = useState<IDimensioneVeicolo[]>([])

    useEffect(() => {
        listaDimensioniVeicoli()
    }, [ui.aggiornamentoGenerico])

    //Ottieni lista gas
    const listaDimensioniVeicoli = () => get('carbon_footprint/veicoli_dimensioni').then((res) => {
        setDimensioniVeicoli(res.data.data)
    })

    //Crea gas
    const creaDimensioniVeicolo = () => ui.mountAsideModal(<DimensioniVeicoliForm />)

    //Modifica gas
    const modificaDimensioniVeicolo = (dimensioniVeicolo: IDimensioneVeicolo) => ui.mountAsideModal(<DimensioniVeicoliForm dimensioniVeicolo={dimensioniVeicolo} />)

    //Elimina gas
    const eliminaDimensioniVeicolo = (dimensioniVeicolo: IDimensioneVeicolo) => elimina(`carbon_footprint/veicoli_dimensioni/${dimensioniVeicolo.nome}`)
        .then(() => {
            ui.mountDialogue(<Dialogue titolo="Dimensione eliminata con successo" />)
            listaDimensioniVeicoli()
        }
        )
        .catch(() => {
            ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione della dimensione" />)
        })

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const { tabella } = useTabellaInterattivaContext()
    useEffect(() => { }, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    testo: 'Crea dimensione',
                    azione: creaDimensioniVeicolo,
                    icona: <CgAdd size={18} />
                }])

            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaDimensioniVeicolo,
                    testo: 'Modifica',
                    icona: <FaPen size={12} />,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaDimensioniVeicolo,
                    testo: 'Elimina',
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={dimensioniVeicoli}
                titolo="Dimensioni dei veicoli"
                paragrafo="Inserisci le dimensioni dei veicoli."
                endpoint="carbon_footprint/veicoli_dimensioni"
                pk={['nome']}
                callToActions={ctaTabella}
                colonne={
                    [
                        { id: 'nome', label: 'Nome' },
                        { id: 'descrizione', label: 'Descrizione' },
                        { id: 'unita_misura', label: 'Unità di misura' },
                        { id: 'kg_co2e', label: 'Kg co2e' },
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default DimensioniVeicoli;