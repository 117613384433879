import React, { useEffect, useState } from 'react'

//Data
import { Controller, useForm } from 'react-hook-form'
import { crea, get, modifica } from '../../../../../rest/crud_generica';
import { getUnitaMisuraLista } from '../../../../gestioneUnitaMisura/rest/gestioneUnitaMisura';
import { useStores } from '../../../../../hooks/useStores';
import { IEmissioneElettrica, IFattoreEmissioneElettrico } from '../../../types';
import { useTranslation } from 'react-i18next';

//Components
import InputField from '../../../../../components/common/form/InputField';
import SpinnerButton from '../../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../components/common/InfoBox';
import Select from 'react-select'
import Spinner from '../../../../../components/common/Spinner';


const RendicontazioneElettricitaForm: React.FC<{
    emissioneElettrica?: IEmissioneElettrica,
    businessUnit?: string,
    anno: number
    paese: string
}> = ({
    emissioneElettrica,
    businessUnit,
    anno,
    paese
}) => {

        const { t } = useTranslation()
        const { ui } = useStores()
        const [attesa, setAttesa] = useState<boolean>(false)
        const [successo, setSuccesso] = useState<string>('')
        const [errore, setErrore] = useState<string>('')

        const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
            defaultValues: {
                nome: emissioneElettrica ? emissioneElettrica.nome : '',
                fattore_emissione: emissioneElettrica ? emissioneElettrica.fattore_emissione : '',
                paese: emissioneElettrica ? emissioneElettrica.paese : '',
                elettricita: emissioneElettrica ? emissioneElettrica.elettricita : '',
            }
        });

        const [listaUnitaMisura, setListaUnitaMisura] = useState<any[]>([])

        //Setta le unità di misura
        useEffect(() => {
            getUnitaMisuraLista().then((res) => {
                setListaUnitaMisura([...res.data.data, {
                    label: t('common.nessuna'),
                    value: null
                }])
            })

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])


        //Ristruttura dati per label MultiSelect
        listaUnitaMisura.filter((um) => !um.label).map((unitaMisura: any) => {
            unitaMisura.value = unitaMisura.codice
            unitaMisura.label = unitaMisura.codice
        })

        const [paeseSelezionato, setPaeseSelezionato] = useState<string>(paese)

        useEffect(() => {
            if (!paeseSelezionato) {
                setPaeseSelezionato('Italy')
            }
        }, [])

        const onSubmit = () => {
            setAttesa(true)
            emissioneElettrica
                ?
                modifica(`carbon_footprint/rendicontazione/elettricita/${businessUnit}/${anno}/${emissioneElettrica.fattore_emissione}/${emissioneElettrica.nome}`, {
                    nome: watch('nome'),
                    fattore_emissione: watch('fattore_emissione'),
                    elettricita: watch('elettricita'),
                    business_unit: businessUnit,
                    anno: anno,
                    paese: paeseSelezionato,
                })
                    .then((res) => {
                        setErrore('')
                        setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendElettricita.form.successoModifica'))
                        ui.triggerAggiornamentoGenerico()
                    })
                    .catch((err) => {
                        setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                        setSuccesso('')
                    })
                    .finally(() => setAttesa(false))
                :
                crea(`carbon_footprint/rendicontazione/elettricita`, {
                    nome: watch('nome'),
                    fattore_emissione: watch('fattore_emissione'),
                    elettricita: watch('elettricita'),
                    business_unit: businessUnit,
                    anno: anno,
                    paese: paeseSelezionato,
                })
                    .then((res) => {
                        setErrore('')
                        setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendElettricita.form.successoCreazione'))
                        ui.triggerAggiornamentoGenerico()
                    })
                    .catch((err) => {
                        setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                        setSuccesso('')
                    })
                    .finally(() => setAttesa(false))
        }

        const [listaFattoriEmissione, setListaFattoriEmissione] = useState<[]>([])
        const [gettingListaFattoriEmissione, setGettingListaFattoriEmissione] = useState<boolean>(false)

        useEffect(() => {
            setGettingListaFattoriEmissione(true)
            get('carbon_footprint/elettricita_emissioni/lista_senza_fattori').then((res) => {

                setListaFattoriEmissione(res.data.data.filter((fattore: IFattoreEmissioneElettrico) => (
                    fattore.paese === paeseSelezionato && fattore.anno.toString() === anno.toString()
                )))


                setGettingListaFattoriEmissione(false)

            })
        }, [paeseSelezionato])

        //Ristruttura dati per label MultiSelect
        listaFattoriEmissione.map((fattore: any) => {
            fattore.value = fattore.nome
            fattore.label = fattore.nome
        })

        return (
            <div>
                <div>
                    {emissioneElettrica ?
                        <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendElettricita.form.titoloModifica')} {businessUnit}</h2>
                        :
                        <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendElettricita.form.titoloCreazione')} {businessUnit}</h2>
                    }
                </div>

                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    {
                        gettingListaFattoriEmissione &&
                        <div className="flex items-center justify-center w-full my-2">
                            <Spinner colore='blue' />
                        </div>
                    }

                    {
                        !gettingListaFattoriEmissione &&
                        <>
                            {
                                listaFattoriEmissione.length > 0 || emissioneElettrica ?
                                    <>
                                        <div className="form">
                                            <div className="font-bold my-3">
                                                {`${t('ModuloCarbonFootprint.rendElettricita.questoValoreUtilizzeraFattore')} "${paeseSelezionato}" ${t('ModuloCarbonFootprint.rendElettricita.perAnno')} ${anno}`}
                                            </div>

                                            <InputField
                                                label={t('ModuloCarbonFootprint.common.nomeImpianto')}
                                                type="text"
                                                error={errors.nome}
                                                form={register("nome")}
                                                placeholder={t('common.nome')}
                                            />

                                            <label>{t('ModuloCarbonFootprint.common.electricitySupplier')}</label>
                                            <Controller
                                                name="fattore_emissione"
                                                control={control}
                                                render={({ field: { onChange } }) => (
                                                    <Select
                                                        options={listaFattoriEmissione}
                                                        onChange={(e: any) => onChange(e.value)}
                                                        defaultValue={emissioneElettrica?.elettricita && {
                                                            label: emissioneElettrica.fattore_emissione,
                                                            value: emissioneElettrica.fattore_emissione,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <InputField
                                            label={`${t('ModuloCarbonFootprint.common.elettricita')} (in kWh)`}
                                            type="number"
                                            error={errors.elettricita}
                                            form={register("elettricita")}
                                            placeholder="550.000"
                                        />

                                        {
                                            !attesa ?
                                                <input type="submit" value={emissioneElettrica ? t('common.salvaModifica') : t('common.crea')} />
                                                : <SpinnerButton />
                                        }

                                        {(errore || successo) &&
                                            <div className="mt-2">
                                                {errore && <ErrorBox errore={errore} />}
                                                {successo && <InfoBox messaggio={successo} />}
                                            </div>
                                        }
                                    </>
                                    :
                                    <div>
                                        {`${t('ModuloCarbonFootprint.rendElettricita.nonEsistonFattoriDiEmissionePaese')} "${paeseSelezionato}" ${t('ModuloCarbonFootprint.rendElettricita.perAnno')} ${anno}`}
                                    </div>
                            }
                        </>
                    }

                </form>
            </div>
        )
    }

export default RendicontazioneElettricitaForm;