import React from 'react'

//Data
import './stile/tabellaInterattiva.css'
import { observer } from 'mobx-react-lite';
import { useTabellaInterattivaContext } from './context/useTabellaInterattivaContext';

//Components
import RigaHeadTabella from './RigaHeadTabella';
import RigaTabella from './RigaTabella';
import { useTranslation } from 'react-i18next';

const CorpoTabella = observer(() => {

  const { tabella } = useTabellaInterattivaContext()
  const { t } = useTranslation()
  return (
    <div className="overflow-x-scroll mt-1">
      <table className="tabellaInterattiva min-w-full max-w-full">

        {tabella.dati && tabella.dati.length > 0 &&
          <>
            <thead className="bg-accent dark:bg-dark-accent text-white">
              <RigaHeadTabella
                riga={tabella.dati[0]}
                colonne={tabella.colonne}
              />
            </thead>
            <tbody>
              {
                tabella.datiVisibili.map((riga: any) => (
                  <RigaTabella
                    dati={riga}
                    key={riga[0]} />
                ))
              }
            </tbody>
          </>
        }

      </table>

      {tabella.dati && tabella.dati.length === 0 &&
        <div className="opacity-70 text-lg text-center my-2">
          {t('tabellaInterattiva.nessunDatoPresente')}
        </div>
      }
    </div>
  )
})

export default CorpoTabella;
