import React from 'react'
import ReactEcharts from "echarts-for-react"

const GraficoBusinessUnit: React.FC<{ emissioniBusinessUnit: any[] }> = ({ emissioniBusinessUnit }) => {

    let listaAnni = emissioniBusinessUnit.map(e => e.anno)
    let listaEmissioniCombustibile = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_combustibile)
    let listaEmissioniVeicoli = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_veicoli)
    let listaEmissioniGas = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_gas_refrigerante)
    let listaEmissioniElettricita = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_elettricita)

    let option = {
        toolbox: {
            show: true,
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        legend: {
            data: ['Combustibile', 'Veicoli', 'Gas refrigeranti', 'Elettricità']
        },
        xAxis: {
            type: 'category',
            data: listaAnni
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: 'Combustibile',
                data: listaEmissioniCombustibile,
                type: 'bar',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                stack: 'total'
            },
            {
                name: 'Veicoli',
                data: listaEmissioniVeicoli,
                type: 'bar',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                stack: 'total'
            },
            {
                name: 'Gas refrigeranti',
                data: listaEmissioniGas,
                type: 'bar',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                stack: 'total'
            },
            {
                name: 'Elettricità',
                data: listaEmissioniElettricita,
                type: 'bar',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                stack: 'total'
            },
        ]
    };

    return (
        <div>
            <ReactEcharts style={{ minHeight: 500 }} option={option} />
        </div>
    )
}


export default GraficoBusinessUnit;