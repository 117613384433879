import React from 'react'
import { useStores } from '../../../../../hooks/useStores'
import { observer } from 'mobx-react'
import { FaInfoCircle } from 'react-icons/fa'

const GraficoVerificaDeltaVeicoli: React.FC<{ emissioniFlat: any[], anno: number }> = observer(({ emissioniFlat, anno }) => {

    const { ui } = useStores()

    let emissioniDeltaOltre10 = emissioniFlat.filter((item) => (
        item.anno === anno &&
        parseFloat(item.delta) > 10
    ))

    return (
        <>
            {
                emissioniDeltaOltre10.length > 0 &&
                <div className="p-4">

                    <div className="flex items-center gap-1">
                        <div
                            onMouseEnter={() => ui.mountFollowMouse(<p>Delta tra le emissioni calcolate sulla base del chilometraggio e sulle emissioni calcolate sulla base del carburante consumato.</p>)}
                            onMouseLeave={() => ui.unmountFollowMouse()}
                        >
                            <FaInfoCircle />
                        </div>
                        <p className="font-bold">Emissioni con delta oltre il 10%:</p>
                    </div>

                    <div className='flex flex-col gap-2 my-2'>
                        {emissioniDeltaOltre10.map((item) => (
                            <div className="border border-red p-2 text-red">
                                L'emissione di {item.numero_veicoli} veicoli ( {item.dimensione} | {item.tipologia} ) inserita nella business unit <b>{item.business_unit}</b> ha un'inconsistenza del <b>{item.delta}</b>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </>
    )
})

export default GraficoVerificaDeltaVeicoli;
