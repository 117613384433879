import React from 'react'

//Data
import { IRigaTabellaInterattiva } from './types';
import { useTabellaInterattivaContext } from './context/useTabellaInterattivaContext';
import { observer } from 'mobx-react-lite';

//Components
import CallToActionRiga from './CallToActionRiga';
import CellaTabella from './CellaTabella';

const RigaTabella: React.FC<IRigaTabellaInterattiva> = observer(({ dati }) => {

  const { tabella } = useTabellaInterattivaContext()

  return (
    <tr className="rigaTabellaInterattiva">
      {Object.values(dati).map((valore, i) => (
        <CellaTabella
          dati={dati}
          colonna={Object.keys(dati)[i]}
          key={Object.values(dati)[0] as string + valore + i}
          valoreDefault={valore as string} />
      ))}

      {tabella.callToActionsRiga && tabella.callToActionsRiga.length > 0 &&
        <td className="cellaAzioniRiga">
          {
            tabella.callToActionsRiga?.map((cta) => (
              <CallToActionRiga
                icona={cta.icona}
                testo={cta.testo}
                id={cta.id}
                azione={cta.azione}
                dati={dati}
                coloreSfondo={cta.coloreSfondo}
                coloreTesto={cta.coloreTesto}
              />
            ))
          }
        </td>
      }

    </tr>
  )
})

export default RigaTabella;