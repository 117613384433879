import React, { useState } from 'react'
import Carburanti from '../configurazioneCarburanti/components/Carburanti'
import CarburantiFattoriEmissione from '../configurazioneCarburanti/components/CarburantiFattoriEmissione'


const ConfigurazioneCarburanti = () => {
    const [vistaAttiva, setVistaAttiva] = useState<'carburanti' | 'carburanti_emissioni'>('carburanti')

    const styles = {
        bottone: 'bg-lightgray p-2 cursor-pointer',
        bottoneAttivo: 'bg-white border-t-2 border-accent'
    }
    return (
        <div>
            <div className="flex items-center justify-center rounded-md">
                <div
                    className={`${styles.bottone} ${vistaAttiva === 'carburanti' && styles.bottoneAttivo}`}
                    onClick={() => setVistaAttiva('carburanti')}>
                    Carburanti
                </div>

                <div
                    className={`${styles.bottone} ${vistaAttiva === 'carburanti_emissioni' && styles.bottoneAttivo}`}
                    onClick={() => setVistaAttiva('carburanti_emissioni')}>
                    Fattori di Emissione dei Carburanti
                </div>
            </div>

            {vistaAttiva === 'carburanti_emissioni' && <CarburantiFattoriEmissione />}
            {vistaAttiva === 'carburanti' && <Carburanti />}


        </div>
    )
}

export default ConfigurazioneCarburanti;
