import React, { useState } from 'react'

//Data
import { useForm } from 'react-hook-form';
import { useStores } from '../../../../../../hooks/useStores';
import { crea, modifica } from '../../../../../../rest/crud_generica';
import { ITipologiaVeicolo } from '../../../../types';

//Components
import ErrorBox from '../../../../../../components/common/ErrorBox';
import InputField from '../../../../../../components/common/form/InputField';
import InfoBox from '../../../../../../components/common/InfoBox';
import SpinnerButton from '../../../../../../components/common/SpinnerButton';

const TipologieVeicoliForm: React.FC<{tipologiaVeicolo?: ITipologiaVeicolo}> = ({tipologiaVeicolo}) => {
  const { ui } = useStores()
  const [attesa, setAttesa] = useState<boolean>(false)
  const [successo, setSuccesso] = useState<string>('')
  const [errore, setErrore] = useState<string>('')

  const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      nome: tipologiaVeicolo ? tipologiaVeicolo.nome : '',
      descrizione: tipologiaVeicolo ? tipologiaVeicolo.descrizione : '',
  
    }
  });

  const [listaUnitaMisura, setListaUnitaMisura] = useState<any[]>([])

 
  //Ristruttura dati per label MultiSelect
  listaUnitaMisura.filter((um) => !um.label).map((unitaMisura: any) => {
    unitaMisura.value = unitaMisura.codice
    unitaMisura.label = unitaMisura.codice
  })

  const onSubmit = () => {
    setAttesa(true)
    tipologiaVeicolo
      ?
      modifica(`carbon_footprint/veicoli_tipologie/${tipologiaVeicolo.nome}`, {
        nome: watch('nome'),
        descrizione: watch('descrizione'),
      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Tipologia modificato con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
      :
      crea(`carbon_footprint/veicoli_tipologie`, {
        nome: watch('nome'),
        descrizione: watch('descrizione'),
      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Tipologia creato con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
  }

  return (
    <div>
      <div>
        {tipologiaVeicolo ?
          <h2 className="text-2xl mb-2 capitalize">Modifica il Tipologia {tipologiaVeicolo.nome}</h2>
          :
          <h2 className="text-2xl mb-2 capitalize">Crea un nuovo Tipologia</h2>
        }
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>

        <InputField
          label="Nome"
          type="text"
          error={errors.nome}
          form={register("nome", { required: "Il nome è richiesto" })}
          placeholder="Nome"
        />

        <InputField
          label="Descrizione"
          type="textarea"
          error={errors.descrizione}
          form={register("descrizione")}
          placeholder="Descrizione"
        />

        {
          !attesa ?
            <input type="submit" value={tipologiaVeicolo ? 'Salva modifiche' : 'Crea tipologia Veicolo'} />
            : <SpinnerButton />
        }

        {(errore || successo) &&
          <div className="mt-2">
            {errore && <ErrorBox errore={errore} />}
            {successo && <InfoBox messaggio={successo} />}
          </div>
        }

      </form>
    </div>
  )
}

export default TipologieVeicoliForm;