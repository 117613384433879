import React from 'react'
import { ICallToActionTabella } from './types';

const CallToActionTabella: React.FC<ICallToActionTabella> = ({
    testo,
    id,
    azione,
    icona
}) => {
        
    return (
        <div
            id={id}
            className="p-1 px-2 cursor-pointer hover:opacity-80 bg-accent dark:bg-dark-accent text-white rounded-sm flex items-center gap-1"
            onClick={() => azione && azione()}
        >
            {icona && <span className="w-4 h-4 flex items-center justify-center">{icona}</span> }
            <span>{testo}</span>
        </div>
    )
}

export default CallToActionTabella;
