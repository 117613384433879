import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useTabellaInterattivaContext } from './context/useTabellaInterattivaContext';
import { ICallToActionTabella } from './types';
import CallToActionTabella from './CallToActionTabella';

const InformazioniTabella = () => {
  
  const { t } = useTranslation()
  const { tabella } = useTabellaInterattivaContext()
  const [visualizzaParagrafo, setVisualizzaParagrafo] = useState<boolean>(false)

  return (
    <div className="flex items-start justify-between gap-5">

      <div className="flex flex-col items-start gap-2">
        
        <div>
          {tabella.titolo &&
            <h2 className="font-bold text-xl">{tabella.titolo}</h2>
          }

          {tabella.sottoTitolo &&
            <h3 className="text-lg text-accent dark:text-dark-accent">{tabella.sottoTitolo}</h3>
          }
        </div>

        {tabella.paragrafo &&
          <p>
            {
              tabella.paragrafo.length > 100 ?
                visualizzaParagrafo ?
                  tabella.paragrafo
                  :
                  tabella.paragrafo.substring(0, 100) + '...'
                :
                tabella.paragrafo
            }

            {
              tabella.paragrafo.length > 100 &&
              <span className="ml-1 underline text-accent dark:text-dark-accent cursor-pointer" onClick={() => setVisualizzaParagrafo(!visualizzaParagrafo)}>
                  {visualizzaParagrafo ? t('tabellaInterattiva.mostraMeno') : t('tabellaInterattiva.mostraPiu')}
              </span>
            }
          </p>
        }
      </div>

      {tabella.callToActions && tabella.callToActions.length > 0 &&
        <div className="flex items-center gap-1">
          {tabella.callToActions.map((cta: ICallToActionTabella) => (
            <CallToActionTabella
              testo={cta.testo}
              azione={cta.azione}
              icona={cta.icona}
            />
          ))}
        </div>
      }

    </div>
  )
}

export default InformazioniTabella;