import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../../../../../hooks/useStores'
import { ICostanteVeicolo } from '../../../../types'
import { elimina, get } from '../../../../../../rest/crud_generica'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'

//Components
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'
import Dialogue from '../../../../../../components/common/Dialogue'
import { CgAdd } from 'react-icons/cg'
import { FaPen, FaTrash } from 'react-icons/fa'
import CostantiVeicoliForm from './CostantiVeicoliForm'
import { ICallToActionTabella } from '../../../../../../components/tabellaInterattiva/types'
import { useTabellaInterattivaContext } from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'

const CostantiVeicoli = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()

    const { ui } = useStores()

    const [costantiVeicoli, setDimensioniVeicoli] = useState<ICostanteVeicolo[]>([])

    useEffect(() => {
        listaCostantiVeicoli()
    }, [ui.aggiornamentoGenerico])

    //Ottieni lista gas
    const listaCostantiVeicoli = () => get('carbon_footprint/veicoli_costanti').then((res) => {
        setDimensioniVeicoli(res.data.data)
    })

    //Crea gas
    const creaCostantiVeicolo = () => ui.mountAsideModal(<CostantiVeicoliForm />)

    //Modifica gas
    const modificaCostantiVeicolo = (costanteVeicolo: ICostanteVeicolo) => ui.mountAsideModal(<CostantiVeicoliForm costanteVeicolo={costanteVeicolo} />)

    //Elimina gas
    const eliminaCostantiVeicolo = (costanteVeicolo: ICostanteVeicolo) => elimina(`carbon_footprint/veicoli_costanti/${costanteVeicolo.anno}/${costanteVeicolo.veicoli_tipologia}/${costanteVeicolo.veicoli_dimensioni}`)
        .then(() => {
            ui.mountDialogue(<Dialogue titolo="costante eliminata con successo" />)
            listaCostantiVeicoli()
        }
        )
        .catch(() => {
            ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione della dimensione" />)
        })

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const { tabella } = useTabellaInterattivaContext()
    useEffect(() => { }, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    testo: 'Crea costante',
                    azione: creaCostantiVeicolo,
                    icona: <CgAdd size={18} />
                }])

            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaCostantiVeicolo,
                    testo: 'Modifica',
                    icona: <FaPen size={12} />,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaCostantiVeicolo,
                    testo: 'Elimina',
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={costantiVeicoli}
                titolo="Costanti dei veicoli"
                paragrafo="Componi le costanti basate su dimensioni e tipologie dei veicoli."
                endpoint="carbon_footprint/veicoli_costanti"
                pk={['veicoli_tipologia', 'veicoli_dimensioni']}
                callToActions={ctaTabella}
                colonne={
                    [
                        { id: 'anno', label: 'Anno' },
                        { id: 'veicoli_dimensioni', label: 'Dimensioni' },
                        { id: 'veicoli_tipologia', label: 'Tipo' },
                        { id: 'unita_misura', label: 'Unità di misura' },
                        { id: 'kg_co2e', label: 'Kg co2e' },
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default CostantiVeicoli;