import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../../../../../hooks/useStores'
import { ITipologiaVeicolo } from '../../../../types'
import { elimina, get } from '../../../../../../rest/crud_generica'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'

//Components
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'
import Dialogue from '../../../../../../components/common/Dialogue'
import { CgAdd } from 'react-icons/cg'
import { FaPen, FaTrash } from 'react-icons/fa'
import TipologieVeicoliForm from './TipologieVeicoliForm'
import { ICallToActionTabella } from '../../../../../../components/tabellaInterattiva/types'
import { useTabellaInterattivaContext } from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'

const TipologieVeicoli = observer(() => {

  const privilegio = useOttieniPrivilegioModulo()
  const { ui } = useStores()

  const [tipologieVeicoli, setTipologieVeicoli] = useState<ITipologiaVeicolo[]>([])

  useEffect(() => {
    listaTipologieVeicoli()
  }, [ui.aggiornamentoGenerico])

  //Ottieni lista gas
  const listaTipologieVeicoli = () => get('carbon_footprint/veicoli_tipologie').then((res) => {
    setTipologieVeicoli(res.data.data)
  })

  //Crea gas
  const creaTipologiaVeicolo = () => ui.mountAsideModal(<TipologieVeicoliForm />)

  //Modifica gas
  const modificaTipologiaVeicolo = (tipologiaVeicolo: ITipologiaVeicolo) => ui.mountAsideModal(<TipologieVeicoliForm tipologiaVeicolo={tipologiaVeicolo} />)

  //Elimina gas
  const eliminaTipologiaVeicolo = (tipologiaVeicolo: ITipologiaVeicolo) => elimina(`carbon_footprint/veicoli_tipologie/${tipologiaVeicolo.nome}`)
    .then(() => {
      ui.mountDialogue(<Dialogue titolo="Tipologia eliminata con successo" />)
      listaTipologieVeicoli()
    }
    )
    .catch(() => {
      ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione della tipologia" />)
    })

  const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
  const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

  const { tabella } = useTabellaInterattivaContext()
  useEffect(() => { }, [tabella.callToActions, tabella.callToActionsRiga])

  //Setta CTA basate su ruoli
  useEffect(() => {

    if (privilegio) {
      setCtaRiga([])
      setCtaTabella([])

      if (privilegio >= 2) {
        setCtaTabella(cta => [...cta, {
          testo: 'Crea tipologia',
          azione: creaTipologiaVeicolo,
          icona: <CgAdd size={18} />
        }])

      }

      if (privilegio >= 3) {
        setCtaRiga(cta => [...cta, {
          azione: modificaTipologiaVeicolo,
          testo: 'Modifica',
          icona: <FaPen size={12} />,
        }])
      }

      if (privilegio >= 4) {
        setCtaRiga(cta => [...cta, {
          azione: eliminaTipologiaVeicolo,
          testo: 'Elimina',
          icona: <FaTrash size={12} />,
          coloreSfondo: "bg-red dark:bg-dark-red"
        }])
      }
    }

  }, [privilegio])

  return (
    <div>
      <TabellaInterattiva
        dati={tipologieVeicoli}
        titolo="Tipologie dei veicoli"
        paragrafo="Inserisci le tipologie dei veicoli."
        endpoint="carbon_footprint/veicoli_tipologie"
        pk={['nome']}
        callToActions={ctaTabella}
        colonne={
          [
            { id: 'nome', label: 'Nome' },
            { id: 'descrizione', label: 'Descrizione' },
            { id: 'unita_misura', label: 'Unità di misura' },
            { id: 'kg_co2e', label: 'Kg co2e' },
          ]
        }
        callToActionsRiga={ctaRiga}
      />
    </div>
  )
})

export default TipologieVeicoli;