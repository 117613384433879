import React, { useEffect, useState } from 'react'
import { IModulo } from '../../../types';
import ModuloTile from '../../gestioneModuli/ModuloTile';
import { getAllModuli } from '../../gestioneModuli/rest/gestioneModuli';

const ConfigurazioneCarbonFootprint = () => {

    const [moduli, setModuli] = useState<IModulo[]>([])
    const [moduliDisattivi, setModuliDisattivi] = useState<IModulo[]>([])

    useEffect(() => {
        getAllModuli().then((res) => setModuli(res.data.data.filter((modulo: IModulo) => modulo.codice.includes('configurazione_scope') && modulo.attivo)))
        getAllModuli().then((res) => setModuliDisattivi(res.data.data.filter((modulo: IModulo) => modulo.codice.includes('configurazione_scope') && !modulo.attivo)))
    }, [])

    return (
        <div>
            {moduli && moduli.map((modulo) => (
                <ModuloTile modulo={modulo} modifica={false} />
            ))}

            {moduliDisattivi.length > 0 &&
                <div className="pointer-events-none opacity-60">
                    {moduliDisattivi.map((modulo) => (
                        <ModuloTile modulo={modulo} modifica={false} />
                    ))}
                </div>
            }
        </div>
    )
}

export default ConfigurazioneCarbonFootprint;
