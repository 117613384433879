import React from 'react'

const MenuGenerico: React.FC<{ bottoni: { label: string, action: () => void, attivo?: boolean, classi?: string }[] }> = ({ bottoni }) => {

    const styles = {
        bottone: 'duration-200 whitespace-nowrap bg-white dark:bg-darkgray-d p-2 cursor-pointer hover:bg-accent dark:hover:bg-dark-accent hover:text-white opacity-70',
        bottoneAttivo: 'pointer-events-none opacity-100 font-bold border-l-2 md:border-l-0 md:border-t-2 border-accent dark:border-dark-accent'
    }

    return (
        <div className="mb-2 md:mb-0 md:gap-[4px] flex items-stretch md:items-center justify-center w-full flex-col md:flex-row">

            {
                bottoni.map((bottone) => (
                    <div
                        className={`${styles.bottone} ${bottone.attivo && styles.bottoneAttivo} ${bottone.classi && bottone.classi}`}
                        onClick={() => bottone.action()}
                    >
                        {bottone.label}
                    </div>
                ))
            }

        </div>
    )
}

export default MenuGenerico;