import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../hooks/useStores'
import { useModuloESGStores } from '../context/useModuloESGStores'
import { IBilancio } from '../types'
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import AggiungiButton from '../../../components/common/AggiungiButton'
import BilanciForm from '../components/bilanci/BilanciForm'
import BilancioTile from '../components/bilanci/BilancioTile'
import useOttieniPrivilegioModulo from '../../../hooks/useOttieniPrivilegioModulo'
import ReactPaginate from 'react-paginate'
import BarraRicercaGenerale from '../../../components/common/BarraRicercaGenerale'

const ArchivioBilanci = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()
    const { t } = useTranslation();
    const { bilanci } = useModuloESGStores()
    const { ui } = useStores();
    const testoTile = `${t('ModuloFattoriEsg.SchedaBilancio.bilancio')}`

    const [stringaDiRicercaBilanci, setStringaDiRicercaBilanci] = useState<string>('');

    const dataBilanci = bilanci.bilanci.filter((bilancio) => bilancio.descrizione.toString().toLowerCase().includes(stringaDiRicercaBilanci.toLowerCase()));
    const [numeroBilanci, setNumeroBilanci] = useState(0);
    const bilanciPerPagina = 4;
    const paginaBilanciVisitate = numeroBilanci * bilanciPerPagina;
    const conteggioPaginaBilanci = Math.ceil(dataBilanci.length / bilanciPerPagina)
    const cambioPaginaBilanci = ({ selected }: { selected: number }): void => {
        setNumeroBilanci(selected)
    };

    useEffect(() => {
        setNumeroBilanci(0);
    }, [stringaDiRicercaBilanci])
    useEffect(() => {
        setNumeroBilanci(0);
    }, [stringaDiRicercaBilanci])
    useEffect(() => {

        bilanci.getBilanci()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        bilanci.bilancioCreato,
        bilanci.bilancioEliminato,
        bilanci.bilancioModificato,
    ])

    return (
        <div>
            <div className='flex justify-end'>
                <BarraRicercaGenerale
                    setStringaDiRicerca={setStringaDiRicercaBilanci}
                    numeroRisultati={dataBilanci.length}
                    mostraRisultati={true} />
            </div>
            <div className="mt-2 mb-4">
                {
                    dataBilanci.length > 0 ?
                        <div className="grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-5">
                            {dataBilanci
                                .slice(paginaBilanciVisitate, paginaBilanciVisitate + bilanciPerPagina)
                                .map((bilancio: IBilancio) => (
                                    <BilancioTile testo={testoTile} bilancio={bilancio} key={bilancio.anno} />
                                )
                                )}
                        </div>
                        :
                        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
                            <FaInfoCircle /> {t('ModuloDashboard.Archivio.nessunBilancioPresente')}
                        </h3>
                }
            </div>
            {dataBilanci.length > 4 &&
            <div className='mb-4'>
                <ReactPaginate
                    pageCount={conteggioPaginaBilanci}
                    forcePage={numeroBilanci}
                    nextLabel={'Next'}
                    onPageChange={cambioPaginaBilanci}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"} />
            </div>
            }
            {
                privilegio >= 2 &&
                <div className="w-full mt-2" onClick={() => ui.mountAsideModal(<BilanciForm />)} >
                    <AggiungiButton testo={t('common.aggiungiNuovoBilancio')} />
                </div>
            }
        </div >
    )
})

export default ArchivioBilanci;