
import React, { useEffect, useState } from 'react'

//Data
import { Controller, useForm } from 'react-hook-form'
import { useStores } from '../../../../../../hooks/useStores'
import { crea, get, modifica } from '../../../../../../rest/crud_generica'
import { getUnitaMisuraLista } from '../../../../../gestioneUnitaMisura/rest/gestioneUnitaMisura'
import { ICostanteVeicolo } from '../../../../types'

//Components
import ErrorBox from '../../../../../../components/common/ErrorBox'
import InputField from '../../../../../../components/common/form/InputField'
import InfoBox from '../../../../../../components/common/InfoBox'
import SpinnerButton from '../../../../../../components/common/SpinnerButton'
import Select from 'react-select'

const CostantiVeicoliForm: React.FC<{ costanteVeicolo?: ICostanteVeicolo }> = ({ costanteVeicolo }) => {

  const { ui } = useStores()
  const [attesa, setAttesa] = useState<boolean>(false)
  const [successo, setSuccesso] = useState<string>('')
  const [errore, setErrore] = useState<string>('')

  const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      anno: costanteVeicolo ? costanteVeicolo.anno : '',
      veicoli_dimensioni: costanteVeicolo ? costanteVeicolo.veicoli_dimensioni : '',
      veicoli_tipologia: costanteVeicolo ? costanteVeicolo.veicoli_tipologia : '',
      unita_misura: costanteVeicolo ? costanteVeicolo.unita_misura : '',
      kg_co2e: costanteVeicolo ? costanteVeicolo.kg_co2e : '',
    }
  });

  const [listaDimensioniVeicoli, setListaDimensioni,] = useState<any[]>([])

  //Setta le unità di misura

  useEffect(() => {
    listaVeicoliDimensioni()
  }, [])

  //Ottieni lista gas
  const listaVeicoliDimensioni = () => get('carbon_footprint/veicoli_dimensioni').then((res) => {
    setListaDimensioni(res.data.data)
  })

  //Ristruttura dati per label MultiSelect
  listaDimensioniVeicoli.filter((um) => !um.label).map((listaVeicoliDimensioni: any) => {
    listaVeicoliDimensioni.value = listaVeicoliDimensioni.nome
    listaVeicoliDimensioni.label = listaVeicoliDimensioni.nome
  })

  const [listaTipologiaVeicoli, setListaTipologia,] = useState<any[]>([])

  useEffect(() => {
    listaTipoVeicoli()
  }, [])

  //Ottieni lista gas
  const listaTipoVeicoli = () => get('carbon_footprint/veicoli_tipologie').then((res) => {
    setListaTipologia(res.data.data)
  })

  //Ristruttura dati per label MultiSelect
  listaTipologiaVeicoli.filter((um) => !um.label).map((listaTipoVeicoli: any) => {
    listaTipoVeicoli.value = listaTipoVeicoli.nome
    listaTipoVeicoli.label = listaTipoVeicoli.nome
  })
  const [listaUnitaMisura, setListaUnitaMisura] = useState<any[]>([])

  //Setta le unità di misura
  useEffect(() => {
    getUnitaMisuraLista().then((res) => {
      setListaUnitaMisura([...res.data.data, {
        label: 'Nessuna',
        value: null
      }])
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //Ristruttura dati per label MultiSelect
  listaUnitaMisura.filter((um) => !um.label).map((unitaMisura: any) => {
    unitaMisura.value = unitaMisura.codice
    unitaMisura.label = unitaMisura.codice
  })

  const onSubmit = () => {
    setAttesa(true)
    costanteVeicolo
      ?
      modifica(`carbon_footprint/veicoli_costanti/${costanteVeicolo.anno}/${costanteVeicolo.veicoli_tipologia}/${costanteVeicolo.veicoli_dimensioni}`, {
        anno: watch('anno'),
        veicoli_dimensioni: watch('veicoli_dimensioni'),
        veicoli_tipologia: watch('veicoli_tipologia'),
        unita_misura: watch('unita_misura'),
        kg_co2e: watch('kg_co2e'),

      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Costante modificata con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
      :
      crea(`carbon_footprint/veicoli_costanti`, {
        anno: watch('anno'),
        veicoli_dimensioni: watch('veicoli_dimensioni'),
        veicoli_tipologia: watch('veicoli_tipologia'),
        unita_misura: watch('unita_misura'),
        kg_co2e: watch('kg_co2e'),

      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Costante creata con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
  }

  const [listaAnni, setListaAnni] = useState<any[]>([])

  //Ottieni lista gas
  const listaAnno = () => get('carbon_footprint/anni').then((res) => {
    setListaAnni(res.data.data)
  })

  useEffect(() => {
    listaAnno()
  }, [])

  //Ristruttura dati per label MultiSelect
  listaAnni.filter((um) => !um.label).map((listaAnno: any) => {
    listaAnno.value = listaAnno.anno
    listaAnno.label = listaAnno.anno
  })

  return (
    <div>
      <div>
        {costanteVeicolo ?
          <h2 className="text-2xl mb-2 capitalize">Modifica la costante {costanteVeicolo.veicoli_dimensioni}, {costanteVeicolo.veicoli_tipologia}</h2>
          :
          <h2 className="text-2xl mb-2 capitalize">Crea una nuova costante</h2>
        }
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>


        <div className="form w-full">
          <label htmlFor="">Anno</label>
          <Controller
            name="anno"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaAnni}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={costanteVeicolo?.anno && {
                  value: costanteVeicolo.anno,
                  label: costanteVeicolo.anno,
                }}
              />
            )}
          />
        </div>

        <div className="form w-full">
          <label htmlFor="">Dimensioni veicolo</label>
          <Controller
            name="veicoli_dimensioni"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaDimensioniVeicoli}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={costanteVeicolo?.veicoli_dimensioni && {
                  value: costanteVeicolo.veicoli_dimensioni,
                  label: costanteVeicolo.veicoli_dimensioni,
                }}
              />
            )}
          />
        </div>

        <div className="form w-full">
          <label htmlFor="">Tipologia veicolo</label>
          <Controller
            name="veicoli_tipologia"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaTipologiaVeicoli}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={costanteVeicolo?.veicoli_tipologia && {
                  value: costanteVeicolo.veicoli_tipologia,
                  label: costanteVeicolo.veicoli_tipologia,
                }}
              />
            )}
          />
        </div>

        <div className="form w-full">
          <label htmlFor="">Unità di misura</label>
          <p className="opacity-70 mb-2">Puoi inserire nuove unità di misura dal modulo "Gestione unità di misura"</p>
          <Controller
            name="unita_misura"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaUnitaMisura}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={costanteVeicolo?.unita_misura && {
                  value: costanteVeicolo.unita_misura,
                  label: costanteVeicolo.unita_misura,
                }}
              />
            )}
          />
        </div>

        <InputField
          label="Kg co2e"
          type="number"
          error={errors.kg_co2e}
          form={register("kg_co2e", { required: "Il fattore di emissione è richiesto" })}
          placeholder="CO2 Equivalente"
        />

        {
          !attesa ?
            <input type="submit" value={costanteVeicolo ? 'Salva modifiche' : 'Crea costante veicolo'} />
            : <SpinnerButton />
        }

        {(errore || successo) &&
          <div className="mt-2">
            {errore && <ErrorBox errore={errore} />}
            {successo && <InfoBox messaggio={successo} />}
          </div>
        }

      </form>
    </div>
  )
}

export default CostantiVeicoliForm;