import React, { useEffect, useState } from 'react'

//Data
import { Controller, useForm } from 'react-hook-form'
import { crea, get, modifica } from '../../../../../rest/crud_generica';
import { useStores } from '../../../../../hooks/useStores';
import { IEmissioneGasRefrigerante } from '../../../types';
import { useTranslation } from 'react-i18next';

//Components
import InputField from '../../../../../components/common/form/InputField';
import SpinnerButton from '../../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../components/common/InfoBox';
import Select from 'react-select'


const RendicontazioneGasRefrigerantiForm: React.FC<{
    emissioneGas?: IEmissioneGasRefrigerante,
    businessUnit?: string,
    anno: number
}> = ({
    emissioneGas,
    businessUnit,
    anno,
}) => {

        const { t } = useTranslation()
        const { ui } = useStores()
        const [attesa, setAttesa] = useState<boolean>(false)
        const [successo, setSuccesso] = useState<string>('')
        const [errore, setErrore] = useState<string>('')

        const { register, handleSubmit, watch, control, formState: { errors } } = useForm({
            defaultValues: {
                gas_refrigerante: emissioneGas ? emissioneGas.gas_refrigerante : '',
                nome: emissioneGas ? emissioneGas.nome : '',
                quantita_contenuta: emissioneGas ? emissioneGas.quantita_contenuta : '',
                quantita_ricaricata: emissioneGas ? emissioneGas.quantita_ricaricata : '',
                unita_misura: "Kg",
            }
        });

        const onSubmit = () => {
            setAttesa(true)
            emissioneGas
                ?
                modifica(`carbon_footprint/rendicontazione/gas_refrigeranti/${businessUnit}/${anno}/${emissioneGas.gas_refrigerante}/${emissioneGas.nome}`, {
                    gas_refrigerante: watch('gas_refrigerante'),
                    nome: watch('nome'),
                    quantita_contenuta: watch('quantita_contenuta'),
                    quantita_ricaricata: watch('quantita_ricaricata'),
                    unita_misura: watch('unita_misura'),
                    business_unit: businessUnit,
                    anno: anno,
                })
                    .then((res) => {
                        setErrore('')
                        setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendGas.form.successoModifica'))
                        ui.triggerAggiornamentoGenerico()
                    })
                    .catch((err) => {
                        setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                        setSuccesso('')
                    })
                    .finally(() => setAttesa(false))
                :
                crea(`carbon_footprint/rendicontazione/gas_refrigeranti`, {
                    gas_refrigerante: watch('gas_refrigerante'),
                    nome: watch('nome'),
                    quantita_contenuta: watch('quantita_contenuta'),
                    quantita_ricaricata: watch('quantita_ricaricata'),
                    unita_misura: watch('unita_misura'),
                    business_unit: businessUnit,
                    anno: anno,
                })
                    .then((res) => {
                        setErrore('')
                        setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendGas.form.successoCreazione'))
                        ui.triggerAggiornamentoGenerico()
                    })
                    .catch((err) => {
                        setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                        setSuccesso('')
                    })
                    .finally(() => setAttesa(false))
        }

        const [listaGasRefrigeranti, seListaGasRefrigeranti] = useState<[]>([])

        useEffect(() => {

            get('carbon_footprint/gas_refrigeranti/lista_senza_fattori').then((res) =>
                seListaGasRefrigeranti(res.data.data.filter((gas: any) => gas.anno === anno))
            )

        }, [])

        //Ristruttura dati per label MultiSelect
        listaGasRefrigeranti.map((gas: any) => {
            gas.value = gas.nome
            gas.label = gas.nome
        })

        return (
            <div>
                {anno}
                <div>
                    {emissioneGas ?
                        <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendGas.form.titoloModifica')} {businessUnit}</h2>
                        :
                        <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendGas.form.titoloCreazione')} {businessUnit}</h2>
                    }
                </div>

                <form className="form" onSubmit={handleSubmit(onSubmit)}>


                    <InputField
                        label={t('ModuloCarbonFootprint.common.nomeImpianto')}
                        type="text"
                        error={errors.nome}
                        form={register("nome")}
                        placeholder={t('common.nome')}
                    />

                    <div className="form">
                        <label>{t('ModuloCarbonFootprint.common.gasRefrigerante')}</label>
                        <Controller
                            name="gas_refrigerante"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={listaGasRefrigeranti}
                                    onChange={(e: any) => onChange(e.value)}
                                    defaultValue={emissioneGas?.gas_refrigerante && {
                                        label: emissioneGas.gas_refrigerante,
                                        value: emissioneGas.gas_refrigerante,
                                    }}
                                />
                            )}
                        />
                    </div>

                    <InputField
                        label={`${t('ModuloCarbonFootprint.common.quantitaContenutaGas')} (in Kg)`}
                        type="number"
                        error={errors.quantita_contenuta}
                        form={register("quantita_contenuta")}
                        placeholder="1900"
                    />

                    <InputField
                        label={`${t('ModuloCarbonFootprint.common.quantitaRicaricataGas')} (in Kg)`}
                        type="number"
                        error={errors.quantita_ricaricata}
                        form={register("quantita_ricaricata")}
                        placeholder="1900"
                    />

                    {
                        !attesa ?
                            <input type="submit" value={emissioneGas ? t('common.salvaModifica') : t('common.crea')} />
                            : <SpinnerButton />
                    }

                    {(errore || successo) &&
                        <div className="mt-2">
                            {errore && <ErrorBox errore={errore} />}
                            {successo && <InfoBox messaggio={successo} />}
                        </div>
                    }


                </form>
            </div>
        )
    }

export default RendicontazioneGasRefrigerantiForm;