
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import ErrorBox from '../../../../../../components/common/ErrorBox'
import InputField from '../../../../../../components/common/form/InputField'
import InfoBox from '../../../../../../components/common/InfoBox'
import SpinnerButton from '../../../../../../components/common/SpinnerButton'
import { useStores } from '../../../../../../hooks/useStores'
import { crea, get, modifica } from '../../../../../../rest/crud_generica'
import { ICarburanti } from '../../../../types'

const CarburantiForm: React.FC<{ carburante?: ICarburanti }> = ({ carburante }) => {
  const { ui } = useStores()
  const [attesa, setAttesa] = useState<boolean>(false)
  const [successo, setSuccesso] = useState<string>('')
  const [errore, setErrore] = useState<string>('')

  const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      nome: carburante ? carburante.nome : '',
      tipologia: carburante ? carburante.tipologia : '',
      descrizione: carburante ? carburante.descrizione : '',
    }
  });

  const [datiSelectTipoCarburanti, setListaTipologia,] = useState<any[]>([])

  useEffect(() => {
    listaTipoCarburanti()
  }, [])

  //Ottieni lista gas
  const listaTipoCarburanti = () => setListaTipologia([
    {
      nome: 'Gaseous fuels',
    },
    {
      nome: 'Liquid fuels',
    },
    {
      nome: 'Solid fuels',
    },
  ])

  //Ristruttura dati per label MultiSelect
  datiSelectTipoCarburanti.filter((dato) => !dato.label).map((listaTipoCarburanti: any) => {
    listaTipoCarburanti.value = listaTipoCarburanti.nome
    listaTipoCarburanti.label = listaTipoCarburanti.nome
  })

  const onSubmit = () => {
    setAttesa(true)
    carburante
      ?
      modifica(`carbon_footprint/carburanti/${carburante.nome}`, {
        nome: watch('nome'),
        tipologia: watch('tipologia'),
        descrizione: watch('descrizione'),

      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Carburante modificato con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
      :
      crea(`carbon_footprint/carburanti`, {
        nome: watch('nome'),
        tipologia: watch('tipologia'),
        descrizione: watch('descrizione'),

      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Carburante creato con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
  }

  return (
    <div>
      <div>
        {carburante ?
          <h2 className="text-2xl mb-2 capitalize">Modifica il carburante {carburante.nome}</h2>
          :
          <h2 className="text-2xl mb-2 capitalize">Crea un nuovo carburante</h2>
        }
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label="Nome"
          type="text"
          error={errors.nome}
          form={register("nome", { required: "Il nome è richiesto" })}
          placeholder="Nome"
        />
        <InputField
          label="Descrizione"
          type="textarea"
          error={errors.descrizione}
          form={register("descrizione", { required: "La descrizione è richiesta" })}
          placeholder="Descrizione"
        />
        <div className="form w-full">
          <label htmlFor="">Tipologia di Carburante</label>
          <Controller
            name="tipologia"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={datiSelectTipoCarburanti}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={carburante?.tipologia && {
                  value: carburante.tipologia,
                  label: carburante.tipologia
                }}
              />
            )}
          />
        </div>

        {
          !attesa ?
            <input type="submit" value={carburante ? 'Salva modifiche' : 'Crea carburante'} />
            : <SpinnerButton />
        }

        {(errore || successo) &&
          <div className="mt-2">
            {errore && <ErrorBox errore={errore} />}
            {successo && <InfoBox messaggio={successo} />}
          </div>
        }

      </form>
    </div>
  )
}

export default CarburantiForm;