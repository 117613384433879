export const it_modulo_carbon_footprint = {
    common: {
        fattoreDiEmissione: 'Fattore di emissione',
        electricitySupplier: 'Fornitore di energia elettrica',
        elettricita: 'Elettricità',
        emissioneElettrica: 'Emissione elettrica',

        veicolo: 'Veicolo',
        gruppoVeicoli: 'Gruppo di veicoli',
        gruppiVeicoli: 'Gruppi di veicoli',
        veicoli: 'Veicoli',
        dimensione: 'Dimensione',
        tipologia: 'Tipologia',
        numeroVeicoli: 'Numero di veicoli',
        chilometraggioTotale: 'Chilometraggio totale',
        quantitaCarburante: 'Quantità di carburante',
        dimensioneVeicolo: 'Dimensione veicolo',
        dimensioniVeicoli: 'Dimensioni veicoli',
        tipologiaVeicoli: 'Tipologia veicolo',
        tipologieVeicoli: 'Tipologie veicoli',

        gasRefrigerante: 'Gas refrigerante',
        gasRefrigeranti: 'Gas refrigeranti',
        emissioneGasRefrigerante: 'Emissione di gas refrigerante',
        emissioniGasRefrigeranti: 'Emissioni di gas refrigerante',
        quantitaContenutaGas: 'Quantità contenuta',
        quantitaRicaricataGas: 'Quantità ricaricata',

        carburante: 'Carburante',
        carburanti: 'Carburanti',
        nomeImpianto: 'Nome sede / impianto',

        nonHaiAccesso: 'Non hai accesso a nessuna unità che possieda il privilegio di rendicontare in questa sezione',
    },

    rendCarburanti: {
        titolo: 'Emissioni di carburante per la business unit: ',
        aggiungi: 'Aggiungi emissione',

        successoEliminazione: 'Emissione di carburante eliminata con successo',
        erroreEliminazione: 'Errore durante l\'eliminazione dell\'emissione di carburante',

        successoValidazione: 'Emissione di carburante validata con successo',
        erroreValidazione: 'Errore durante la validazione dell\'emissione di carburante',

        successoRimValidazione: 'Validazione rimossa con successo',
        erroreRimValidazione: 'Errore durante la rimozione della validazione dall\'emissione di carburante',
        
        form: {
            titoloModifica: 'Modifica l\'emissione di carburante per la business unit:',
            titoloCreazione: 'Aggiungi un\'emissione di carburante per la business unit:',

            successoCreazione: 'Emissione di carburante inserita con successo',
            erroreCreazione: 'Errore durante l\'inserimento dell\'emissione di carburante',

            successoModifica: 'Emissione di carburante modificata con successo',
            erroreModifica: 'Errore durante la modifica dell\'emissione di carburante',
        }
    },

    rendGas: {
        titolo: 'Emissioni di gas refrigeranti per la business unit: ',
        aggiungi: 'Aggiungi emissione',

        successoEliminazione: 'Emissione di gas eliminata con successo',
        erroreEliminazione: 'Errore durante l\'eliminazione dell\'emissione di gas',

        successoValidazione: 'Emissione di gas validata con successo',
        erroreValidazione: 'Errore durante la validazione dell\'emissione di gas',

        successoRimValidazione: 'Validazione rimossa con successo',
        erroreRimValidazione: 'Errore durante la rimozione della validazione dall\'emissione di gas',
        
        form: {
            titoloModifica: 'Modifica l\'emissione di gas per la business unit:',
            titoloCreazione: 'Aggiungi un\'emissione di gas per la business unit:',

            successoCreazione: 'Emissione di gas inserita con successo',
            erroreCreazione: 'Errore durante l\'inserimento dell\'emissione di gas',

            successoModifica: 'Emissione di gas modificata con successo',
            erroreModifica: 'Errore durante la modifica dell\'emissione di gas',
        }
    },

    rendVeicoli: {
        titolo: 'Gruppi di veioli per la business unit: ',
        aggiungi: 'Aggiungi gruppo di veicoli',

        successoEliminazione: 'Gruppo di veicoli eliminato con successo',
        erroreEliminazione: 'Errore durante l\'eliminazione del gruppo di veicoli',

        successoValidazione: 'Emissione del gruppo di veicoli con successo',
        erroreValidazione: 'Errore durante la validazione del gruppo di veicoli',

        successoRimValidazione: 'Validazione rimossa con successo',
        erroreRimValidazione: 'Errore durante la rimozione della validazione dal gruppo di veicoli',
        
        form: {
            titoloModifica: 'Modifica il gruppo di veicoli per la business unit:',
            titoloCreazione: 'Aggiungi un gruppo di veicoli per la business unit:',

            successoCreazione: 'Gruppo di veicoli inserito con successo',
            erroreCreazione: 'Errore durante l\'inserimento gruppo di veicoli',

            successoModifica: 'Gruppo di veicoli modificato con successo',
            erroreModifica: 'Errore durante la modifica del gruppo di veicoli',
        }
    },

    rendElettricita: {
        titolo: 'Emissioni di elettricità per la business unit: ',
        aggiungi: 'Aggiungi emissione',

        successoEliminazione: 'Emissione elettrica eliminata con successo',
        erroreEliminazione: 'Errore durante l\'eliminazione dell\'emissione elettrica',

        nonEsistonFattoriDiEmissionePaese: 'Non esistono fattori di emissione per il paese',
        questoValoreUtilizzeraFattore: 'Questa emissione utilizzerà i fattori di emissione del paese',
        perAnno: 'per l\'anno',

        successoValidazione: 'Emissione di elettricità validata con successo',
        erroreValidazione: 'Errore durante la validazione dell\'emissione di elettricità',

        successoRimValidazione: 'Validazione rimossa con successo',
        erroreRimValidazione: 'Errore durante la rimozione della validazione dall\'emissione di elettricità',
        
        form: {
            titoloModifica: 'Modifica l\'emissione elettrica per la business unit:',
            titoloCreazione: 'Aggiungi un\'emissione elettrica per la business unit:',

            successoCreazione: 'Emissione elettrica inserita con successo',
            erroreCreazione: 'Errore durante l\'inserimento dell\'emissione elettrica',

            successoModifica: 'Emissione elettrica modificata con successo',
            erroreModifica: 'Errore durante la modifica dell\'emissione elettrica',
        }
    },
}