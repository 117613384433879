export const en_fattori_esg = {
    FormFattoreEsgBilanci: {
        modificaBilancioPerAnno: 'Edit the sustainability report of the year',
        creaUnNuovaBilancio: 'Create new sustainability report',
        modificaDatiIBilanci: 'Edit data for the sustainability report of the year',
        testoModifica: 'Choose a year and enter a description. You can later enter ESG topics for the sustainability report created',
        selezionaAnno: 'Select a year',
        fornisciBilancio: 'Privde a description for the sustainability report',
        placeholderBusinessunit: 'Sustainability report description',
        creaBilancio: 'Create sustainability report'
    },

    BilancioContextMenu: {
        eliminatoSucesso: 'Sustainability report successfully deleted',
        modificaBilancio: 'Edit sustainability report',
        eliminaBilacio: 'Are you sure you want to delethe the sustainability report of the year',
        eliminaBilancioTesto: 'This action is irreversible. All data previously associated with this sustainability report will be permanently lost.',
        eliminaBilancioDelAnno: 'Delete the sustainability report of the year',
        eliminaBilancioButtone: 'Delete sustainability report',
    },

    BilancioTile: {
        bilancioDiSostenibilita: 'Sustainability report for the year',
        rendiconta: 'report',
    },

    CanvasAttribuisciBusinessUnit: {
        nessunBusinessUnitTrovata: 'No business unit available',
        utilizzaIl: 'Go to the',
        moduloBusinessUnit: 'Business Units module',
        perCrearneDiNuovo: 'to create new units',
    },

    FiltroRicerca: {
        testoFiltroRicerca: "Search topics by name and description"
    },

    FormFattoreBilancioBusinessUnit: {
        selezionaLebusinessUnit: 'Select the business units you want to connect to the topic',
        perIlBilancio: 'for the sustainability report of the year',
        testoBusinessUnitSelezionato: 'Each business unit selected and assigned to this topic also grants cascading access to its sub-topics.',
        nonSelezionata: 'Not selected',
        selezionata: 'Selected',
        conAcessoEridato: 'With access inherited',
        ricarica: 'Reload',
        businessUnitSelezionate: 'Business units selected',
        businessUnitPadriSelezionate: 'Parent Business units selected',
        attribuisci: 'Assign',
        businessUnitQestoFattore: 'business units to this topic',
    },

    statoAvanzamentoCompletamentoIndicatori: {
        indicatoriRendicontare: 'values to report on',
        indicatoriDiValidare: 'values to validare',
        nonSonoAncoraRendicontatre: 'No value has been reported on yet',
        rendicontati: 'reported',
        validati: 'validated',
        nonSonoPresenti: 'No value provided',
    },

    BloccoFattore: {
        obbietivo2030: 'Agenda 2030\'s goal',
        core: 'Core',
        businessUnit: 'Business units',
        collegate: 'connected',
        nessunaBusinessUnitcollegata: 'No business unit connected to this topic',
        fattori: 'topics',
    },

    ModuloBilanciFattori: {
        fattoriBilancio: 'Topics Report',
        fattoriBilancioBu: 'Topics Report BU',
        materialitaBilancio: 'Materiality Report'
    },

    BloccoFattoreDefaultTabella: {
        eliminatoSucessoTesto: 'Successfully deleted',
        primaRiga: 'Create first row',
        PrimaCollona: 'Create first column',
        tabellaEVuoto: 'This table is empty',
        eliminaCollona: 'Are you sure you want to delete this column',
        dellaTabella: 'from the table',
        nessunaCollonaInserita: 'No column created',
        eliminaRiga: 'Are you sure you want to delete this row',
        nessunaRigaInserita: 'No row created',
    },

    HoverFattoriBilancio: {
        nessunDescrizione: 'No description inserted',
    },

    BottoneAttribuisciFattore: {
        fattorePuoAggiunto: ' topic can be activated',
        fattoreNonPuoAggiunto: ' topics can be activated',
    },

    BottoneAttribuisciStandard: {
        standardAttribuito: 'Standard successfully connected',
        verficatoUnErrore: 'An error occured',
        scegliUnStandard: 'Choose a standard',
        proseguandoAttribuirai: 'You will assign all the topics in the standard',
        bilancioDel: 'to the sustainability report of the year',
        nessunStandardTrovato: 'No standard found',
    },

    ListaFattoriDisattivi: {
        fattoreAttivoSucesso: 'Topic successfully activated in the sustainability report',
        erroreDurante: 'Error during the topic activation',
        standardUtilizato: 'The standard used does not include any other topic assignable to this category.',
    },

    bloccoFattoreBilancioContextMenu: {
        fattoreDisattivatoSucesso: 'Topic successfully deactivated from the sustainability report',
        disattivaFattore: 'Deactivate topic',
        attribuisciBusinessUnitFattore: 'Assign business unit',
        collegaTemiDiMaterialita: 'Connect materiality topic',
    },

    bloccoFattoreContextMenu: {
        fattoreEliminatoSucesso: 'Topic successfully deleted',
        eliminaFattore: 'Are you sure you want to delete the topic',
        eliminaFattoreTesto: 'All the sustainability reports that use this topic will lose it with all of the values associated.',
        eliminaIlFattore: 'Delete the topic',
    },

    bloccoStandardContextMenu: {
        standardRimosoConSucesso: 'Standard successfully removed from the sustailability report',
        eliminaStandard: 'Are you sure you want to remove this standard from the sustainability report of the year',
        eliminaStandardTesto: 'All the topics in this standard will be removed from the sustainability report too.',
        rimouviLoStandard: 'Remove the standard',
    },

    canvasFattoriEsg: {
        vaiAllaCategoriaPadre: 'Go to the parent category',
        attribuisciUnoStandardSulBilancio: 'Connect a standard to the sustainability report',
        vaiAlModuloAttribuzione: ' Go to the module "Connect topics to sustainability report" to assign a standard to this sustainability report',
        tornaAgliStandard: 'Go to the standards list'

    },

    formFattorEsg: {
        modificandoLinguaIn: 'You are editing this factor in: ',
        ilFattore: 'the topic',
        modificaIDati: 'Edit the data for',
        delFattore: 'of the topic',
        stabilisciCodice: 'Provide a code, a name and a description for',
        fornisciUnCodice: 'Provide a code for the topic',
        placeholderFattore: 'es: gri_2016_12',
        fornisciUnNome: 'Provide a name for the topic',
        placeHolderNomeEsteso: 'es: General disclosures',
        placeholderDescrizionefattore: 'In-depth description',
        richiesto: ' Required',
        determinaSeQuesto: 'Establish if this is a core topic, if you check this checkbox it won\'t be possible to deactivate this topic in any sustainability report.',
        FattorecreatoConSucesso: 'Successfully created',
        specificaTipoIndicatore: 'You can choose a value type to define how this ESG topic must be reported on.',
        aggiungiRispostePossibili: 'Add the answers you want the user can choose to report on this topic.',
        aggiungiRisposta: 'Add answer',
        stabilisciRispostePossibili: 'Define the maximum number of answers the data owner can select for this topic',
        defaultUM: 'You can set the unit of measurement this topic must be reported on',
        specificaSelezioneUM: 'Let this field empty, or set the option "None", to let the user free to choose which unit of measurement use to report on this topic.'
    },

    GraficoBarreFattoreBilancio: {
        perAnnoConUnitaDiMisura: 'by year with measurement unit',
        datiGrezzi: 'Raw data',
        graficoLineare: 'Linear chart',
        graficoBarre: 'Bar chart',
        salvaComeImagine: 'Save as image',
    },

    GraficoBarreTabellaBilanci: {
        laUnitaDiMisura: 'The measurement units used to report this cell are not consistent across years, so the data shown in this chart cannot be considered consistent.',
        unitaDimisuraInconsistente: 'Inconsistent measurement units',
        nessunDatoRendicontazione: 'No data reported in any sustainability report for this column:',
        nellaRiga: 'on the row:',
    },

    IndicatoriSchedaTabellaESG: {
        NonRendicontato: 'This cell has not been reported on in any sustainability report yet'
    },

    standardEsgContextMenu: {
        standardEliminatoSuccesso: 'Standard successfully deleted',
        eliminaStandardEsg: 'Are you sure you want to delete the standard',
        eliminaStandardEsgTesto: 'This action è irreversibile. All sustainability report data associated with this standard will be permanently lost',
        eliminaLoStandard: 'Delete standard',
        modificaStandard: 'Edit standard',
    },

    FormIndicatore: {
        giaStatoValidato: 'This values has already been validated',
        indicatore: 'The value',
        nelBilancioDel: 'in the sustainability report of the year',
        perLaBusinessUnit: 'for the business unit',
        seguente: 'it\'s the following:',
        placeholderValore: '0.2, 2, 22',
        placeholderUm: 'UM',
        Testo: 'Text',
        placeholderTesto: 'Provide a text',
        note: 'Notes',
        placeholderNote: 'No note inserted',
        rimuviValidizioneIndicatori: 'Remove the validation from this value',
        nonHaiIlPrivellagio: 'You have no privilige to interact with this value',
        attribuisciIndicatore: ' Assign a value to',
        valoreNumerico: 'Numeric value',
        valoreTestuale: 'Text value',
        valoreBooleano: 'Yes / No',
        valoreRispostaMultipla: 'Multiple Answet',
        placeholderNoteAltra: 'Insert an optional note',
    },

    FormIndicatoreTabella: {
        indicatoreEliminatoSucesso: 'Value successfully deleted',
        indicatoreValidatoSucesso: 'Value successfully validated',
        Datistrutturati: 'Structured data',
        statistiche: 'statistics',
        statistichePerRiga: 'statistics by row',
        minimo: 'Minimum',
        media: 'Avarage',
        massimo: 'Maximum',
        somma: 'Sum',
        indicatoreValidato: 'Value validated',
        modificaIndicatore: 'Edit value',
        validaIndicatore: 'Validate value',
        eliminaIndicatore: 'Delete value',
        rimouviValidazione: 'Remove value validation',
        perCollona: 'by column',
        Inconsistenti: 'inconsistent',
        unitaDiMisura: 'Measurement unit',
    },

    InformativeFattoriEsg: {
        SchedaInformativa: 'Information sheet',
        listaObiettivi: 'List of Goals of Agenda 2030 related to this topic:',
        nessunaDescrizione: 'No description available for this ESG topic',
    },

    SchedaCategoria: {
        TabellePresenti: 'Available tables',
        listaTuttiTabelle: 'List of all the available tables.',
    },

    SchedaFattoreEsg: {
        questoFattoreRendicontato: 'This topic can not be reported on for',
        questoFattoreNonCollegato: 'This topic is not connected to any business unit, so it can\'t be reported on.',
        businessUnit: 'business units.',
    },

    BoxMaterialitaBilanco: {
        MatriceDiMaterialità: 'Materiality Assessment:',
        mattriceDellaMaterialita: 'The materiality assessment allows a company to idenify the main aspects of the sustainability the company and the stakeholders need to focus on. The term materiality emphasizes the concreteness and measurability of all the elements that show a company\'s commitment to being sustainable.',
        aggiungiNuovaTemi: 'Add new materiality topic',
        vissulizaListatemi: 'Show the list of all the materiality topics',
        vissulizaMatrice: 'Show materiality assessment',
        nessunTemiApplicato: 'No materiality topic associated to this sustainability report',
        applicailPrimaTemo: 'Connect the first materiality topic',
        vaiAlModulo: 'Go to the module "Attribution of materiality topics to sustainability report" to connect the first materiality topic'
    },

    GraficoMaterialitaBilancio: {
        valorePerGliStakeholder: 'Relevance for the stakeholders',
        valorePerAzienda: 'Relevance for the company',
    },

    TemaMaterialitaBilancioContextMenu: {
        scollegatoSucesso: 'The materiality topic has been successfully disconnected from the sustainability report.',
        modificaParametri: 'Edit parameters',
        scollegaTemiMaterialita: 'Are you sure you want to remove the materiality topic',
        daQuestoBilancio: 'from this sustainability report?',
        scollegaIlTemaMaterialita: 'Remove materiality topic',
        scollegaDalBilancio: 'Remove from the sustainability report',
    },

    FormTemaMaterialitaBilancio: {
        ModificaMaterilita: 'Edit the materiality topic',
        applicatoAquestoBilancio: 'connected to this sustainability report',
        applicaUnatema: 'Connect a Materiality topic to the sustainability report of the year',
        modificaIDati: 'Edit data for this Materiality topic in the sustainability report',
        scgliUnaTema: 'Choose a Materiality topic, enter a description for this sustainability report and a relevance.',
        temaDiMaterialita: 'Materiality topic',
        scegliUnatemaMaterialita: 'Choose a Materiality topic',
        RilevanzaPerlazienda: 'Relevance for the company',
        fornisciUnValore: 'Set a relevance value for the company',
        RilevanzaPerStakeholder: 'Relevance for the stakeholders',
        fornisciValorePerStakeHolder: 'Set a relevance value for the stakeholders',
        modifciatemaDiMaterialita: 'Edit Materiality topic',
        applicaTemaDiMaterialita: 'Apply Materiality topic',
        applicatoSucesso: 'Materiality topic successfully applied',
    },

    TileTemaMaterialitaBilancio: {
        RilevanzaAzienda: 'Company relevance',
        RilevanzaStakeholder: 'Stakeholders relevance',
    },

    FormtemaMaterialitaFattoreBilancio: {
        applicaTemaMaterilita: 'Apply materiality topics to this ESG topic',
        selezioneUnoOPiuTemi: 'Select one or more materiality topics to connect to this ESG topic',
        temi: 'Topics',
        selezioneTemi: 'Select topics',
        tuttiIselezionati: 'All the topics selected',
        selezionaTutti: 'Select all',
        collegaTemiAFattore: 'Connects materiality topics to the topic',
        temiCollegatiSucesso: 'Materiality topics successfully connected to the topic',
    },

    ArchivioRendicontazioneBilancio: {
        nonHaiAncoraAcesso: 'You do not yet have access to any sustainability report, contact an admin to request more information.',
    },

    FormRendicontazioneIndicatotre: {
        giaValidato: 'This value has already been validated',
        IndicatoreAttribuitoSucesso: 'Value successfully attributed',
    },

    FormRendicontazioneIndicatori: {
        validazionrRomiso: 'Validation successfully removed',
    },

    SchedaRendicontazioneBilancio: {
        schedaDirendicontazione: 'Reporting sheet for the sustainability report of the year',
        inQuestoSezione: 'In this section you can report on all the ESG topics to which you have access to.',
        fattoriDisponibile: 'Topics available for the business unit:',
        puoiRendicontare: 'You can report',
        fattoriInQuestoBusinessUnit: 'in this business unit with role access:',
        Consultazione: ' Consultation',
        Raccolta: ' Entry',
        Validazione: ' Validation',
        Gestione: ' Management',
        caricandoFattoriESG: 'Loading ESG Topics you can report on..'
    },

    TileRendicontazioneFattore: {
        statoAvanzamentoRendicontazione: 'Reporting progress',
        nonRendicontato: 'Not reported yet',
        rendicontato: 'Reported',
        validato: 'Validated',
        fattoreRendicontatoLiberamente: 'This topic can be reported with a numeric, textual, or a yes / no value.',
        tipologiaRendicontazioneRichiesta: 'Type of value required:'
    },

    IndicatoreFattoreBusinessUnit: {
        nessunDescrizione: 'No description available for this business unit',
    },

    SchedaBilancio: {
        tornaAllArchivio: 'Back to the sustainability reports archive',
        BilancioSostenibilita: 'Sustainability report of the',
        vaiAllaSchedaCompleta: 'Go to the full susainability report for the year',
        bilancio: 'Reports'
    },

    NavbarSchedaBilancio: {
        IndiceContenuti: 'Content Index',
        TemiMaterialita: 'Materiality topics',
        ObiettiviAgenda2030: 'Agenda 2030 goals',
        Gruppi: 'Groups'
    },

    DashboardSchedaBilancio: {
        StandardApplicati: 'Standard connected to this report',
        Fattori: 'Topics connected to this report',
        FattoriRendicontabili: 'Topics can be reported on in this report',
        TabelleRendicontabili: 'Tables can be reported in this report',
        TemiMaterialita: 'Materiality topics connected to this report',
        Obiettivi2030: 'Goals of the 2030 agenda taken into account in this report',
        BusinessUnits: 'Business Units have access to this report',
        Gruppi: 'Groups have access to this report'
    },

    SchedafattoreEsgBilancio: {
        temiDiMaterialitaCollegati: 'materiality topics related to this ESG topic for the sustainability report of the year',
    },

    SchedaTemaMaterialitaBilancio: {
        fattoreCollegati: 'ESG topics related to this Materiality topic in the sustainability report of of the year',
        nessunFattoreESGAssociato: 'No ESG topic associated with this materiality topic for the sustainability report of the',
        associarefattori: 'To associate ESG topics with this materiality topic:',
        collegaStandard: 'Connect a standard to a sustainability report from the module',
        AttribuzioneFattori: 'Connect topics to sustainability report',
        utilizzaIlMenu: 'Use the contextual menu found in the ESG topics tree from the form',
        attribuzioneTemi: 'Connection of materiality topics to sustainability report',
        premendoVoce: 'by clicking on the "connect materiality topics"',
        selezioneDalForm: 'Select from the form all the materiality topics you want to connect to the ESG topic',
    },

    ObiettivoAgenda2030ContextMenu: {
        obiettivoEliminatoSucesso: 'The goal has been successfully deleted.',
        modificaObiettivo: 'Edit goal',
        eliminaObiettivo: 'Are you sure you want to delete the goal',
        QuestaIrreversibile: 'This action is irreversible.',
        eliminaObiettivoTesto: 'Delete the goal',
    },

    FormObiettivoAgenda2030: {
        ModificaObiettivo: 'Edit the goal',
        creaObiettivo: 'Create a goal for the agenda 2030',
        modifcaIDati: 'Edit the data for',
        dellObietivo: 'of the goal',
        StabilisciUnCodice: 'Set a code, a name and a description for this goal.',
        id: 'Id',
        fornisciObiettivo: 'Set and id for the Agenda 2030 goal',
        placeholderObiettivo: '"es: 1, 2, 3"',
        placeholderNomeObiettivo: 'es: Goal name',
        fornisciNomePerObiettivo: 'Name required for the goal',
        placeholderNomeestesoObiettivo: '"es: Extended goal name"',
        nomeImagine: 'Name of the image',
        colorEtichetta: 'Label color',
    },

    ArchivioObiettiviAgenda2030: {
        creaNuovoObiettivo: 'Create a new goal for the agenda 2030',
        nessunObittivioPresente: 'No goal available',
        ruota: 'Wheel',
        lista: 'List',
    },

    SchedaObiettivoAgenda2030: {
        TornaAllaLista: 'Go back to the goals list',
        NonePossibileDescrizione: 'No description available for this goal',
    },

    CanvasBloccoApplicaObiettiviFattori: {
        scollegareObiettivio: 'are you sure you want to disconnect the goal',
        dalFattore: 'from the topic',
        conferma: 'Confirm',
        obbietivo: 'Goal',
        romosoSuccesso: 'successfully removed from the ESG topic'
    },

    CanvasApplicaObiettiviAgenda2030: {
        premiFattori: 'Click on the ESG topics to select them, you can then assign the 2030 agenda goals.',
    },

    FormAggiungiObiettiviAgenda2030Fattori: {
        fattoriSelezionati: 'Selected topics',
        gliObbietviDellaAzeinda: 'Agenda 2030 Goals to be added to',
        obiettivi: 'goals to',
    },

    TemaMaterialitaContextMenu: {
        TemaEliminatoSucesso: 'Materiality topic successfully deleted',
        eliminaTema: 'Are you sure you want to delete the Materiality topic',
        eliminaTemaMaterialita: 'Delete the Materiality topic',
    },

    FormTemaMaterialita: {
        creaTemaMaterialita: 'Create a Materiality topic',
        delTema: 'of the topic',
        fornisciCodice: 'Code for the Materiality topic required',
        placeholderTemaMaterilita: 'es: Materiality topic',
        fornisciNomeTema: 'Name for the Materiality topic required',
    },

    ArchivioMaterialita: {
        nessunTemaPersente: 'No Materiality topic availalbe',
        creaMaterialita: 'Create a new Materiality topic',
    },

    InformativaMaterialita: {
        nessunDescrizioneDisponibile: 'No description available for this Materiality topic'
    },

    schedaBilancioTemaMaterialita: {
        tornaAlBilancio: 'Go back to the sustainability reports list',
        listaDeiFattori: 'List of ESG topics applied to the sustainability report of the year',
        perTema: 'For the materiality topic',
        nonCiSonoFattori: 'There are no ESG topics related to this materiality topics in the sustainability report of the year',
    },

    SchedaTemaMaterialita: {
        tornaAllaLista: 'Go back to the list of Materiality topics'
    },

    ArchivioStandardEsg: {
        nessunStandardPresente: 'No standards available',
        creaNuovoStandard: 'Create a new standard',
    }
}