export const en_modulo_carbon_footprint = {
    common: {
        fattoreDiEmissione: 'Emission factor',
        electricitySupplier: 'Electricity Supplier',

        elettricita: 'Electricity',
        emissioneElettrica: 'Electricity emission',

        veicolo: 'Vehicle',
        gruppoVeicoli: 'Group of vehicles',
        gruppiVeicoli: 'Groups of vehicles',
        veicoli: 'Vehicles',
        dimensione: 'Size',
        tipologia: 'Type',
        numeroVeicoli: 'Number of vehicles',
        chilometraggioTotale: 'Total mileage',
        quantitaCarburante: 'Fuel quantity',
        dimensioneVeicolo: 'Vehicle size',
        dimensioniVeicoli: 'Vehicle sizes',
        tipologiaVeicoli: 'Vehicle type',
        tipologieVeicoli: 'Vehicle types',

        gasRefrigerante: 'Refrigerant Gas',
        gasRefrigeranti: 'Refrigerant gases',
        emissioneGasRefrigerante: 'Refrigerant Gas emission',
        emissioniGasRefrigeranti: 'Refrigerant gases emissions',
        quantitaContenutaGas: 'Contained quantity',
        quantitaRicaricataGas: 'Recharged quantity',

        carburante: 'Fuel',
        carburanti: 'Fuels',
        nomeImpianto: 'Facility / plant name',

        nonHaiAccesso: 'You have no access to any business unit that allows you to report on this section.',
    },

    rendCarburanti: {
        titolo: 'Fuel emissions for the business unit: ',
        aggiungi: 'Add emission',

        successoEliminazione: 'Fuel emission successfully deleted',
        erroreEliminazione: 'Error deleting the fuel emission',

        successoValidazione: 'Fuel emission successfully validated',
        erroreValidazione: 'Error validating the fuel emission',

        successoRimValidazione: 'Validation successfully removed',
        erroreRimValidazione: 'Error during validation removing',

        form: {
            titoloModifica: 'Edit the fuel emission for the business unit:',
            titoloCreazione: 'Add a fuel emission for the business unit:',

            successoCreazione: 'Fuel emission successfully added',
            erroreCreazione: 'Error adding the fuel emission',

            successoModifica: 'Fuel emission successfully edited',
            erroreModifica: 'Error editing the fuel emission',
        }
    },

    rendGas: {
        titolo: 'Refrigerant gas emissions for the business unit: ',
        aggiungi: 'Add emission',

        successoEliminazione: 'Refrigerant gas emission successfully deleted',
        erroreEliminazione: 'Error deleting the refrigerant gas emission',

        successoValidazione: 'Refrigerant gas emission successfully validated',
        erroreValidazione: 'Error validating the refrigerant gas emission',

        successoRimValidazione: 'Validation successfully removed',
        erroreRimValidazione: 'Error during validation removing',

        form: {
            titoloModifica: 'Edit the refrigerant gas emission for the business unit:',
            titoloCreazione: 'Add a refrigerant gas emission for the business unit:',

            successoCreazione: 'Refrigerant gas emission successfully added',
            erroreCreazione: 'Error adding the refrigerant gas emission',

            successoModifica: 'Refrigerant gas emission successfully edited',
            erroreModifica: 'Error editing the refrigerant gas emission',
        }
    },

    rendVeicoli: {
        titolo: 'Group of vehicles for the business unit: ',
        aggiungi: 'Add group of vehicles',

        successoEliminazione: 'Group of vehicles successfully deleted',
        erroreEliminazione: 'Error deleting the Group of vehicles',

        successoValidazione: 'Group of vehicles emission successfully validated',
        erroreValidazione: 'Error validating the group of vehicles emission',

        successoRimValidazione: 'Validation successfully removed',
        erroreRimValidazione: 'Error during validation removing',

        form: {
            titoloModifica: 'Edit the Group of vehicles for the business unit:',
            titoloCreazione: 'Add a Group of vehicles for the business unit:',

            successoCreazione: 'Group of vehicles successfully added',
            erroreCreazione: 'Error adding the Group of vehicles',

            successoModifica: 'Group of vehicles successfully edited',
            erroreModifica: 'Error editing the Group of vehicles',
        }
    },

    rendElettricita: {
        titolo: 'Electricity emissions for the business unit: ',
        aggiungi: 'Add emission',

        successoEliminazione: 'Electricity emission successfully deleted',
        erroreEliminazione: 'Error deleting the electricity emission',

        nonEsistonFattoriDiEmissionePaese: 'There are no emission factors for the country',
        questoValoreUtilizzeraFattore: 'This emission will use the emission factors for the country',
        perAnno: 'for the year',

        successoValidazione: 'Electricity emission successfully validated',
        erroreValidazione: 'Error validating the electricity emission',

        successoRimValidazione: 'Validation successfully removed',
        erroreRimValidazione: 'Error during validation removing',

        form: {
            titoloModifica: 'Edit the electricity emission for the business unit:',
            titoloCreazione: 'Add a electricity emission for the business unit:',

            successoCreazione: 'Electricity emission successfully added',
            erroreCreazione: 'Error adding the electricity emission',

            successoModifica: 'Electricity emission successfully edited',
            erroreModifica: 'Error editing the electricity emission',
        }
    },
}