import React, { useState } from 'react'
import MenuGenerico from '../../../components/common/MenuGenerico'

import AnalisiCarbonFootprintAnnuale from './analisiGenerale/AnalisiCarbonFootprintAnnuale'
import AnalisiCarbonFootprintGenerale from './analisiGenerale/AnalisiCarbonFootprintGenerale'
import AnalisiCarbonFootprintStoricoRendicontazione from './analisiGenerale/AnalisiCarbonFootprintStoricoRendicontazione'
import AnalisiCarbonFootprintBusinessUnitRendicontabili from './analisiGenerale/AnalisiCarbonFootprintBusinessUnitRendicontabili'
import AnalisiCarbonFootprintAggregatoEmissioni from './analisiGenerale/AnalisiCarbonFootprintAggregatoEmissioni'

const AnalisiCarbonFootprint = () => {

  const [tabellaAttiva, setTabellaAttiva] = useState<
    'aggregato_emissioni' |
    'resoconto_annuale' |
    'resoconto_generale' |
    'storico_rendicontazione' |
    'business_units_rendicontabili'
    >('aggregato_emissioni')

  return (
    <div>

      <MenuGenerico bottoni={[
        {
          label: 'Aggregato emissioni',
          action: () => setTabellaAttiva('aggregato_emissioni'),
          attivo: tabellaAttiva === 'aggregato_emissioni' ? true : false
        },
        {
          label: 'Resoconto annuale',
          action: () => setTabellaAttiva('resoconto_annuale'),
          attivo: tabellaAttiva === 'resoconto_annuale' ? true : false
        },
        {
          label: 'Resoconto generale',
          action: () => setTabellaAttiva('resoconto_generale'),
          attivo: tabellaAttiva === 'resoconto_generale' ? true : false
        },
        {
          label: 'Storico rendicontazione',
          action: () => setTabellaAttiva('storico_rendicontazione'),
          attivo: tabellaAttiva === 'storico_rendicontazione' ? true : false
        },
        {
          label: 'Business Units Rendicontabili',
          action: () => setTabellaAttiva('business_units_rendicontabili'),
          attivo: tabellaAttiva === 'business_units_rendicontabili' ? true : false
        },
      ]} />

      {tabellaAttiva === 'aggregato_emissioni' && <AnalisiCarbonFootprintAggregatoEmissioni />}

      {tabellaAttiva === 'resoconto_annuale' && <AnalisiCarbonFootprintAnnuale />}

      {tabellaAttiva === 'resoconto_generale' && <AnalisiCarbonFootprintGenerale />}

      {tabellaAttiva === 'storico_rendicontazione' && <AnalisiCarbonFootprintStoricoRendicontazione />}

      {tabellaAttiva === 'business_units_rendicontabili' && <AnalisiCarbonFootprintBusinessUnitRendicontabili />}

    </div>
  )
}

export default AnalisiCarbonFootprint;