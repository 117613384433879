import React, { useEffect, useState } from 'react'

//Data
import { Controller, useForm } from 'react-hook-form'
import { crea, get, modifica } from '../../../../../rest/crud_generica';
import { useStores } from '../../../../../hooks/useStores';
import { IGruppoVeicoli } from '../../../types';

//Components
import InputField from '../../../../../components/common/form/InputField';
import SpinnerButton from '../../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../components/common/InfoBox';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';


const RendicontazioneVeicoliForm: React.FC<{
    gruppoVeicoli?: IGruppoVeicoli,
    businessUnit?: string,
    anno: number
}> = ({
    gruppoVeicoli,
    businessUnit,
    anno,
}) => {

        const { t } = useTranslation()
        const { ui } = useStores()
        const [attesa, setAttesa] = useState<boolean>(false)
        const [successo, setSuccesso] = useState<string>('')
        const [errore, setErrore] = useState<string>('')

        const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
            defaultValues: {
                dimensione: gruppoVeicoli ? gruppoVeicoli.dimensione : '',
                tipologia: gruppoVeicoli ? gruppoVeicoli.tipologia : '',
                unita_misura: "Km",
                numero_veicoli: gruppoVeicoli ? gruppoVeicoli.numero_veicoli : '',
                chilometraggio_totale: gruppoVeicoli ? gruppoVeicoli.chilometraggio_totale : '',
                quantita_carburante: gruppoVeicoli ? gruppoVeicoli.quantita_carburante : '',
            }
        });

        const onSubmit = () => {
            setAttesa(true)
            gruppoVeicoli
                ?
                modifica(`carbon_footprint/rendicontazione/veicoli/${businessUnit}/${anno}/${gruppoVeicoli.tipologia}/${gruppoVeicoli.dimensione}`, {
                    dimensione: watch('dimensione'),
                    tipologia: watch('tipologia'),
                    unita_misura: watch('unita_misura'),
                    numero_veicoli: watch('numero_veicoli'),
                    chilometraggio_totale: watch('chilometraggio_totale'),
                    quantita_carburante: watch('quantita_carburante'),
                    business_unit: businessUnit,
                    anno: anno,
                })
                    .then((res) => {
                        setErrore('')
                        setSuccesso(res.data.message ? res.data.message : 'Gruppo di veicoli modificato con successo')
                        ui.triggerAggiornamentoGenerico()
                    })
                    .catch((err) => {
                        setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                        setSuccesso('')
                    })
                    .finally(() => setAttesa(false))
                :
                crea(`carbon_footprint/rendicontazione/veicoli`, {
                    dimensione: watch('dimensione'),
                    tipologia: watch('tipologia'),
                    unita_misura: watch('unita_misura'),
                    numero_veicoli: watch('numero_veicoli'),
                    chilometraggio_totale: watch('chilometraggio_totale'),
                    quantita_carburante: watch('quantita_carburante'),
                    business_unit: businessUnit,
                    anno: anno,
                })
                    .then((res) => {
                        setErrore('')
                        setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendVeicoli.form.successoCreazione'))
                        ui.triggerAggiornamentoGenerico()
                    })
                    .catch((err) => {
                        setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                        setSuccesso('')
                    })
                    .finally(() => setAttesa(false))
        }

        const [listaVeicoliCostanti, setListaVeicoliCostanti] = useState<[]>([])

        useEffect(() => {

            get('carbon_footprint/veicoli_costanti/lista_senza_fattori').then((res) => {
                setListaVeicoliCostanti(res.data.data.filter((cos: any) => cos.anno == anno))
            }
            )

        }, [])


        //Ristruttura dati per label MultiSelect
        listaVeicoliCostanti && listaVeicoliCostanti.length > 0 &&
            listaVeicoliCostanti.map((costante: any) => {
                costante.value = `${costante.tipologia}|${costante.dimensione}`
                costante.label = `${costante.tipologia} | ${costante.dimensione}`
            })


        const triggerSettaParametri = ((costante: any) => {

            if (typeof costante === 'object') {
                setValue('dimensione', costante.dimensione)
                setValue('tipologia', costante.tipologia)
            }

            if (typeof costante === 'string') {

                let arrayCost = costante.split('|')

                let dimensione = arrayCost[0]
                let tipologia = arrayCost[1]

                setValue('dimensione', dimensione)
                setValue('tipologia', tipologia)

            }

        })

        useEffect(() => {
            if (gruppoVeicoli) {
                triggerSettaParametri(gruppoVeicoli)
            }
        }, [])

        return (
            <div>
                <div>
                    {gruppoVeicoli ?
                        <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendVeicoli.form.titoloModifica')} {businessUnit}</h2>
                        :
                        <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendVeicoli.form.titoloCreazione')} {businessUnit}</h2>
                    }
                </div>

                <form className="form" onSubmit={handleSubmit(onSubmit)}>


                    <div className="form">
                        <label>{t('ModuloCarbonFootprint.common.tipologia')}</label>
                        <Select
                            options={listaVeicoliCostanti}
                            onChange={(e: any) => triggerSettaParametri(e)}
                            defaultValue={gruppoVeicoli?.tipologia && {
                                label: gruppoVeicoli.tipologia,
                                value: gruppoVeicoli.tipologia,
                            }}
                        />
                    </div>

                    <InputField
                        label={t('ModuloCarbonFootprint.common.numeroVeicoli')}
                        type="number"
                        error={errors.numero_veicoli}
                        form={register("numero_veicoli")}
                        placeholder="1"
                    />

                    <InputField
                        label={`${t('ModuloCarbonFootprint.common.quantitaCarburante')} (in l)`}
                        type="number"
                        error={errors.quantita_carburante}
                        form={register("quantita_carburante")}
                        placeholder="56000"
                    />

                    <InputField
                        label={`${t('common.facoltativo')} - ${t('ModuloCarbonFootprint.common.chilometraggioTotale')} (in Km)`}
                        type="number"
                        error={errors.chilometraggio_totale}
                        form={register("chilometraggio_totale")}
                        placeholder="56000"
                    />

                    {
                        !attesa ?
                            <input type="submit" value={gruppoVeicoli ? t('common.salvaModifica') : t('common.crea')} />
                            : <SpinnerButton />
                    }

                    {(errore || successo) &&
                        <div className="mt-2">
                            {errore && <ErrorBox errore={errore} />}
                            {successo && <InfoBox messaggio={successo} />}
                        </div>
                    }


                </form>
            </div>
        )
    }

export default RendicontazioneVeicoliForm;