import React from 'react'
import ReactEcharts from "echarts-for-react"

const GraficoResocontoAnnuale: React.FC<{ resocontoAnnuale: any[] }> = ({ resocontoAnnuale }) => {

  let emissioniCombustibile = { value: resocontoAnnuale[0].emissione_annuale_t_co2e_combustibile, name: 'Combustibile' }
  let emissioniVeicoli = { value: resocontoAnnuale[0].emissione_annuale_t_co2e_veicoli, name: 'Veicoli' }
  let emissioniGas = { value: resocontoAnnuale[0].emissione_annuale_t_co2e_gas_refrigerante, name: 'Gas refrigeranti' }
  let emissioniElettricita = { value: resocontoAnnuale[0].emissione_annuale_t_co2e_elettricita, name: 'Elettricità' }

  let dati: any[] = []

  if (emissioniCombustibile.value > 0) {
    dati.push(emissioniCombustibile)
  }
  if (emissioniVeicoli.value > 0) {
    dati.push(emissioniVeicoli)
  }
  if (emissioniGas.value > 0) {
    dati.push(emissioniGas)
  }
  if (emissioniElettricita.value > 0) {
    dati.push(emissioniElettricita)
  }

  let option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '50%'],
        avoidLabelOverlap: false,
        label: {
          formatter: function (params: any) {
            return `${params.name} (${params.value} t / co2e)`;
          }
        },
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2
        },
        emphasis: {
          label: {
            fontSize: 14,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: dati
      }
    ]
  };

  return (
    <div>
      <ReactEcharts style={{ minHeight: 600 }} option={option} />
    </div>
  )
}


export default GraficoResocontoAnnuale;