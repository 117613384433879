import React from 'react'
import ReactEcharts from "echarts-for-react"

const GraficoResocontoGenerale: React.FC<{ resocontoGenerale: any[] }> = ({ resocontoGenerale }) => {

    let listaBu: Set<string> = new Set('')
    let nomiSeries = ['Impianti stazionari', 'Gas Refrigeranti', 'Veicoli', 'Elettricità']
    let series: any[] = []

    resocontoGenerale.forEach(item => listaBu.add(item.business_unit.substr(0, 20)))

    let datiImpianti: any[] = []
    let datiGas: any[] = []
    let datiVeicoli: any[] = []
    let datiElettricita: any[] = []

    resocontoGenerale.forEach((item) => {

        datiImpianti.push({
            business_unit: item.business_unit,
            valore: Math.floor(item.emissione_totale_t_co2e_combustibile),
        })

        datiGas.push({
            business_unit: item.business_unit,
            valore: Math.floor(item.emissione_totale_t_co2e_gas_refrigerante),
        })

        datiVeicoli.push({
            business_unit: item.business_unit,
            valore: Math.floor(item.emissione_totale_t_co2e_veicoli),
        })

        datiElettricita.push({
            business_unit: item.business_unit,
            valore: Math.floor(item.emissione_totale_t_co2e_elettricita),
        })


    })

    nomiSeries.forEach(nomeSerie => {

        let data = []
        let color = ''

        switch (nomeSerie) {
            case ('Impianti stazionari'):
                data = datiImpianti.map(item => item.valore)
                color = '#EDAA25'
                break;

            case ('Gas Refrigeranti'):
                data = datiGas.map(item => item.valore)
                color = '#C97E3C'
                break;

            case ('Veicoli'):
                data = datiVeicoli.map(item => item.valore)
                color = '#DB3A23'
                break;

            case ('Elettricità'):
                data = datiElettricita.map(item => item.valore)
                color = '#025259'
                break;
        }

        series.push({
            name: nomeSerie,
            type: 'bar',
            stack: 'total',
            itemStyle: { color: color },
            label: {
                formatter: function (d: any) {
                    return d.data > 0 ? `${d.data}` : '';
                },
                show: true
            },
            emphasis: {
                focus: 'series'
            },
            data: data,
        })

    })

    const option = {
        legend: {
            data: Array.from(nomiSeries)
        },
        toolbox: {
            show: true,
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        dataZoom: [
            {
                id: 'dataZoomY',
                type: 'slider',
                yAxisIndex: [0],
            },
            {
                id: 'dataZoomX',
                type: 'slider',
                xAxisIndex: [0],
            }
        ],
        responsive: true,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '1%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
        },
        yAxis: {
            data: Array.from(listaBu)
        },
        series: series
    };

    return (
        <div>
            <div className="text-center font-bold">Valori in t / co2e</div>
            <ReactEcharts style={{ height: listaBu.size * 40, minHeight: 200 }} className='h-full' option={option} />
        </div>
    )
}

export default GraficoResocontoGenerale;