import React from 'react'
import { observer } from 'mobx-react-lite';
import TabellaFeatureExportExcel from './features/TabellaFeatureExportExcel';


const FooterTabella = observer(() => {

    return (
        <div className="flex items-center justify-center gap-2 mt-2">
            <TabellaFeatureExportExcel />
        </div>
    )
})

export default FooterTabella;