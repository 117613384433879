import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../../../../../hooks/useStores'
import { ICostanteVeicoloTipologiaCarburante } from '../../../../types'
import { elimina, get } from '../../../../../../rest/crud_generica'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'

//Components
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'
import Dialogue from '../../../../../../components/common/Dialogue'
import { CgAdd } from 'react-icons/cg'
import { FaPen, FaTrash } from 'react-icons/fa'
import { ICallToActionTabella } from '../../../../../../components/tabellaInterattiva/types'
import { useTabellaInterattivaContext } from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'
import CostantiTipologiaVeicoliCarburantiForm from './CostantiTipologiaVeicoliCarburantiForm'

const CostantiTipologiaVeicoliCarburanti = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()

    const { ui } = useStores()

    const [costantiVeicoli, setCostantiVeicoli] = useState<ICostanteVeicoloTipologiaCarburante[]>([])

    useEffect(() => {
        listaCostantiTipologiaVeicoliCarburanti()
    }, [ui.aggiornamentoGenerico])

    //Ottieni lista gas
    const listaCostantiTipologiaVeicoliCarburanti = () => get('carbon_footprint/veicoli_tipologie_carburanti').then((res) => {
        setCostantiVeicoli(res.data.data)
    })

    //Crea gas
    const creaCostantiVeicolo = () => ui.mountAsideModal(<CostantiTipologiaVeicoliCarburantiForm />)

    //Modifica gas
    const modificaCostantiVeicolo = (tipologieVeicoloCarburante: ICostanteVeicoloTipologiaCarburante) => ui.mountAsideModal(<CostantiTipologiaVeicoliCarburantiForm tipologieVeicoloCarburante={tipologieVeicoloCarburante} />)

    //Elimina gas
    const eliminaCostantiVeicolo = (tipologieVeicoloCarburante: ICostanteVeicoloTipologiaCarburante) => elimina(`carbon_footprint/veicoli_tipologie_carburanti/${tipologieVeicoloCarburante.tipologia}/${tipologieVeicoloCarburante.carburante}/${tipologieVeicoloCarburante.anno}/${tipologieVeicoloCarburante.unita_misura}`)
        .then(() => {
            ui.mountDialogue(<Dialogue titolo="costante eliminata con successo" />)
            listaCostantiTipologiaVeicoliCarburanti()
        }
        )
        .catch(() => {
            ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione della dimensione" />)
        })

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const { tabella } = useTabellaInterattivaContext()
    useEffect(() => { }, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    testo: 'Crea costante',
                    azione: creaCostantiVeicolo,
                    icona: <CgAdd size={18} />
                }])

            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaCostantiVeicolo,
                    testo: 'Modifica',
                    icona: <FaPen size={12} />,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaCostantiVeicolo,
                    testo: 'Elimina',
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={costantiVeicoli}
                titolo="Costanti dei veicoli con carburanti"
                paragrafo="Componi le costanti basate su tipologie dei veicoli e carburanti."
                endpoint="carbon_footprint/veicoli_tipologie_carburanti"
                pk={['veicoli_tipologia', 'veicoli_dimensioni']}
                callToActions={ctaTabella}
                colonne={
                    [
                        { id: 'anno', label: 'Anno' },
                        { id: 'tipologia', label: 'Tipo' },
                        { id: 'carburante', label: 'Carburante' },
                        { id: 'unita_misura', label: 'Unità di misura' },
                        { id: 'kg_co2e', label: 'Kg co2e' },
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default CostantiTipologiaVeicoliCarburanti;