import React, { useState, useEffect } from 'react'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { elimina, get, modifica } from '../../../../../rest/crud_generica'
import { ICallToActionTabella } from '../../../../../components/tabellaInterattiva/types'
import { IBusinessUnitRendicontabile } from '../../../../UiModuloFattoriESG/types'
import { IEmissioneCarburante } from '../../../types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

//Components
import { FaInfoCircle, FaPen, FaTrash } from 'react-icons/fa'
import { BsShieldCheck } from 'react-icons/bs'
import { CgAdd } from 'react-icons/cg'
import Dialogue from '../../../../../components/common/Dialogue'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'
import RendicontazioneCarburantiForm from './RendicontazioneCarburantiForm'
import Spinner from '../../../../../components/common/Spinner'
import { useStatoRendicontazioneContext } from '../../context/useStatoRendicontazioneContext'

const RendicontazioneCarburanti = observer(() => {

    const { ui } = useStores()
    const { t } = useTranslation()

    //Lista delle bu
    const [businessUnits, setBusinessUnits] = useState<IBusinessUnitRendicontabile[]>([])
    const [caricamento, setCaricamento] = useState<boolean>(false)
    const { stato_rendicontazione } = useStatoRendicontazioneContext()

    //Ottiene lista bu
    useEffect(() => {

        setCaricamento(true)

        get('lista_ruoli_utente').then((res) => {
            setBusinessUnits(res.data.data)
            cambiaBuAttiva(res.data.data[0])
        }).finally(() => {
            setCaricamento(false)
        })
    }, [])

    //BU attiva
    const [businessUnitAttiva, setBusinessUnitAttiva] = useState<IBusinessUnitRendicontabile>()


    //Cambia BU attiva
    const cambiaBuAttiva = (bu: IBusinessUnitRendicontabile) => {
        setBusinessUnitAttiva(bu)
        ui.triggerAggiornamentoGenerico()
    }


    const [emissioniCarburante, setEmissioniCarburante] = useState<IEmissioneCarburante[]>([])

    useEffect(() => {

        businessUnitAttiva &&
            get(`carbon_footprint/rendicontazione/carburanti/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}`)
                .then((res) => {
                    setEmissioniCarburante(res.data.data)
                })


    }, [businessUnitAttiva, ui.aggiornamentoGenerico, stato_rendicontazione.annoAttivo])

    //Crea emissione
    const creaEmissioneCarburante = () => {
        businessUnitAttiva &&
            ui.mountAsideModal(
                <RendicontazioneCarburantiForm
                    businessUnit={businessUnitAttiva.nome}
                    anno={stato_rendicontazione.annoAttivo}
                />
            )
    }

    //Modifica emissione
    const modificaEmissioneCarburante = (emissioneCarburante: IEmissioneCarburante) => {
        businessUnitAttiva &&
            ui.mountAsideModal(
                <RendicontazioneCarburantiForm
                    businessUnit={businessUnitAttiva.nome}
                    anno={stato_rendicontazione.annoAttivo}
                    emissioneCarburante={emissioneCarburante} />
            )
    }

    //Elimina emissione
    const eliminaEmissioneCarburante = (emissioneCarburante: IEmissioneCarburante) => {
        businessUnitAttiva &&
            elimina(`carbon_footprint/rendicontazione/carburanti/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}/${emissioneCarburante.carburante}/${emissioneCarburante.nome}`)
                .then(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCarburanti.successoEliminazione')} />)
                    ui.triggerAggiornamentoGenerico()
                }
                )
                .catch(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCarburanti.erroreEliminazione')} />)
                })
    }

    //Valida emissione
    const validaEmissioneCarburante = (emissioneCarburante: IEmissioneCarburante) => {
        businessUnitAttiva &&
            modifica(`carbon_footprint/rendicontazione/carburanti/valida/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}/${emissioneCarburante.carburante}/${emissioneCarburante.nome}`, {})
                .then(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCarburanti.successoValidazione')} />)
                    ui.triggerAggiornamentoGenerico()
                }
                )
                .catch(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCarburanti.erroreValidazione')} />)
                })
    }

    //Invalida emissione
    const invalidaEmissioneCarburante = (emissioneCarburante: IEmissioneCarburante) => {
        businessUnitAttiva &&
            modifica(`carbon_footprint/rendicontazione/carburanti/invalida/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}/${emissioneCarburante.carburante}/${emissioneCarburante.nome}`, {})
                .then(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCarburanti.successoRimValidazione')} />)
                    ui.triggerAggiornamentoGenerico()
                }
                )
                .catch(() => {
                    ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCarburanti.erroreRimValidazione')} />)
                })
    }

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (businessUnitAttiva) {

            setCtaRiga([])
            setCtaTabella([])

            if (businessUnitAttiva.ruolo >= 1) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaEmissioneCarburante,
                    testo: t('common.modifica'),
                    icona: <FaPen size={12} />,
                },])

                setCtaRiga(cta => [...cta, {
                    azione: eliminaEmissioneCarburante,
                    testo: t('common.elimina'),
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])

                setCtaTabella(cta => [...cta, {
                    testo: t('ModuloCarbonFootprint.rendCarburanti.aggiungi'),
                    azione: creaEmissioneCarburante,
                    icona: <CgAdd size={18} />
                }])
            }

            if (businessUnitAttiva.ruolo >= 2) {

                setCtaRiga(cta => [...cta, {
                    azione: validaEmissioneCarburante,
                    testo: t('common.valida'),
                    icona: <BsShieldCheck size={12} />,
                    coloreSfondo: "bg-green dark:bg-dark-green"
                }])

                setCtaRiga(cta => [...cta, {
                    azione: invalidaEmissioneCarburante,
                    testo: t('common.rimuoviValidazione'),
                    icona: <BsShieldCheck size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])

            }
        }

    }, [businessUnitAttiva])


    return (
        <div className="flex flex-col">

            {caricamento &&
                <div className='flex items-center justify-center p-4 w-full'>
                    <Spinner colore='blue' />
                </div>
            }

            {businessUnits && businessUnits.length > 0 && !caricamento &&
                <div className="flex max-w-[90vw] sm:max-w-[450px] md:max-w-[600px] overflow-x-auto">
                    {
                        businessUnits.map((bu) => (
                            <div
                                key={bu.nome}
                                onClick={() => cambiaBuAttiva(bu)}
                                onMouseEnter={() => bu.nome.length > 30 && ui.mountFollowMouse(<>{bu.nome}</>)}
                                onMouseLeave={() => ui.unmountFollowMouse()}
                                className={`
                                p-1 px-4 py-2 cursor-pointer duration-200
                                ${businessUnitAttiva && businessUnitAttiva.nome === bu.nome ?
                                        'bg-accent dark:bg-dark-accent pointer-events-none text-white'
                                        :
                                        'bg-white dark:bg-darkgray-dd opacity-100 hover:opacity-70'
                                    }`}>
                                {bu.nome.substring(0, 30)}
                                {bu.nome.length > 30 && '...'}
                            </div>
                        ))
                    }

                </div>
            }


            {businessUnits.length === 0 && !caricamento &&
                <div className='flex flex-col gap-2 items-center max-w-[500px] mx-auto'>
                    <FaInfoCircle />
                    <h2 className="text-center text-lg">{t('ModuloCarbonFootprint.common.nonHaiAccesso')}</h2>
                </div>
            }

            {
                businessUnitAttiva &&
                <div className="grow border-t-2 border-accent dark:border-dark-accent">
                    <TabellaInterattiva
                        dati={emissioniCarburante}
                        titolo={`${t('ModuloCarbonFootprint.rendCarburanti.titolo')} ${businessUnitAttiva.nome}`}
                        callToActions={ctaTabella}
                        colonne={
                            [
                                { id: 'business_unit', label: t('common.businessUnit') },
                                { id: 'anno', label: t('common.anno') },
                                { id: 'carburante', label: t('ModuloCarbonFootprint.common.carburante') },
                                { id: 'nome', label: t('common.nome') },
                                { id: 'quantita', label: t('common.quantita') },
                                { id: 'unita_misura', label: t('common.unitaMisura') },
                            ]
                        }
                        callToActionsRiga={ctaRiga}
                    />
                </div>
            }

        </div>
    )
})
export default RendicontazioneCarburanti;