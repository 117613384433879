import React, { useEffect, useState } from 'react'
import { getBilanciRendicontazione } from '../rest/rendicontazione_per_business_unit'
import { IBilancioRendicontabile } from '../../../types'
import BilancioTile from '../../../components/bilanci/BilancioTile'
import { FaInfoCircle } from 'react-icons/fa'
import TextLoader from '../../../../../components/common/TextLoader'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import BarraRicercaGenerale from '../../../../../components/common/BarraRicercaGenerale'

const ArchivioRendicontazioneBilancio = () => {
  const { t } = useTranslation();
  const [gettingBilanci, setGettingBilanci] = useState<boolean>(false)
  const [bilanci, setBilanci] = useState<IBilancioRendicontabile[]>([])

  const styles = {
    cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-5",
  }

  useEffect(() => {
    setGettingBilanci(true)
    getBilanciRendicontazione()
      .then((res) => setBilanci(res.data.data))
      .finally(() => setGettingBilanci(false))
  }, [])

  const testoTile = `${t('ModuloFattoriEsg.BilancioTile.rendiconta')}`
  const [stringaDiRicercaBilanci, setStringaDiRicercaBilanci] = useState<string>('');

  const dataBilanci = bilanci.filter((bilancio) => bilancio.descrizione.toString().toLowerCase().includes(stringaDiRicercaBilanci.toLowerCase()));
  const [numeroBilanci, setNumeroBilanci] = useState(0);
  const bilanciPerPagina = 4;
  const paginaBilanciVisitate = numeroBilanci * bilanciPerPagina;
  const conteggioPaginaBilanci = Math.ceil(dataBilanci.length / bilanciPerPagina)
  const cambioPaginaBilanci = ({ selected }: { selected: number }): void => {
    setNumeroBilanci(selected)
  };

  useEffect(() => {
    setNumeroBilanci(0);
}, [stringaDiRicercaBilanci])

  return (
    <div>
      <div className="flex flex-col gap-2 mb-4">
        {
          gettingBilanci ?
            <div className="flex flex-col gap-4">
              <TextLoader />
              <TextLoader />
              <TextLoader />
            </div>
            :
            <>
              <div  className='flex justify-end'>
                <BarraRicercaGenerale 
                  setStringaDiRicerca={setStringaDiRicercaBilanci} 
                  numeroRisultati={dataBilanci.length}
                  mostraRisultati={true} />
              </div>
              <div className={styles.cardsWrapper}>

                {dataBilanci.length > 0 ?

                  dataBilanci
                    .slice(paginaBilanciVisitate, paginaBilanciVisitate + bilanciPerPagina)
                    .map((bilancio: IBilancioRendicontabile) => <BilancioTile testo={testoTile} bilancio={bilancio} />)

                  :

                  <div className="text-center font-xl my-4 flex flex-col items-center justify-center gap-4 opacity-70">
                    <FaInfoCircle size={40} />
                    <p className="text-xl">{t('ModuloFattoriEsg.ArchivioRendicontazioneBilancio.nonHaiAncoraAcesso')}</p>
                  </div>
                }
              </div>
            </>
        }
      </div>
      {dataBilanci.length > 4 &&
        <ReactPaginate
          pageCount={conteggioPaginaBilanci}
          forcePage={numeroBilanci}
          nextLabel={'Next'}
          onPageChange={cambioPaginaBilanci}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"} />
      }
    </div>
  )
}

export default ArchivioRendicontazioneBilancio;
