import React, { useEffect, useState } from 'react'
import { get } from '../../../../../rest/crud_generica'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'
import MenuGenerico from '../../../../../components/common/MenuGenerico'
import GraficoRadarVeicoliTotali from '../../grafici/graficiVeicoli/GraficoRadarVeicoliTotali'
import GraficoDettagliVeicoliDimensioni from '../../grafici/graficiVeicoli/GraficoDettagliVeicoliDimensioniPerBu'
import GraficoDettagliVeicoliTipologiaPerBu from '../../grafici/graficiVeicoli/GraficoDettagliVeicoliTipologiaPerBu'
import GraficoVerificaDeltaVeicoli from '../../grafici/graficiVeicoli/GraficoVerificaDeltaVeicoli'

const AnalisiVeicoli = () => {

  const [tabellaAttiva, setTabellaAttiva] = useState<'emissioni_bu_totali_flat' | 'emissioni_bu_flat' | 'emissioni_flat'>('emissioni_bu_totali_flat')
  const [emissioniBusinessUnitTotaliFlat, setEmissioniBusinessUnitTotaliFlat] = useState([])
  const [emissioniBusinessUnitFlat, setEmissioniBusinessUnitFlat] = useState([])
  const [emissioniFlat, setEmissioniFlat] = useState([])
  const [dimensioniTipologie, setDimensioniTipologie] = useState<'dimensioni' | 'tipologie'>('dimensioni')

  useEffect(() => {

    switch (tabellaAttiva) {

      case ('emissioni_bu_totali_flat'):
        get('carbon_footprint/analisi/scope1/veicoli/emissioni_business_unit_totali_flat').then((res) => setEmissioniBusinessUnitTotaliFlat(res.data.data))
        break;

      case ('emissioni_bu_flat'):
        get('carbon_footprint/analisi/scope1/veicoli/emissioni_business_unit_flat').then((res) => setEmissioniBusinessUnitFlat(res.data.data))
        break;

      case ('emissioni_flat'):
        get('carbon_footprint/analisi/scope1/veicoli/emissioni_flat').then((res) => setEmissioniFlat(res.data.data))
        break;

    }

  }, [tabellaAttiva])

  return (
    <div>

      <MenuGenerico bottoni={[
        {
          label: 'Emissioni totali per business unit',
          action: () => setTabellaAttiva('emissioni_bu_totali_flat'),
          attivo: tabellaAttiva === 'emissioni_bu_totali_flat' ? true : false
        },
        {
          label: 'Dettagli emissioni per business unit',
          action: () => setTabellaAttiva('emissioni_bu_flat'),
          attivo: tabellaAttiva === 'emissioni_bu_flat' ? true : false
        },
        {
          label: 'Lista Singole emissioni',
          action: () => setTabellaAttiva('emissioni_flat'),
          attivo: tabellaAttiva === 'emissioni_flat' ? true : false
        },
      ]} />

      {
        tabellaAttiva === 'emissioni_bu_totali_flat' &&
        <div className='bg-white dark:bg-darkgray-dd'>

          <div className="py-6 text-center">
            <h1 className='text-3xl font-bold'>Emissioni totali per business unit</h1>
            <p className="text-lg">Distribuzione delle emissioni dirette di veicoli per business unit (in t / co2e)</p>
          </div>

          {emissioniBusinessUnitTotaliFlat.length > 0 && <GraficoRadarVeicoliTotali emissioniBusinessUnitTotaliFlat={emissioniBusinessUnitTotaliFlat} anno={2022} />}

          <TabellaInterattiva
            titolo="Dati grezzi"
            dati={emissioniBusinessUnitTotaliFlat}
            colonne={
              [
                { id: 'business_unit', label: 'Business Unit'},
                { id: 'anno', label: 'Anno' },
                { id: 'emissione_totale_t_co2e_veicoli', label: 'Emissioni (t / co2e) - calcolate con il chilometraggio' },
                { id: 'emissione_totale_t_co2e_veicoli_carburante', label: 'Emissioni (t / co2e) - calcolate con i consumi di carburante' },
              ]
            }
          />
        </div>
      }

      {
        tabellaAttiva === 'emissioni_bu_flat' &&
        <div className='bg-white dark:bg-darkgray-dd'>

          <div className="py-6 text-center">
            <h1 className='text-3xl font-bold'>Dettagli delle emissioni</h1>
            <p className="text-lg">Distribuzione delle emissioni di carburanti negli impianti stazionari</p>
          </div>

          <MenuGenerico bottoni={[
            {
              label: 'Emissioni per dimensioni',
              action: () => setDimensioniTipologie('dimensioni'),
              attivo: dimensioniTipologie === 'dimensioni' ? true : false
            },
            {
              label: 'Emissioni per tipologia',
              action: () => setDimensioniTipologie('tipologie'),
              attivo: dimensioniTipologie === 'tipologie' ? true : false
            },
          ]} />

          {emissioniBusinessUnitFlat.length > 0 &&
            <>
              {dimensioniTipologie === 'dimensioni' && <GraficoDettagliVeicoliDimensioni emissioniBusinessUnitFlat={emissioniBusinessUnitFlat} anno={2022} />}
              {dimensioniTipologie === 'tipologie' && <GraficoDettagliVeicoliTipologiaPerBu emissioniBusinessUnitFlat={emissioniBusinessUnitFlat} anno={2022} />}
            </>
          }

          <TabellaInterattiva
            titolo="Dati grezzi"
            dati={emissioniBusinessUnitFlat}
            colonne={
              [
                { id: 'business_unit', label: 'Business Unit' },
                { id: 'anno', label: 'Anno' },
                { id: 'dimensione', label: 'Dimensione' },
                { id: 'tipologia', label: 'Tipologia' },
                { id: 'emissione_totale_t_co2e_veicoli', label: 'Emissioni (t / co2e) - calcolate con il chilometraggio' },
                { id: 'emissione_totale_t_co2e_veicoli_carburanti', label: 'Emissioni (t / co2e) - calcolate con i consumi di carburante' },
              ]
            }
          />

        </div>
      }

      {
        tabellaAttiva === 'emissioni_flat' &&
        <div className='bg-white dark:bg-darkgray-dd'>

          <div className="py-6 text-center">
            <h1 className='text-3xl font-bold'>Lista singole emissioni</h1>
            <p className="text-lg">Dettaglio di tutte le emissioni dei veicoli</p>
          </div>

          {emissioniFlat.length > 0 && <GraficoVerificaDeltaVeicoli emissioniFlat={emissioniFlat} anno={2022} />}

          <TabellaInterattiva
            titolo="Lista Singole emissioni"
            dati={emissioniFlat}
            colonne={
              [
                { id: 'business_unit', label: 'Business Unit'},
                { id: 'anno', label: 'Anno' },
                { id: 'dimensione', label: 'Dimensione' },
                { id: 'tipologia', label: 'Tipologia' },
                { id: 'numero_veicoli', label: 'Numero di veicoli' },
                { id: 'chilometraggio_totale', label: 'Km totali' },
                { id: 'quantita_carburante', label: 'Quantità di carburante' },
                { id: 'unita_misura', label: 'Unità di misura' },
                { id: 't_co2e', label: 'Emissioni (t / co2e) - calcolate con il chilometraggio' },
                { id: 't_co2e_carburante', label: 'Emissioni (t / co2e), calcolate con i consumi di carburante' },
                { id: 'delta', label: 'Delta tra le emissioni calcolate in km e le emissioni calcolate in consumi' },
              ]
            }
          />
        </div>
      }

    </div>
  )
}

export default AnalisiVeicoli;