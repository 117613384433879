import React, { useState, useEffect } from 'react'
import { IModulo } from '../../../../types'
import { getAllModuli } from '../../../gestioneModuli/rest/gestioneModuli'
import ModuloTile from '../../../gestioneModuli/ModuloTile'

export default function AnalisiScope1() {
  
  const [moduli, setModuli] = useState<IModulo[]>([])
  const [moduliDisattivi, setModuliDisattivi] = useState<IModulo[]>([])

  useEffect(() => {

    getAllModuli().then((res) => setModuli(res.data.data.filter((modulo: IModulo) => (
      (
        modulo.codice === 'analisi_carburanti' ||
        modulo.codice === 'analisi_gas_refrigeranti' ||
        modulo.codice === 'analisi_veicoli'
      ) && modulo.attivo
    ))))

    getAllModuli().then((res) => setModuliDisattivi(res.data.data.filter((modulo: IModulo) => (
      (
        modulo.codice === 'analisi_carburanti' ||
        modulo.codice === 'analisi_gas_refrigeranti' ||
        modulo.codice === 'analisi_veicoli'
      ) && !modulo.attivo
    ))))
  }, [])

  return (
    <div>
      {moduli && moduli.map((modulo) => (
        <ModuloTile modulo={modulo} modifica={false} />
      ))}

      {moduliDisattivi.length > 0 &&
        <div className="pointer-events-none opacity-60">
          {moduliDisattivi.map((modulo) => (
            <ModuloTile modulo={modulo} modifica={false} />
          ))}
        </div>
      }
    </div>
  )
}
