import React from 'react'
import { useStores } from '../../hooks/useStores';
import { ICallToActionTabella } from './types';

const CallToActionRiga: React.FC<ICallToActionTabella> = ({
    icona,
    testo,
    azione,
    id,
    dati,
    coloreSfondo,
    coloreTesto
}) => {

    const { ui } = useStores()


    return (
        <div
            id={id}
            className={`callToActionRiga
            ${coloreSfondo ? coloreSfondo : "bg-accent dark:bg-dark-accent"}
            ${coloreTesto ? coloreTesto : "text-white"}
            `}
            onClick={() => azione && azione(dati)}
            onMouseEnter={() => ui.mountFollowMouse(<>{testo}</>)}
            onMouseLeave={() => ui.unmountFollowMouse()}
        >
            {icona && <span>{icona}</span>}
        </div>
    )
}

export default CallToActionRiga;
