import React from 'react'

const GraficoBusinessUnitsRendicontabili: React.FC<{ businessUnitsRendicontabili: any[] }> = ({ businessUnitsRendicontabili }) => {
  return (
    <div className="p-4 flex flex-col">
      <div className="grid grid-cols-5 border-b border-grey font-bold">
        <div className='py-1'>Business Unit</div>
        <div className='py-1'>Impianti stazionari</div>
        <div className='py-1'>Gas refrigeranti</div>
        <div className='py-1'>Veicoli</div>
        <div className='py-1'>Elettricità</div>
      </div>
      {businessUnitsRendicontabili.map((row) => <RowBusinessUnitsRendicontabile row={row} />)}
    </div>
  )
}

const RowBusinessUnitsRendicontabile: React.FC<{ row: any }> = ({ row }) => {
  console.log(row)
  return (
    <div className="grid grid-cols-5 border-b border-grey">
      <div className='p-1'> {row.business_unit}</div>
      <TileBusinessUnitsRendicontabile attivo={typeof(row.emissione_totale_t_co2e_combustibile) === 'number' ? true : false} />
      <TileBusinessUnitsRendicontabile attivo={typeof(row.emissione_totale_t_co2e_gas_refrigerante) === 'number' ? true : false} />
      <TileBusinessUnitsRendicontabile attivo={typeof(row.emissione_totale_t_co2e_veicoli) === 'number' ? true : false} />
      <TileBusinessUnitsRendicontabile attivo={typeof(row.emissione_totale_t_co2e_elettricita) === 'number' ? true : false} />
    </div>
  )
}

const TileBusinessUnitsRendicontabile: React.FC<{ attivo?: boolean }> = ({ attivo = false }) => {
  return (
    <div className={`border-r border-grey h-full ratio-square items-center justify-center flex ${attivo ? 'bg-green' : 'bg-red'}`}>
      <p className="text-white">{attivo ? 'Rendicontato' : 'Non rendicontato'}</p>
    </div>
  )
}
export default GraficoBusinessUnitsRendicontabili;