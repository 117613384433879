import React, { useEffect, useState } from 'react'
import ReactEcharts from "echarts-for-react"
import MenuGenerico from '../../../../components/common/MenuGenerico'


const GraficoAggregatoEmissioniAnno: React.FC<{ aggregatoEmissioniAnno: any[] }> = ({ aggregatoEmissioniAnno }) => {

  //Genera nodi
  let nodes: any[] = [{ "name": "totale" }]

  aggregatoEmissioniAnno.forEach((emissione) => {
    nodes.push({ "name": emissione.business_unit })
  })

  //Genera link combustibile
  let linksCombustibile: any[] = []

  aggregatoEmissioniAnno.forEach((emissione) => {
    if (emissione.emissione_cumulata_t_co2e_combustibile > 0) {
      linksCombustibile.push({
        "source": emissione.business_unit,
        "target": emissione.business_unit_padre ? emissione.business_unit_padre : 'totale',
        "value": emissione.emissione_cumulata_t_co2e_combustibile
      })
    }
  })

  //Genera link gas
  let linksGas: any[] = []

  aggregatoEmissioniAnno.forEach((emissione) => {
    if (emissione.emissione_cumulata_t_co2e_gas_refrigerante > 0) {
      linksGas.push({
        "source": emissione.business_unit,
        "target": emissione.business_unit_padre ? emissione.business_unit_padre : 'totale',
        "value": emissione.emissione_cumulata_t_co2e_gas_refrigerante
      })
    }
  })

  //Genera link veicoli
  let linksVeicoli: any[] = []

  aggregatoEmissioniAnno.forEach((emissione) => {
    if (emissione.emissione_cumulata_t_co2e_veicoli > 0) {
      linksVeicoli.push({
        "source": emissione.business_unit,
        "target": emissione.business_unit_padre ? emissione.business_unit_padre : 'totale',
        "value": emissione.emissione_cumulata_t_co2e_veicoli
      })
    }
  })

  //Genera link elettricita
  let linksElettricita: any[] = []

  aggregatoEmissioniAnno.forEach((emissione) => {
    if (emissione.emissione_cumulata_t_co2e_gas_refrigerante > 0) {
      linksElettricita.push({
        "source": emissione.business_unit,
        "target": emissione.business_unit_padre ? emissione.business_unit_padre : 'totale',
        "value": emissione.emissione_cumulata_t_co2e_elettricita
      })
    }
  })

  //Genera link totali
  let linksTotali: any[] = []

  aggregatoEmissioniAnno.forEach((emissione) => {
    if (emissione.emissione_cumulata_t_co2e_totale > 0) {
      linksTotali.push({
        "source": emissione.business_unit,
        "target": emissione.business_unit_padre ? emissione.business_unit_padre : 'totale',
        "value": emissione.emissione_cumulata_t_co2e_totale
      })
    }
  })


  const [emissioniAttive, setEmissioniAttive] = useState<'combustibile' | 'gas_refrigeranti' | 'veicoli' | 'elettricita' | 'totale'>('combustibile')
  const [datiEmissioni, setDatiEmissioni] = useState<any[]>(linksCombustibile)

  useEffect(() => {
    switch (emissioniAttive) {

      case ('combustibile'):
        setDatiEmissioni(linksCombustibile)
        break;

      case ('gas_refrigeranti'):
        setDatiEmissioni(linksGas)
        break;

      case ('veicoli'):
        setDatiEmissioni(linksVeicoli)
        break;

      case ('elettricita'):
        setDatiEmissioni(linksElettricita)
        break;

      case ('totale'):
        setDatiEmissioni(linksTotali)
        break;

    }
  }, [emissioniAttive])


  let option = {
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove'
    },
    series: [
      {
        type: 'sankey',
        name: 'Combustibile',
        data: nodes,
        nodeAlign: 'right',
        links: datiEmissioni,
        emphasis: {
          focus: 'adjacency'
        },
        lineStyle: {
          color: 'gradient',
          curveness: 0.5
        }
      }
    ]
  }

  return (
    <div className="my-4">
      <MenuGenerico bottoni={[
        {
          label: 'Combustibili',
          action: () => setEmissioniAttive('combustibile'),
          attivo: emissioniAttive === 'combustibile' ? true : false,
          classi: 'bg-[#eee] dark:bg-[#111]'
        },
        {
          label: 'Gas refrigeranti',
          action: () => setEmissioniAttive('gas_refrigeranti'),
          attivo: emissioniAttive === 'gas_refrigeranti' ? true : false,
          classi: 'bg-[#eee] dark:bg-[#111]'
        },
        {
          label: 'Veicoli',
          action: () => setEmissioniAttive('veicoli'),
          attivo: emissioniAttive === 'veicoli' ? true : false,
          classi: 'bg-[#eee] dark:bg-[#111]'
        },
        {
          label: 'Elettricità',
          action: () => setEmissioniAttive('elettricita'),
          attivo: emissioniAttive === 'elettricita' ? true : false,
          classi: 'bg-[#eee] dark:bg-[#111]'
        },
        {
          label: 'Totali',
          action: () => setEmissioniAttive('totale'),
          attivo: emissioniAttive === 'totale' ? true : false,
          classi: 'bg-[#eee] dark:bg-[#111]'
        },
      ]} />
      <hr className='border-2 border-[#eee] dark:border-[#111]' />
      <ReactEcharts style={{ minHeight: 700 }} option={option} />
    </div>
  )
}

export default GraficoAggregatoEmissioniAnno;
